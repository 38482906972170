const marketingNomenclatureSchema = function () {
  return {
    promotion: marketingPromotionSchema(),
    nomenclatures: {
      nomenclature: {
        manufacturer: {
          id: true,
          name: true
        },
        guid: true,
        name: true
      },
      confirmedCategories: {
        level: true,
        category: {
          id: true,
          guid: true,
          name: true,
          is_visible: true,
          is_active: true,
          is_container: true
        }
      },
      availableCategories: {
        categories: {
          level: true,
          category: {
            id: true,
            guid: true,
            name: true,
            is_visible: true,
            is_active: true,
            is_container: true
          },
          hasErrors: true
        },
        category2BasePath: true
      }
    }
  }
}

const marketingPromotionSchema = function () {
  return {
    name: true,
    status: true,
    isPaid: true,
    startDate: true,
    endDate: true,
    amount: true,
    createdAt: true,
    report: {
      downloadUrl: true
    }
  }
}

export { marketingNomenclatureSchema, marketingPromotionSchema }
