var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['base-alert', `-${_vm.type}`]},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"description"},[_vm._v("\n      "+_vm._s(_vm.description)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.$slots.description)?_c('div',{staticClass:"description"},[_vm._t("description")],2):_vm._e(),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"footer"},[_vm._t("footer")],2):_vm._e(),_vm._v(" "),(_vm.isShowUploadingButton)?_c('AppFileUploader',{staticClass:"mt-3",attrs:{"api-entity":_vm.apiEntity,"api-method":_vm.apiMethod,"api-method-params":_vm.apiMethodParams,"model-name":_vm.modelName,"valid-file-types":[
        'xls',
        'xlsx',
        'csv',
        'vnd.ms-excel',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],"is-immediate-upload":"","is-multi-page-behavior":""},on:{"uploaded-to-cloud":_vm.onCloudUploaded}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }