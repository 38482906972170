<template>
  <div class="app-header-container">
    <header id="app_header" class="app-header">
      <component :is="logo.type" :to="logo.link" :href="logo.link" class="app-logo">
        <img class="app-logo-img" src="/nuxt/vi_logo.svg" width="44" height="44" alt="ВсеИнструменты" />
        <div class="app-site-name">
          {{ logo.title }}
          <div class="user-name">
            {{ user('user.supplier.name') }}
          </div>
        </div>
      </component>
      <AppMenu />
      <div class="app-header-right-column">
        <AppHelper v-if="isAnySupplier" class="app-helper" />
        <AppUserNotifications v-if="isAnySupplier" />
        <AppToolbar v-if="getUserType" :user="user('user')" />
      </div>
      <template v-if="$route.path !== '/marketing/'">
        <FixedVideoButton class="video-button" />
        <MarketingFAQ v-if="isShowMarketingFAQ" class="faq-button" />
      </template>
    </header>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ADMIN_PERMISSION } from '@/constants'
import { USER_TYPES } from '@/constants/user'
import AppMenu from '@/components/layout/AppMenu'
import AppToolbar from '@/components/layout/AppToolbar'
import AppUserNotifications from '@/components/layout/AppUserNotifications'
import AppHelper from '@/components/layout/AppHelper'
import FixedVideoButton from '@/components/buttons/FixedVideoButton'
import MarketingFAQ from '@/pages/marketing/list/blocks/MarketingFAQ'

export default {
  name: 'AppHeader',
  components: {
    AppToolbar,
    AppMenu,
    AppUserNotifications,
    AppHelper,
    FixedVideoButton,
    MarketingFAQ
  },
  data() {
    return {
      isModalOpen: false
    }
  },

  computed: {
    ...mapGetters('user', {
      user: 'get',
      hasUser: 'hasUser',
      hasOnePermission: 'hasOnePermission',
      getUserType: 'getUserType',
      isAuthorized: 'isAuthorized'
    }),
    isShowMarketingFAQ() {
      return this.$route.name === 'marketing'
    },
    isAdmin() {
      return this.hasOnePermission([ADMIN_PERMISSION])
    },
    isAnySupplier() {
      return this.getUserType === USER_TYPES.supplier
    },
    logo() {
      if (this.getUserType === USER_TYPES.manager) {
        return {
          link: '/admin',
          type: 'a',
          title: 'Администрирование'
        }
      }
      if (this.isAuthorized) {
        return {
          link: '/',
          type: 'nuxt-link',
          title: 'КАБИНЕТ ПОСТАВЩИКА'
        }
      }
      return {
        link: '/site/login',
        type: 'a',
        title: 'КАБИНЕТ ПОСТАВЩИКА'
      }
    }
  },
  methods: {
    ...mapActions('user', ['setUser'])
  }
}
</script>

<style lang="scss" scoped>
.app-header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.app-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: $vr-red 32px;
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
}

.app-logo {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  text-decoration: none;
  border: none;

  &-img {
    display: block;
    margin-right: 12px;
  }
}

.app-site-name {
  font-size: 1.6rem;
  font-weight: 700;
  color: $gray-90;
  margin-right: 40px;
}

.app-header-right-column {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
}

.app-helper {
  margin-right: 16px;
}

.video-button {
  position: absolute;
  right: 0;
  bottom: -96px;
  z-index: 100;
}

.faq-button {
  position: absolute;
  right: 0;
  bottom: -144px;
  z-index: 110;
}
</style>
