import { EnumType, VariableType } from 'json-to-graphql-query'
import defaultNomenclatureSchema from '@/schema/nomenclature'
import defaultNomenclatureContentSchema from '@/schema/nomenclatureContent'
import defaultReplaceNomenclatureSchema from '@/schema/replacmentNomanclatures'
import defaultPageParamsSchema from '@/schema/infoPanel'
import defaultNomenclatureListSchema from '@/schema/nomenclatureList'
import defaultNomenclatureListContentData from '@/schema/nomenclatureListContentData'
import addFilters from '@/mixins/addFilters'
import addOrderBy from '@/mixins/addOrderBy'
import cast from '@/mixins/cast'
import addFilesToQuery from '@/mixins/addFilesToQuery'

const getNomenclatureQuery = (nomenclatureId, fields) => {
  return {
    query: {
      nomenclature: {
        __args: {
          id: nomenclatureId
        },
        ...(Object.keys(fields).length ? fields : defaultNomenclatureSchema())
      }
    }
  }
}

const getNomenclatureContentQuery = (nomenclatureId, fields) => {
  return {
    query: {
      nomenclatureContent: {
        __args: {
          id: nomenclatureId
        },
        ...(Object.keys(fields).length ? fields : defaultNomenclatureContentSchema())
      }
    }
  }
}

const getReplacementNomenclaturesQuery = (search, nomenclatureId, fields, limit) => {
  return {
    query: {
      replacementNomenclatures: {
        __args: {
          supplierNomenclatureId: nomenclatureId,
          pattern: search,
          limit
        },
        ...(Object.keys(fields).length ? fields : defaultReplaceNomenclatureSchema())
      }
    }
  }
}

const getPageParamsQuery = (nomenclatureId, fields) => {
  return {
    query: {
      infoPanel: {
        __args: {
          supplierNomenclatureId: nomenclatureId
        },
        ...(Object.keys(fields).length ? fields : defaultPageParamsSchema())
      }
    }
  }
}

const getNomenclatureListQuery = (pageSize, pageNumber, filters = null, orderBy = null, fields) => {
  return {
    query: {
      nomenclatureList: {
        __args: {
          page: pageNumber,
          perPage: pageSize,
          filter: filters,
          orderBy
        },
        ...(Object.keys(fields).length ? fields : defaultNomenclatureListSchema())
      }
    }
  }
}

const getExportNomenclatureListJobQuery = (filters, orderBy) => {
  let transformedOrderBy = null
  if (orderBy) {
    const [key, value] = Object.entries(orderBy)[0]
    transformedOrderBy = { [key]: new EnumType(value) }
  }
  cast(filters, 'nomenclatureList')

  return {
    query: {
      exportNomenclatureListJob: {
        __args: {
          filter: filters,
          orderBy: transformedOrderBy
        }
      }
    }
  }
}

export const getExportTemplateTaskMutation = (filters, orderBy) => {
  const mutation = {
    mutation: {
      createExportTemplateTask: true
    }
  }

  addFilters('createExportTemplateTask', filters, mutation, 'mutation')
  addOrderBy('createExportTemplateTask', orderBy, mutation, 'mutation')

  return mutation
}

export const getPdmTaskProgressPercentQuery = pdmTaskId => {
  return {
    query: {
      pdmTaskProgressPercent: {
        __args: {
          pdmTaskId
        }
      }
    }
  }
}

export const getNomenclatureTechParamsExportTemplateFileQuery = pdmTaskId => {
  return {
    query: {
      nomenclatureTechExportTemplateFile: {
        __args: {
          pdmTaskId
        },
        name: true,
        content: true
      }
    }
  }
}

export const getSendNomenclatureListToVerificationQuery = filters => {
  cast(filters, 'nomenclatureList')

  return {
    mutation: {
      sendNomenclatureListOnCoordinationVerification: {
        __args: {
          listFilter: { ...filters }
        }
      }
    }
  }
}

export const getSendNomenclatureListToCoordinationQuery = jobId => {
  return {
    mutation: {
      sendNomenclatureListOnCoordination: {
        __args: {
          verificationJobId: jobId
        }
      }
    }
  }
}

export const getContentDataQuery = (pageSize, pageNumber, filters, orderBy, fields) => {
  const query = {
    query: {
      nomenclatureListContentData: {
        __args: {
          page: pageNumber,
          perPage: pageSize,
          filter: filters,
          orderBy
        },
        ...(Object.keys(fields).length ? fields : defaultNomenclatureListContentData())
      }
    }
  }

  return query
}

export const savePermitDocsMutation = (supplierNomenclatureId, permitDocs) => {
  const query = {
    mutation: {
      SavePermitDocs: {
        __args: {
          supplierNomenclature: {
            supplierNomenclatureId: Number(supplierNomenclatureId)
          },
          permitDocs
        },
        id: true,
        numberRd: true,
        linkRd: true
      }
    }
  }

  return query
}

export const get =
  ({ $axios }) =>
  (nomenclatureId, fields = {}) => {
    const query = getNomenclatureQuery(nomenclatureId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const content =
  ({ $axios }) =>
  (nomenclatureId, fields = {}) => {
    const query = getNomenclatureContentQuery(nomenclatureId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const replacementNomenclatures =
  ({ $axios }) =>
  (search = '', nomenclatureId, fields = {}, limit = 10) => {
    const query = getReplacementNomenclaturesQuery(search, nomenclatureId, fields, limit)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const pageParams =
  ({ $axios }) =>
  (nomenclatureId, fields = {}) => {
    const query = getPageParamsQuery(nomenclatureId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const list =
  ({ $axios }) =>
  (pageSize = 20, pageNumber = 1, filters = {}, orderBy = null, fields = {}) => {
    const query = getNomenclatureListQuery(pageSize, pageNumber, filters, orderBy, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getExportNomenclatureListJob =
  ({ $axios }) =>
  (filters = null, orderBy = null) => {
    const query = getExportNomenclatureListJobQuery(filters, orderBy)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getExportTemplateTask =
  ({ $axios }) =>
  (filters = {}, orderBy = null) => {
    const query = getExportTemplateTaskMutation(filters, orderBy)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPdmTaskProgressPercent =
  ({ $axios }) =>
  pdmTaskId => {
    const query = getPdmTaskProgressPercentQuery(pdmTaskId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getNomenclatureTechParamsExportTemplateFile =
  ({ $axios }) =>
  pdmTaskId => {
    const query = getNomenclatureTechParamsExportTemplateFileQuery(pdmTaskId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const sendNomenclatureListToVerification =
  ({ $axios }) =>
  (filters = {}) => {
    const query = getSendNomenclatureListToVerificationQuery(filters)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const sendNomenclatureListToCoordination =
  ({ $axios }) =>
  jobId => {
    const query = getSendNomenclatureListToCoordinationQuery(jobId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const contentData =
  ({ $axios }) =>
  (pageSize = 20, pageNumber = 1, filters = {}, orderBy = null, fields = {}, cancelToken) => {
    const query = getContentDataQuery(pageSize, pageNumber, filters, orderBy, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query }, { cancelToken })
  }

export const supplierClassifierUnits =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        supplierClassifierUnits: {
          name: true,
          id: true
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveNomenclatureStatus =
  ({ $axios, $json2graphql }) =>
  (nomenclatureId, formStatus, replacementId) => {
    const status = new $json2graphql.EnumType(formStatus)

    const query = {
      mutation: {
        saveNomenclatureStatus: {
          __args: {
            id: nomenclatureId,
            formInput: {
              status,
              replacementNomenclatureId: replacementId
            }
          },
          nomenclature: {
            isActive: true
          },
          validationErrors: {
            field: true,
            errors: true
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
export const save =
  ({ $axios }) =>
  (nomenclatureId, form) => {
    const { replacementNomenclatureId, status, ...formData } = form

    const query = {
      mutation: {
        saveNomenclature: {
          __args: {
            id: nomenclatureId,
            form: formData
          },
          nomenclature: defaultNomenclatureSchema(),
          validationErrors: {
            field: true,
            errors: true
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveContent =
  ({ $axios }) =>
  (nomenclatureId, form) => {
    const query = {
      mutation: {
        saveNomenclatureContent: {
          __args: {
            id: nomenclatureId,
            form
          },
          nomenclatureContent: defaultNomenclatureContentSchema(),
          validationErrors: {
            field: true,
            errors: true
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveCategory =
  ({ $axios }) =>
  (nomenclatureId, newCategoryId) => {
    const query = {
      mutation: {
        saveCategoryAndResetTechParamChanges: {
          __args: {
            supplierNomenclature: {
              supplierNomenclatureId: nomenclatureId
            },
            newCategoryId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const sendToCoordination =
  ({ $axios }) =>
  nomenclatureId => {
    const query = {
      mutation: {
        sendOnCoordination: {
          __args: {
            supplierNomenclatureId: nomenclatureId
          },
          commonCoordinationResult: {
            coordinationId: true,
            processToDate: true
          },
          contentCoordinationResult: {
            coordinationId: true,
            processToDate: true
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveNomenclatureListColumnsSettings =
  ({ $axios }) =>
  columns => {
    const query = {
      mutation: {
        saveNomenclatureListColumns: {
          __args: {
            columns: columns.map(column => {
              return {
                type: new EnumType(column.type),
                isVisible: column.isVisible,
                isDisabled: column.isDisabled
              }
            })
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const uploadNewNomenclatureFile =
  ({ $axios }) =>
  (supplierId, uploadedFile) => {
    const query = {
      mutation: {
        __variables: {
          file: 'Upload!'
        },
        newNomenclatureUploadFile: {
          __args: {
            supplier: {
              supplierId
            },
            uploadedFile: {
              file: new VariableType('file')
            }
          },
          jobId: true,
          uploadId: true
        }
      }
    }

    const formData = addFilesToQuery(uploadedFile, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

export const nomenclatureListCounters =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        nomenclatureListCounters: {
          quickFilterType: true,
          count: true
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const savePermitDocs =
  ({ $axios }) =>
  ({ supplierNomenclatureId, permitDocs }) => {
    const query = savePermitDocsMutation(supplierNomenclatureId, permitDocs)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
