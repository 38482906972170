export const state = () => ({
  isOpenedUserAgreementModal: false
})

export const mutations = {
  SET_VISIBLE: (state, isVisible) => {
    state.isOpenedUserAgreementModal = isVisible
  }
}

export const actions = {
  setVisible({ commit }, isVisible) {
    commit('SET_VISIBLE', isVisible)
  }
}
