import { useContext, useStore } from '@nuxtjs/composition-api'

export default function () {
  const { $api, $notify } = useContext()
  const store = useStore()
  const isFromAdminPanel = store.getters['user/isFromAdminPanel']
  const adminPanelUrl = store.getters['user/adminPanelUrl']

  const logout = async () => {
    try {
      await $api.user.logout()
      window.location.href = window.PAS.ENV.PAS_URL + (isFromAdminPanel ? adminPanelUrl : '/site/login')
    } catch (err) {
      $notify({
        type: 'error',
        title: 'Не удалось выйти'
      })
    }
  }

  return {
    logout
  }
}
