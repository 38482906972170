<template functional>
  <ContentLoader
    height="13"
    :style="{ height: '13px', width: '100%' }"
    :speed="1"
    primary-color="#eaeced"
    secondary-color="#FFFFFF"
    preserve-aspect-ratio="none"
  >
    <rect x="0" y="0" rx="1" ry="1" width="100%" height="13" />
  </ContentLoader>
</template>

<script>
export default {
  name: 'AppTableLoader'
}
</script>
