<template>
  <FilterBox :is-active="isActive" @applyFilter="applyFilter" @clearFilter="clearFilter">
    <template #title>
      {{ label }}
    </template>
    <template #content>
      <Autocomplete
        v-model="innerValue"
        size="small"
        :search-results="searchResults"
        @search="onSearch"
        @option-click="onSelect"
      >
        <template #item="{ item }">
          {{ item.text }}
        </template>
      </Autocomplete>
    </template>
  </FilterBox>
</template>

<script>
import FilterBox from '@/components/filters/FilterBox'

export default {
  name: 'SearchFilter',
  components: {
    FilterBox
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Введите фразу'
    },
    dataProvider: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      innerValue: this.value,
      searchResults: [],
      selectedValue: null
    }
  },
  fetch() {
    this.setInitialValue(this.value)
  },
  watch: {
    value: {
      handler(value) {
        this.selectedValue = value
        this.setInitialValue(value)
      }
    },
    immediate: true
  },
  methods: {
    applyFilter() {
      const value = this.innerValue && !this.selectedValue ? -1 : this.selectedValue // -1 для запроса graphql
      this.$emit('apply-filter', value)
    },
    clearFilter() {
      this.selectedValue = null
      this.innerValue = ''
      this.applyFilter()
    },
    onSelect(item) {
      this.selectedValue = item.value
    },
    async onSearch(value) {
      try {
        const response = await this.$api[this.dataProvider.apiSearchEntity][[this.dataProvider.apiSearchMethod]](
          value,
          {
            value: { __aliasFor: 'id' },
            text: { __aliasFor: 'name' }
          }
        )
        this.searchResults = response.data[this.dataProvider.searchModel]
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err.message || err.errorMessage || 'Не удалось получить список совпадений'
        })
      }
    },
    async setInitialValue(value) {
      if (!value) return
      try {
        const response = await this.$api[this.dataProvider.apiEntity][[this.dataProvider.apiMethod]](parseInt(value), {
          value: { __aliasFor: 'id' },
          text: { __aliasFor: 'name' }
        })
        this.innerValue = response.data[this.dataProvider.model]?.text
          ? response.data[this.dataProvider.model].text
          : ''
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err.message || err.errorMessage || 'Не удалось получить список совпадений'
        })
      }
    }
  }
}
</script>
