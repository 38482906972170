export const getRandMockInt = max => {
  return Math.floor(Math.random() * max) + 1
}

export const getRandMockStr = max => {
  return (Math.random() + 1).toString(36).substring(2, max + 2)
}

export const getRandMockIntBase10Str = max => {
  const rnd = getRandMockInt(max)
  return rnd < 10 ? '0' + rnd : rnd
}
