<template>
  <ul class="list">
    <li v-for="(item, index) in items" :key="index" class="item">
      <span class="marker"></span>
      <span v-html="item + separator"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    separator: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  list-style-type: none;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 300;
  color: $gray-90;

  > .item {
    display: flex;
    align-items: center;
    margin-bottom: $vr-brown;
    position: relative;

    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: $gray-70;
        border-radius: 50%;
      }
    }
  }
}
</style>
