<template>
  <div class="app-tag" @click="$emit('click')">
    <nuxt-link
      v-if="type === 'link'"
      :to="url"
      :class="[
        'link',
        {
          '-active': isActive,
          '-disabled': isDisabled
        }
      ]"
      :event="isDisabled || hasClickListener || !isClickable ? '' : 'click'"
      @click.native="onClick"
    >
      <slot />
    </nuxt-link>
    <label v-if="type === 'radio'">
      <input type="radio" class="input-radio" :name="name" />
      <span :class="['text-radio']">
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppTag',
  props: {
    type: {
      type: String,
      default: 'link'
    },
    url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isClickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasClickListener() {
      return 'on-click' in this.$listeners
    }
  },
  methods: {
    onClick() {
      if (this.hasClickListener) {
        this.$emit('on-click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-tag {
  display: flex;
  background: $white;
  min-height: 32px;
  border-radius: 24px;
  overflow: hidden;
  transition: background-color 0.5s;

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $vr-brown 12px;
    text-decoration: none;
    box-shadow: inset 0 0 0 1px $gray-50;
    font-size: 1.4rem;
    line-height: 1.4rem;
    border-radius: 24px;
    transition: box-shadow 0.2s;
    border-bottom: transparent;
  }

  .text-radio {
    display: block;
    padding: 9px 12px;
    font-size: 1.4rem;
    line-height: 1.4rem;
    border-radius: 24px;
    box-shadow: inset 0 0 0 1px $gray-50;
    cursor: pointer;
    border-radius: 24px;
  }

  .-active {
    box-shadow: inset 0 0 0 2px $gray-70;
  }

  .-disabled {
    background-color: $gray-40;
  }

  &:hover {
    background-color: $gray-20;
  }
}

.input-radio {
  display: none;
}

.input-radio:checked + .text-radio {
  box-shadow: inset 0 0 0 2px $gray-70;
}
</style>
