<template>
  <validationProvider ref="provider" v-slot="{ errors }" :rules="rules" :mode="mode" :vid="name" tag="div">
    <AppAutocomplete
      :value="innerValue"
      v-bind="$attrs"
      :class="{ '-has-error': errors[0] }"
      v-on="$listeners"
      @input="search"
    >
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </AppAutocomplete>
    <AppFieldErrorDetail v-if="errors[0]" :text="errors[0]" />
    <AppFieldNoteDetail v-if="!errors[0] && note" :text="note" />
  </validationProvider>
</template>

<script>
export default {
  name: 'AppAutocompleteValidation',
  props: {
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    helpText: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async search(e) {
      if (e === '') {
        this.$emit('clear-input')
      }
      await this.$refs.provider.validate(e)
    }
  }
}
</script>
