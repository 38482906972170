function tagsCountSchema() {
  return {
    id: true,
    title: true,
    count: true
  }
}

function videoInstructionSchema() {
  return {
    id: true,
    title: true,
    tags: {
      id: true,
      title: true
    },
    sort_index: true,
    videoId: true,
    isYoutube: true,
    duration: true
  }
}

function pageVideoInstructionSchema() {
  return {
    videoInstruction: videoInstructionSchema(),
    hint: true,
    isViewed: true,
    feedback: {
      liked: true
    }
  }
}

function videoInstructionsSchema() {
  return {
    tagsCount: tagsCountSchema(),
    pageVideoInstructions: pageVideoInstructionSchema(),
    totalCount: true
  }
}

export { pageVideoInstructionSchema, videoInstructionsSchema }
