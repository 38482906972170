<template>
  <div class="app-info-block">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppInfoBlock'
}
</script>

<style lang="scss" scoped>
.app-info-block {
  padding: $vr-fuchsia 24px;
  background-color: $white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
</style>
