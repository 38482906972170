import { TASK_STATUS_FINISHED, TASK_STATUS_CANCELLED, TASK_STATUS_INTERRUPTED } from '@/constants'

export default async function (jobId) {
  try {
    const response = await this.$api.jobs.get(jobId)
    const { job } = response.data
    const isFinished = job.statusId === TASK_STATUS_FINISHED
    const isCanceled = job.statusId === TASK_STATUS_CANCELLED
    const isInterrupted = job.statusId === TASK_STATUS_INTERRUPTED

    if (!isFinished && !isCanceled && !isInterrupted) {
      return Promise.resolve({
        status: 'process',
        message: job.completePercent,
        ...job
      })
    }

    if (isFinished) {
      try {
        const resultData = JSON.parse(job.resultData)
        const resultText = job.resultText
        return { resultData, resultText, statusId: job.statusId }
      } catch (err) {
        return {
          status: 'stopped',
          message: 'Не удалось обработать результат',
          ...job
        }
      }
    } else if (isCanceled || isInterrupted) {
      let reason = 'остановлен'
      let detail = ''
      if (isCanceled) {
        reason = 'отменен'
      }

      if (isInterrupted) {
        reason = 'прерван'
        detail = job.resultText
      }
      return {
        status: 'stopped',
        message: 'Процесс был ' + reason + '.',
        detail,
        ...job
      }
    } else {
      return {
        status: 'stopped',
        message: 'Процесс остановлен по непонятным причинам',
        ...job
      }
    }
  } catch (err) {
    return {
      status: 'stopped',
      message: 'Не удалось получить статус процесса'
    }
  }
}
