<template>
  <div class="app-tree-select">
    <base-input
      v-model="innerValue.label"
      size="small"
      :required="required"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="search"
      @focus="onFocus"
      @focusout="checkInnerValue"
    />
    <ul v-if="isOpened" class="suggests">
      <preloader v-if="isLoading" />
      <template v-else>
        <BaseScroll max-height="316px">
          <TreeSuggest
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            :cancel-check-sub-item="cancelCheckSubItem"
            @change="onChange"
          >
            <template #option="{ item }">
              <slot name="option" :item="item" />
            </template>
          </TreeSuggest>
        </BaseScroll>
      </template>
    </ul>
    <AppFieldNoteDetail v-if="note" :text="note" />
  </div>
</template>

<script>
import { BaseScroll } from '@vi-frontend/elements'
import TreeSuggest from '@/components/ui/AppTreeSelect/TreeSuggest'
import preloader from '@/components/ui/AppTreeSelect/preloader'

export default {
  name: 'AppTreeSelect',
  components: {
    TreeSuggest,
    BaseScroll,
    preloader
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        label: '',
        id: null
      })
    },
    valueMinLength: {
      type: Number,
      default: 3
    },
    placeholder: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    apiEntity: {
      type: String,
      default: ''
    },
    apiMethod: {
      type: String,
      default: ''
    },
    apiMethodParams: {
      type: Array,
      default: () => []
    },
    modelName: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    cancelCheckSubItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: {
        label: '',
        id: null
      },
      isLoading: false,
      isOpened: false,
      items: []
    }
  },
  searchTimer: null,
  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.innerValue = {
            label: '',
            id: null
          }
          return
        }
        this.innerValue = structuredClone(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutSide)
  },
  destroyed() {
    document.body.removeEventListener('click', this.clickOutSide)
  },
  methods: {
    search() {
      this.$emit('search', this.innerValue.label)

      if (!this.innerValue.label || this.innerValue.label.length < this.valueMinLength) {
        return
      }

      this.isOpened = true
      this.isLoading = true
      this.items = []

      clearTimeout(this.$options.searchTimer)
      this.$options.searchTimer = setTimeout(async () => {
        const params = [this.innerValue.label, ...this.apiMethodParams]
        try {
          const response = await this.$api[this.apiEntity][this.apiMethod](...params)
          const suggests = response.data[this.modelName]
          if (suggests.length) {
            this.highlightSearchText(suggests)
            this.items = suggests
          }
        } catch (err) {
          this.$notify({
            type: 'error',
            title: 'Не удалось выполнить поиск категории'
          })
        } finally {
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        }
      }, 400)
    },
    highlightSearchText(items) {
      items.forEach(item => {
        if (item.children?.length) {
          this.highlightSearchText(item.children)
        }

        const regex = new RegExp(this.innerValue.label, 'gi')
        item.hightLightLabel = item.label.replace(regex, `<strong>$&</strong>`)
      })
    },
    onChange(item) {
      this.isOpened = false
      this.innerValue = { label: item.label, id: item.id }
      this.$emit('change', { newValue: item, oldValue: this.value })
      this.$emit('input', item)
    },
    onFocus() {
      if (!this.isOpened) {
        this.search(this.innerValue.label)
      }
    },
    clickOutSide(e) {
      if (e.target.closest('.app-tree-select')) {
        return
      }

      this.isOpened = false
    },
    checkInnerValue(e) {
      setTimeout(() => {
        if (this.innerValue.id === this.value.id && this.innerValue.label !== this.value.label) {
          this.innerValue = structuredClone(this.value)
        }
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-tree-select {
  z-index: 2;
  position: relative;
}

.suggests {
  min-width: 100%;
  top: 100%;
  position: absolute;
  padding: $vr-pink 16px;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1;
  overflow-y: auto;
}
</style>
