import nomenclature from './nomenclature'
import file from './file'

export default function () {
  return {
    file: file(),
    supplierNomenclature: nomenclature(),
    errors: true,
    isBlocked: true,
    blockedErrors: true,
    id: true,
    filename: true,
    mimeType: true,
    size: true,
    width: true,
    height: true,
    url: true
  }
}
