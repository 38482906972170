export default function () {
  return {
    totalCount: true,
    reports: {
      createdAt: true,
      reportDate: true,
      documentNumber: true,
      amount: true,
      downloadUrl: true,
      downloadUrlXlsx: true
    }
  }
}
