import defaultUploadSchema from '@/schema/upload'
import defaultUploadItemSchema from '@/schema/uploadItem'
import addOrderBy from '@/mixins/addOrderBy'
import addFilters from '@/mixins/addFilters'

const getUploadQuery = (uploadId, fields) => {
  return {
    query: {
      upload: {
        __args: {
          upload: {
            uploadId
          }
        },
        ...(Object.keys(fields).length ? fields : defaultUploadSchema())
      }
    }
  }
}

const getNomenclatureListByUploadIdQuery = (uploadId, fields, limit, offset, orderBy) => {
  const defaultFields = {
    totalCount: true,
    file: true,
    items: defaultUploadItemSchema()
  }

  const query = {
    query: {
      uploadItems: {
        __args: {
          upload: { uploadId },
          offset,
          limit
        },
        ...(Object.keys(fields).length ? fields : defaultFields)
      }
    }
  }

  addOrderBy('successUploadItems', orderBy, query)

  return query
}

const getUploadListQuery = (supplierId, fields, limit, offset, filters, orderBy) => {
  const defaultFields = {
    totalCount: true,
    items: defaultUploadSchema()
  }

  const query = {
    query: {
      uploadsList: {
        __args: {
          supplier: { supplierId },
          offset,
          limit
        },
        ...(Object.keys(fields).length ? fields : defaultFields)
      }
    }
  }

  addOrderBy('uploadsList', orderBy, query)
  addFilters('uploadsList', filters, query)

  return query
}

const getSendToCoordinationQuery = uploadId => {
  return {
    mutation: {
      sendUploadItems: {
        __args: {
          upload: { uploadId }
        }
      }
    }
  }
}

export const get =
  ({ $axios }) =>
  (jobId, fields = {}) => {
    const query = getUploadQuery(jobId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getNomenclatureListByUploadId =
  ({ $axios }) =>
  (uploadId, fields = {}, limit = 10, offset = 0, orderBy = null) => {
    const query = getNomenclatureListByUploadIdQuery(uploadId, fields, limit, offset, orderBy)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const list =
  ({ $axios }) =>
  (supplierId, fields = {}, limit = 10, offset = 0, filters = {}, orderBy = null) => {
    const query = getUploadListQuery(supplierId, fields, limit, offset, filters, orderBy)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const sendToCoordination =
  ({ $axios }) =>
  (uploadId, fields = {}, limit = 10, offset = 0, orderBy = null) => {
    const query = getSendToCoordinationQuery(uploadId, fields, limit, offset, orderBy)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
