import defaultNomenclatureTechParamsSchema from '@/schema/nomenclatureTechParams'
import defaultNomenclatureTechParamsDiffSchema from '@/schema/nomenclatureTechParamsDiff'
import {
  TECH_PARAM_TYPE_BOOL,
  TECH_PARAM_TYPE_COLOR,
  TECH_PARAM_TYPE_DECIMAL,
  TECH_PARAM_TYPE_INTEGER,
  TECH_PARAM_TYPE_RANGE,
  TECH_PARAM_TYPE_SINGLE_LIST,
  TECH_PARAM_TYPE_STRING
} from '@/constants'

function getTechParamsListQuery(nomenclatureId, fields, newCategoryId = null) {
  return {
    query: {
      nomenclatureTechParams: {
        __args: {
          supplierNomenclature: {
            supplierNomenclatureId: nomenclatureId
          },
          newCategoryId
        },
        ...(Object.keys(fields).length ? fields : defaultNomenclatureTechParamsSchema())
      }
    }
  }
}

export const list =
  ({ $axios }) =>
  (nomenclatureId, fields = {}, newCategoryId = null) => {
    const query = getTechParamsListQuery(nomenclatureId, fields, newCategoryId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

function getRangeNumber(item) {
  const rangeNum = parseFloat(item)
  return rangeNum || rangeNum === 0 ? rangeNum : null
}

function getSaveTechParamMutation({ nomenclatureId, guid, techParamType, newValue, oldValue }, $json2graphql) {
  let valueType
  const valueTypes = []
  let newTechParamValue
  let oldTechParamValue

  switch (techParamType) {
    case TECH_PARAM_TYPE_SINGLE_LIST:
    case TECH_PARAM_TYPE_COLOR:
    case TECH_PARAM_TYPE_STRING:
      valueType = 'stringValue'
      newValue = newValue !== null ? String(newValue) : newValue
      oldValue = oldValue !== null ? String(oldValue) : oldValue
      break
    case TECH_PARAM_TYPE_DECIMAL:
      valueType = 'floatValue'
      newValue = parseFloat(newValue)
      oldValue = parseFloat(oldValue)
      break
    case TECH_PARAM_TYPE_INTEGER:
      valueType = 'intValue'
      newValue = parseInt(newValue)
      oldValue = parseInt(oldValue)
      break
    case TECH_PARAM_TYPE_BOOL:
      valueType = 'boolValue'
      break
    case TECH_PARAM_TYPE_RANGE:
      valueTypes[0] = 'rangeValueFrom'
      valueTypes[1] = 'rangeValueTo'
      break
    default:
      break
  }

  if (techParamType === TECH_PARAM_TYPE_RANGE) {
    newTechParamValue = {
      [valueTypes[0]]: getRangeNumber(newValue[0]),
      [valueTypes[1]]: getRangeNumber(newValue[1])
    }
    oldTechParamValue = {
      [valueTypes[0]]: getRangeNumber(oldValue.from),
      [valueTypes[1]]: getRangeNumber(oldValue.to)
    }
  } else {
    newTechParamValue = {
      [valueType]: newValue
    }
    oldTechParamValue = {
      [valueType]: oldValue
    }
  }

  return {
    saveTechParam: {
      __args: {
        supplierNomenclature: {
          supplierNomenclatureId: nomenclatureId
        },
        guid,
        techParamType: new $json2graphql.EnumType(techParamType),
        newValue: newTechParamValue,
        oldValue: oldTechParamValue
      },
      __on: [
        {
          __typeName: 'TechParamScalarValue',
          value: true
        },
        {
          __typeName: 'TechParamRangeValue',
          from: true,
          to: true
        }
      ]
    }
  }
}

export const save =
  ({ $axios, $json2graphql }) =>
  (nomenclatureId, guid, techParamType, newValue, oldValue) => {
    const saveTechParamMutation = getSaveTechParamMutation(
      {
        nomenclatureId,
        guid,
        techParamType,
        newValue,
        oldValue
      },
      $json2graphql
    )

    const query = {
      mutation: saveTechParamMutation
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveAll =
  ({ $axios, $json2graphql }) =>
  techParams => {
    const query = { mutation: {} }

    techParams.forEach((techParam, index) => {
      query.mutation['saveTechParamMutation' + index] = {
        __aliasFor: 'saveTechParam',
        ...getSaveTechParamMutation(techParam, $json2graphql).saveTechParam
      }
    })

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const createNewValue =
  ({ $axios }) =>
  (nomenclatureId, techParamGuid, value) => {
    const query = {
      mutation: {
        createTechParamAvailableValue: {
          __args: {
            supplierNomenclature: {
              supplierNomenclatureId: nomenclatureId
            },
            techParamGuid,
            value
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const removeValue =
  ({ $axios }) =>
  (nomenclatureId, techParamGuid, availableValueId) => {
    const query = {
      mutation: {
        deleteTechParamAvailableValue: {
          __args: {
            supplierNomenclature: {
              supplierNomenclatureId: nomenclatureId
            },
            techParamGuid,
            availableValueId
          },
          __on: [
            {
              __typeName: 'TechParamScalarValue',
              value: true
            }
          ]
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const diffTechParams =
  ({ $axios }) =>
  (nomenclatureId, currentCategoryId, newCategoryId, fields = {}) => {
    const query = {
      query: {
        techParamsDiff: {
          __args: {
            supplierNomenclature: {
              supplierNomenclatureId: nomenclatureId
            },
            currentCategoryId,
            newCategoryId
          },
          ...(Object.keys(fields).length ? fields : defaultNomenclatureTechParamsDiffSchema())
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
