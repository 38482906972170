<template>
  <div :class="'grid-col-' + col">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppCol',
  props: {
    col: {
      type: String,
      default: '12'
    }
  }
}
</script>
