export default function () {
  return {
    id: true,
    position: true,
    title: true,
    previewUrl: true,
    cloudUrl: true,
    width: true,
    height: true,
    external: true,
    mainOnSite: true,
    processStatus: true
  }
}
