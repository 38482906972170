<template>
  <div class="fixed-left-column">
    <div v-if="isLeftColumn" class="app-left-column">
      <div :class="{ 'app-left-column-wrapper': hasPositionForWrapper }">
        <slot name="left" />
      </div>
    </div>
    <div :class="{ 'app-right-column': isAppRightColumn, 'hidden-right-column': isRightColumnHidden }">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFixedLeftColumn',
  props: {
    isRightColumnHidden: {
      type: Boolean,
      default: false
    },
    hasPositionForWrapper: {
      type: Boolean,
      default: true
    },
    isLeftColumn: {
      type: Boolean,
      default: true
    },
    isAppRightColumn: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-left-column {
  display: flex;

  > .app-left-column {
    width: 288px;
    flex-shrink: 0;
  }

  > .app-right-column {
    max-width: calc(100% - 288px);
    padding-left: 24px;
    flex: 1;
  }

  > .hidden-right-column {
    width: 100%;
  }
}

.app-left-column-wrapper {
  position: sticky;
  top: calc($headerHeight + 28px);
}
</style>
