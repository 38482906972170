export default function () {
  return {
    canSendOnCoordination: true,
    canSendErrorMessage: true,
    alerts: true,
    informationBlock: {
      requiredFieldsCount: true,
      fieldsCount: true,
      errorMessages: true,
      hasValueRequiredFieldsCount: true,
      hasValueUnRequiredFieldsCount: true,
      fields: {
        name: true,
        required: true,
        constraint: {
          min: true,
          max: true
        }
      }
    },
    techParamsBlock: {
      minPercent: true,
      minSearchPercent: true,
      count: true,
      hasValueCount: true,
      hasCategory: true,
      errorMessages: true
    },
    media: {
      imagesIsRequired: true,
      instructionsIsRequired: true,
      errorMessages: true,
      imagesCount: true,
      instructionsCount: true
    }
  }
}
