<template>
  <div class="app-note-detail">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'AppFieldNoteDetail',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.app-note-detail {
  margin-top: $vr-yellow;
  font-size: 12px;
  padding: 0 16px;
  color: $gray-80;
}
</style>
