<template>
  <ContentLoader
    height="43"
    class="list-loader"
    :style="{
      height: '43px',
      width: '100%',
      paddingLeft: isHorizontalPadding ? '24px' : 0,
      paddingRight: isHorizontalPadding ? '24px' : 0
    }"
    :speed="1"
    primary-color="#eaeced"
    secondary-color="#FFFFFF"
    preserve-aspect-ratio="none"
  >
    <rect x="0" y="4" rx="1" ry="1" width="100%" height="13" />
    <rect x="0" y="25" rx="1" ry="1" width="10%" height="12" />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'ListLoader',
  components: {
    ContentLoader
  },
  props: {
    isHorizontalPadding: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.list-loader {
  padding: $vr-pink 24px;
  box-sizing: content-box;
}
</style>
