<template>
  <FilterBox :is-active="isActive" @applyFilter="applyFilter" @clearFilter="clearFilter">
    <template #title>
      {{ label }}
    </template>
    <template #content>
      <base-input v-model="innerValue" :is-async="true" size="small" />
    </template>
  </FilterBox>
</template>

<script>
import FilterBox from '@/components/filters/FilterBox'

export default {
  name: 'SearchFilter',
  components: {
    FilterBox
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Введите фразу'
    }
  },
  data() {
    return {
      innerValue: this.value
    }
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = value
      }
    },
    immediate: true
  },
  methods: {
    applyFilter() {
      this.$emit('apply-filter', this.innerValue)
    },
    clearFilter() {
      this.innerValue = ''
      this.applyFilter()
    }
  }
}
</script>
