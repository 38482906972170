export default function (value) {
  if (!value) return '-'

  const date = this.$dayjs(value)

  if (date.isToday()) {
    return 'Сегодня'
  }

  if (date.isYesterday()) {
    return 'Вчера'
  }

  return date.format('DD.MM.YYYY')
}
