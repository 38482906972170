import { VariableType } from 'json-to-graphql-query'
import { getPlanReserveManagerListQueryMock, getPlanReserveSupplierListQueryMock } from './mocks/plannedReservesMocks'
import addFilesToQuery from '@/mixins/addFilesToQuery'

const useMocks = false

const getPlanReserveManagerListQuery = search => {
  return {
    query: {
      planReserveManagerList: {
        __args: {
          search
        },
        list: [
          {
            id: true,
            fio: true
          }
        ]
      }
    }
  }
}

const getPlanReserveSupplierListQuery = (search, managerId, pageNumber, perPage, sortField, sortDir) => {
  return {
    query: {
      planReserveSupplierList: {
        __args: {
          search,
          managerId: managerId === null ? null : Number(managerId),
          page: Number(pageNumber),
          perPage: Number(perPage),
          sortField,
          sortDir
        },
        pagesCount: true,
        totalCount: true,
        list: [
          {
            supplier: {
              id: true,
              name: true,
              worksWithPlanReserve: true
            },
            manager: {
              id: true,
              fio: true
            },
            stats: {
              answeredItemsCount: true,
              nonAnsweredItemsCount: true
            }
          }
        ]
      }
    }
  }
}

const getPlanReserveRequestListQuery = ({
  supplierId,
  pageNumber = 1,
  perPage = 10,
  warehouseGuid,
  matrixName = '',
  manufacturerId,
  searchString = '',
  monthStart = null,
  yearStart = null,
  reservePattern = '',
  reserveValue = null,
  answerPattern = '',
  answerValue = null,
  showNonAnswered,
  showAnswersLessThanRequirements,
  showChangedRequirements,
  showChangedAnswers
}) => {
  return {
    query: {
      planReserveRequestList: {
        __args: {
          filter: {
            supplierId,
            warehouseGuid,
            matrixName,
            manufacturerId: Number(manufacturerId),
            searchString,
            monthStart: Number(monthStart) || null,
            yearStart: Number(yearStart) || null,
            reservePattern,
            reserveValue: Number(reserveValue) || null,
            answerPattern,
            answerValue: Number(answerValue) || null,
            showNonAnswered: showNonAnswered === 'true',
            showAnswersLessThanRequirements: showAnswersLessThanRequirements === 'true',
            showChangedRequirements: showChangedRequirements === 'true',
            showChangedAnswers: showChangedAnswers === 'true'
          },
          page: Number(pageNumber),
          perPage: Number(perPage)
        },
        totalCount: true,
        supplier: {
          name: true
        },
        pagesCount: true,
        periods: [],
        list: {
          nomenclatureId: true,
          nomenclatureName: true,
          nomenclatureVendorCode: true,
          nomenclatureMatrix: true,
          hasAnswerChanges: true,
          hasRequirementChanges: true,
          reserveData: {
            id: true,
            requirement: true,
            container: true,
            repaymentPercent: true,
            answer: true,
            isRequirementConfirmed: true,
            isAnswerConfirmed: true,
            confirmedRequirement: true,
            confirmedAnswer: true,
            lastComment: true,
            period: true
          }
        }
      }
    }
  }
}

const getPlanReserveRequestWarehousesQuery = () => {
  return {
    query: {
      warehouses: {
        name: true,
        guid: true
      }
    }
  }
}

const getPlanReserveSupplierManufacturersQuery = (supplierId, searchPattern) => {
  return {
    query: {
      supplierManufacturers: {
        __args: {
          supplierId,
          searchPattern
        },
        name: true,
        id: true
      }
    }
  }
}

const getPlanReserveTypesQuery = () => {
  return {
    query: {
      planReserveTypes: {
        id: true,
        name: true
      }
    }
  }
}

const getWarehousesQuery = () => {
  return {
    query: {
      warehouses: {
        guid: true,
        name: true
      }
    }
  }
}

const getPlanReserveSuppliersQuery = pattern => {
  return {
    query: {
      planReserveSuppliers: {
        __args: {
          pattern
        },
        id: true,
        guid: true,
        name: true
      }
    }
  }
}

const getPlanReserveFileInfoQuery = fileId => {
  return {
    query: {
      planReserveFileInfo: {
        __args: {
          fileId
        },
        id: true,
        parse_job_id: true,
        repayment_percent: true,
        file_id: true,
        error_file_id: true,
        reserve_type: true,
        status: true,
        error_user_message: true,
        errorFile: {
          downloadUrl: true,
          id: true,
          guid: true,
          name: true,
          extension: true,
          createdAt: true,
          updatedAt: true
        },
        successCount: true
      }
    }
  }
}

const getPlanReserveRequestAnswerQuery = planReserveUploadRequestAnswerId => {
  return {
    query: {
      planReserveRequestAnswer: {
        __args: {
          planReserveUploadRequestAnswerId
        },
        id: true,
        job_id: true,
        file_id: true,
        error_file_id: true,
        plan_reserve_id: true,
        is_supplier: true,
        status: true,
        error_user_message: true,
        errorFile: {
          downloadUrl: true,
          id: true,
          guid: true,
          name: true,
          extension: true,
          createdAt: true,
          updatedAt: true
        },
        successCount: true
      }
    }
  }
}

const getPlanReserveLastActiveRequestAnswerQuery = (supplierId, warehouseGuid) => {
  return {
    query: {
      planReserveLastActiveRequestAnswer: {
        __args: {
          filter: {
            supplierId: Number(supplierId),
            warehouseGuid
          }
        },
        id: true
      }
    }
  }
}

const getPlanReserveLastActiveFileInfoQuery = (supplierId, warehouseGuid) => {
  return {
    query: {
      planReserveLastActiveFileInfo: {
        __args: {
          filter: {
            supplierId: Number(supplierId),
            warehouseGuid
          }
        },
        id: true
      }
    }
  }
}

const getPlanReserveNomenclatureChangesQuery = ({
  pageNumber = 1,
  supplierId,
  perPage = 10,
  search = '',
  authorId,
  changedFromDate = null,
  changedToDate = null,
  periodFromDate = null,
  periodToDate = null,
  changeLogType = ''
}) => {
  return {
    query: {
      planReserveNomenclatureChanges: {
        __args: {
          changesFilter: {
            supplierId: Number(supplierId),
            search,
            authorId: Number(authorId),
            changedFromDate,
            changedToDate,
            periodFromDate,
            periodToDate,
            changeLogType
          },
          perPage: Number(perPage),
          pageNum: Number(pageNumber)
        },
        totalCount: true,
        pagesCount: true,
        changesList: {
          nomenclatureName: true,
          authorName: true,
          comment: true,
          period: true,
          changeTypeLabel: true,
          id: true,
          changeType: true,
          valueOld: true,
          valueNew: true,
          createdAt: true
        },
        supplier: {
          name: true
        }
      }
    }
  }
}

const getPlanReserveChangesAuthorsQuery = supplierId => {
  return {
    query: {
      planReserveChangesAuthors: {
        __args: {
          supplierId: Number(supplierId)
        },
        id: true,
        email: true,
        fio: true
      }
    }
  }
}

const setPlanReserveSupplierManagerMutation = (supplierId, managerId) => {
  return {
    mutation: {
      setPlanReserveSupplierManager: {
        __args: {
          supplierId,
          managerId
        }
      }
    }
  }
}

const setReserveRequestValuesMutation = rows => {
  return {
    mutation: {
      setReserveRequestValues: {
        __args: {
          rows
        }
      }
    }
  }
}

const uploadPlanReserveFileMutation = (warehouse, supplierId, reserveType, counter) => {
  return {
    mutation: {
      __variables: {
        file: `Upload!`
      },
      uploadPlanReserveFile: {
        __args: {
          planReserveUploadFile: {
            warehouseGuid: warehouse.guid,
            supplierId,
            reserveType: reserveType.id,
            repaymentPercent: counter,
            uploadedFile: new VariableType('file')
          }
        }
      }
    }
  }
}

const uploadPlanReserveRequestAnswerMutation = (supplierId, warehouseGuid) => {
  return {
    mutation: {
      __variables: {
        file: `Upload!`
      },
      uploadPlanReserveRequestAnswer: {
        __args: {
          filter: {
            supplierId: Number(supplierId),
            warehouseGuid
          },
          uploadedFile: new VariableType('file')
        }
      }
    }
  }
}

const downloadPlanReserveRequestsMutation = ({
  supplierId,
  warehouseGuid,
  matrixName = '',
  manufacturerId,
  searchString = '',
  monthStart = null,
  yearStart = null,
  reservePattern = '',
  reserveValue = null,
  answerPattern = '',
  answerValue = null,
  showNonAnswered,
  showAnswersLessThanRequirements,
  showChangedRequirements,
  showChangedAnswers
}) => {
  return {
    mutation: {
      downloadPlanReserveRequests: {
        __args: {
          filter: {
            supplierId: Number(supplierId),
            warehouseGuid,
            matrixName,
            manufacturerId: Number(manufacturerId),
            searchString,
            monthStart: Number(monthStart) || null,
            yearStart: Number(yearStart) || null,
            reservePattern,
            reserveValue: Number(reserveValue) || null,
            answerPattern,
            answerValue: Number(answerValue) || null,
            showNonAnswered: showNonAnswered === 'true',
            showAnswersLessThanRequirements: showAnswersLessThanRequirements === 'true',
            showChangedRequirements: showChangedRequirements === 'true',
            showChangedAnswers: showChangedAnswers === 'true'
          }
        }
      }
    }
  }
}

const sendNotificationOnDateMutation = (
  {
    supplierId,
    warehouseGuid,
    matrixName = '',
    manufacturerId,
    searchString = '',
    monthStart = null,
    yearStart = null,
    reservePattern = '',
    reserveValue = null,
    answerPattern = '',
    answerValue = null,
    showNonAnswered,
    showAnswersLessThanRequirements,
    showChangedRequirements,
    showChangedAnswers
  },
  dateTime
) => {
  return {
    mutation: {
      sendNotificationOnDate: {
        __args: {
          filter: {
            supplierId: Number(supplierId),
            warehouseGuid,
            matrixName,
            manufacturerId: Number(manufacturerId),
            searchString,
            monthStart: Number(monthStart) || null,
            yearStart: Number(yearStart) || null,
            reservePattern,
            reserveValue: Number(reserveValue) || null,
            answerPattern,
            answerValue: Number(answerValue) || null,
            showNonAnswered: showNonAnswered === 'true',
            showAnswersLessThanRequirements: showAnswersLessThanRequirements === 'true',
            showChangedRequirements: showChangedRequirements === 'true',
            showChangedAnswers: showChangedAnswers === 'true'
          },
          dateTime
        }
      }
    }
  }
}

export const getPlanReserveManagerList =
  ({ $axios }) =>
  (search = null) => {
    if (useMocks) return getPlanReserveManagerListQueryMock()
    const query = getPlanReserveManagerListQuery(search)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveSupplierList =
  ({ $axios, $json2graphql }) =>
  ({
    search = '',
    managerId,
    pageNumber = 1,
    perPage = 10,
    sortField = 'supplier',
    sortDir = new $json2graphql.EnumType('ASC')
  }) => {
    if (useMocks) return getPlanReserveSupplierListQueryMock()
    const query = getPlanReserveSupplierListQuery(search, managerId, pageNumber, perPage, sortField, sortDir)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveTypes =
  ({ $axios }) =>
  () => {
    const query = getPlanReserveTypesQuery()
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getWarehouses =
  ({ $axios }) =>
  () => {
    const query = getWarehousesQuery()
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveSuppliers =
  ({ $axios }) =>
  pattern => {
    const query = getPlanReserveSuppliersQuery(pattern)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveFileInfo =
  ({ $axios }) =>
  fileId => {
    const query = getPlanReserveFileInfoQuery(fileId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveRequestList =
  ({ $axios }) =>
  filter => {
    const query = getPlanReserveRequestListQuery(filter)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveWarehouses =
  ({ $axios }) =>
  () => {
    const query = getPlanReserveRequestWarehousesQuery()
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveSupplierManufacturers =
  ({ $axios }) =>
  ({ supplierId, search = '' }) => {
    const query = getPlanReserveSupplierManufacturersQuery(supplierId, search)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveRequestAnswer =
  ({ $axios }) =>
  planReserveUploadRequestAnswerId => {
    const query = getPlanReserveRequestAnswerQuery(planReserveUploadRequestAnswerId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveLastActiveRequestAnswer =
  ({ $axios }) =>
  ({ supplierId, warehouseGuid }) => {
    const query = getPlanReserveLastActiveRequestAnswerQuery(supplierId, warehouseGuid)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveLastActiveFileInfo =
  ({ $axios }) =>
  ({ supplierId, warehouseGuid }) => {
    const query = getPlanReserveLastActiveFileInfoQuery(supplierId, warehouseGuid)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveNomenclatureChanges =
  ({ $axios }) =>
  filter => {
    const query = getPlanReserveNomenclatureChangesQuery(filter)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPlanReserveChangesAuthors =
  ({ $axios }) =>
  supplierId => {
    const query = getPlanReserveChangesAuthorsQuery(supplierId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const setPlanReserveSupplierManager =
  ({ $axios }) =>
  ({ supplierId, managerId = null }) => {
    if (useMocks) return true
    const query = setPlanReserveSupplierManagerMutation(supplierId, managerId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const setReserveRequestValues =
  ({ $axios }) =>
  (rows = []) => {
    const query = setReserveRequestValuesMutation(rows)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const uploadPlanReserveFile =
  ({ $axios }) =>
  ({ warehouse = {}, supplierId, reserveType = {}, counter, file }) => {
    const query = uploadPlanReserveFileMutation(warehouse, supplierId, reserveType, counter)
    const formData = addFilesToQuery(file[0].file, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

export const uploadPlanReserveRequestAnswer =
  ({ $axios }) =>
  ({ supplierId, warehouseGuid, file }) => {
    const query = uploadPlanReserveRequestAnswerMutation(supplierId, warehouseGuid)
    const formData = addFilesToQuery(file[0].file, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

export const downloadPlanReserveRequests =
  ({ $axios }) =>
  filter => {
    const query = downloadPlanReserveRequestsMutation(filter)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
export const sendNotificationOnDate =
  ({ $axios }) =>
  (filter, dateTime) => {
    const query = sendNotificationOnDateMutation(filter, dateTime)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
