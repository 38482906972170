<template>
  <FilterBox :is-active="isActive" @applyFilter="applyFilter" @clearFilter="clearFilter">
    <template #title>
      {{ label }}
    </template>
    <template #content>
      <BaseSelect v-model="innerValue" size="small" :options="innerItems" />
    </template>
  </FilterBox>
</template>

<script>
import FilterBox from '@/components/filters/FilterBox'

export default {
  name: 'SelectFilter',
  components: {
    FilterBox
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    dataProvider: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: 'Выберите вариант'
    }
  },
  data() {
    return {
      innerValue: {
        text: '',
        value: ''
      },
      innerItems: [
        {
          text: '',
          value: ''
        }
      ]
    }
  },
  async fetch() {
    if (!this.dataProvider) {
      return
    }

    try {
      const response = await this.$axios({
        url: this.dataProvider.url,
        method: this.dataProvider.method,
        data: this.dataProvider.data
      })

      this.innerItems.push(...response.data.data[this.dataProvider.model])
      this.innerValue = this.innerItems.find(item => {
        return item.value === this.value
      })
    } catch (err) {
      this.$notify({
        type: 'error',
        title: err.errorMessage || err.message || 'Не удалось получить список'
      })
    }
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = this.innerItems.find(item => {
          return item.value === value
        })
      },
      immediate: true
    },
    items: {
      handler(value) {
        if (!value) {
          return
        }
        this.innerItems = [
          {
            text: '',
            value: ''
          }
        ]
        this.innerItems.push(...JSON.parse(JSON.stringify(this.items)))
        this.innerValue = this.innerItems.find(item => {
          return item.value === this.value
        })
      },
      immediate: true
    }
  },
  methods: {
    applyFilter() {
      this.$emit('apply-filter', this.innerValue.value)
    },
    clearFilter() {
      this.innerValue = this.innerItems.find(item => {
        return item.value === ''
      })
      this.applyFilter()
    }
  }
}
</script>
