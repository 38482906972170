import { NOT_EXIST_POSITION, ADMIN_PERMISSION } from '@/constants'

export default async function ({ store, route }) {
  const isUserAcceptAgreement = await store.getters['user/isUserAcceptAgreement']
  const isUserAgreementPage = route.path.indexOf('/user-agreement') !== NOT_EXIST_POSITION
  const isUserAdmin = await store.getters['user/hasOnePermission']([ADMIN_PERMISSION])

  if (!isUserAcceptAgreement && !isUserAgreementPage && !isUserAdmin) {
    store.dispatch('userAgreementModal/setVisible', true)
  }
}
