<template>
  <li :class="['item', { '-muted': isExistsSubItems }]">
    <base-icon
      v-if="isExistsSubItems"
      icon="drop-down"
      :class="['list-icon', { '-turned': isOpen }]"
      @click.native="onClick(item)"
    />
    <div ref="label" class="label" tabindex="0" @click="onClick(item)" @keydown.enter="onClick(item)">
      <slot name="option" :item="item">
        <span v-html="item.hightLightLabel"></span>
      </slot>
    </div>
    <ul v-if="isExistsSubItems" ref="sublist" :class="['sublist', { '-opened': isOpen }]">
      <TreeSuggest
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :cancel-check-sub-item="cancelCheckSubItem"
        @change="$emit('change', $event)"
      >
        <template #option="{ item }">
          <slot name="option" :item="item" />
        </template>
      </TreeSuggest>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeSuggest',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    cancelCheckSubItem: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isOpen: true,
    numberSelectedRow: 0
  }),
  computed: {
    isExistsSubItems() {
      return this.item?.children && this.item.children?.length
    }
  },
  methods: {
    onClick() {
      if (!this.isExistsSubItems || this.cancelCheckSubItem) {
        this.$emit('change', JSON.parse(JSON.stringify(this.item)))
        return
      }

      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  color: $gray-90;

  > .list-icon {
    font-size: 20px;
    transition: transform 0.1s;

    &.-turned {
      transform: rotate(180deg);
    }
  }

  > .label {
    cursor: pointer;
    user-select: none;
    flex: 1;

    &:hover {
      position: relative;
      left: 0;
      background-color: $blue-10;
    }

    &:focus {
      border: 0;
      outline: none;
      background-color: $blue-10;
    }
  }

  > .sublist {
    width: 100%;
    display: none;
    padding-left: 24px;

    &.-opened {
      display: block;
    }
  }

  &.-muted {
    color: $gray-60;
  }
}
</style>
