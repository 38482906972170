import Vue from 'vue'
import VueSSE from 'vue-sse'

Vue.use(VueSSE)

export default function ({ $sentry }, inject) {
  const sse = {
    instance: null,
    init() {
      this.instance = Vue.$sse.create({
        polyfill: true,
        url: window.PAS.ENV.PAS_URL + '/sse/notifications',
        withCredentials: true
      })

      this.instance.connect().catch(err => {
        $sentry.captureException(err)
      })
    },

    destroy() {
      this.instance.disconnect()
    }
  }

  inject('SSE', sse)
}
