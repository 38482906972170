import { NOT_EXIST_POSITION } from '@/constants'
import { USER_TYPES } from '@/constants/user'

export default async function ({ $api, route, store, error, redirect }) {
  const hasUser = store.state.user.user?.id
  const isBrowserUpdatePage = /^\/browser-update\/?$/.test(route.path)
  const isHomepage = route.path === '/'
  const isUserAgreementPage = route.path.indexOf('/user-agreement') !== NOT_EXIST_POSITION

  if (isUserAgreementPage || isBrowserUpdatePage) {
    return
  }

  if (hasUser && !isHomepage) {
    return
  }

  try {
    const response = await $api.user.get()
    const user = response.data.user
    store.dispatch('user/setUser', user)
    const userType = store.getters['user/getUserType']

    if (userType !== USER_TYPES.supplier && !route.path.match('/app/admin')) {
      redirect(window.PAS.ENV.ADMIN_URL)
    }
    if (userType === USER_TYPES.supplier && route.path.match('/app/admin')) {
      redirect('/planned-reserves')
    }
  } catch (err) {
    return error({ statusCode: err?.response?.status })
  }
}
