const getChatBot = fields => {
  return {
    query: {
      chatBot: {
        fields: {
          display_name: true,
          email: true,
          id: true
        },
        hash: true
      }
    }
  }
}

export const chatBot =
  ({ $axios }) =>
  () => {
    const query = getChatBot()
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
