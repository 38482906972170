import defaultNomenclatureSchema from '@/schema/nomenclature'

export default function () {
  return {
    items: {
      ...defaultNomenclatureSchema()
    },
    totalCount: true
  }
}
