import { GRAPHQL_TYPE_ERROR_INTERNAL, GRAPHQL_TYPE_ERROR_USER, GRAPHQL_TYPE_ERROR_VALIDATION } from '@/constants'

function getGraphqlErrors(err) {
  if (Array.isArray(err) && err[0]) {
    const typeError = err[0].extensions.category

    if (typeError === GRAPHQL_TYPE_ERROR_VALIDATION) {
      return {
        type: GRAPHQL_TYPE_ERROR_VALIDATION,
        detail: err[0].extensions.fieldErrors
      }
    }

    if (typeError === GRAPHQL_TYPE_ERROR_USER) {
      return {
        type: GRAPHQL_TYPE_ERROR_USER,
        detail: err[0].message
      }
    }

    if (typeError === GRAPHQL_TYPE_ERROR_INTERNAL) {
      return {
        type: GRAPHQL_TYPE_ERROR_INTERNAL,
        detail: err[0].message
      }
    }
  } else {
    return {}
  }
}

export default (context, inject) => {
  inject('getGraphqlErrors', getGraphqlErrors)
}
