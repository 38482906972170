import { jsonToGraphQLQuery } from 'json-to-graphql-query'

export default function (file, query) {
  const formData = new FormData()

  if (Array.isArray(file)) {
    const operations = jsonToGraphQLQuery(query)
    const map = {}
    const variablesFiles = { file: [] }
    file.forEach((file, index) => {
      map[index] = [`variables.file.${index}`]
      variablesFiles.file.push(null)
      formData.append(index, file)
    })

    const operationsString = JSON.stringify(operations)
    const variablesFilesString = JSON.stringify(variablesFiles)
    const mapString = JSON.stringify(map)

    formData.append('operations', '{ "query": ' + operationsString + ', "variables":' + variablesFilesString + '}')
    formData.append('map', mapString)
  } else {
    const operations = jsonToGraphQLQuery(query)
    const newOperations = JSON.stringify(operations)
    const map = `{"0": ["variables.file"]}`

    formData.append(
      'operations',
      '{ "query": ' + newOperations + ', "variables": { "file": null }, "operationName":null }'
    )
    formData.append('map', map)
    formData.append('0', file)
  }

  return formData
}
