<template>
  <div class="svg-sprite-place" v-html="svgSpriteSrc"></div>
</template>

<script>
export default {
  name: 'AppSpritesPlace',
  computed: {
    svgSpriteSrc() {
      return require(`@/node_modules/@vi-frontend/svgs/dist/icons.svg?raw`)
    }
  }
}
</script>
