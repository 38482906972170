function useEmit(emit) {
  const emitId = id => {
    emit('remove-parent-error', id)
  }

  const showError = event => {
    emit('show-error', event)
  }
  return {
    emitId,
    showError
  }
}

export default useEmit
