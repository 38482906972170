<template>
  <div :class="['base-alert', `-${type}`]">
    <div class="content">
      <div class="title">
        {{ title }}
      </div>
      <div v-if="description" class="description">
        {{ description }}
      </div>
      <div v-if="$slots.description" class="description">
        <slot name="description" />
      </div>
      <div v-if="$slots.footer" class="footer">
        <slot name="footer" />
      </div>
      <AppFileUploader
        v-if="isShowUploadingButton"
        class="mt-3"
        :api-entity="apiEntity"
        :api-method="apiMethod"
        :api-method-params="apiMethodParams"
        :model-name="modelName"
        :valid-file-types="[
          'xls',
          'xlsx',
          'csv',
          'vnd.ms-excel',
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ]"
        is-immediate-upload
        is-multi-page-behavior
        @uploaded-to-cloud="onCloudUploaded"
      />
    </div>
  </div>
</template>

<script>
import AppFileUploader from '@/components/others/AppFileUploader'

export default {
  name: 'UploadAlert',
  components: {
    AppFileUploader
  },
  props: {
    isShowUploadingButton: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const arr = ['', 'info', 'success', 'warning', 'error']
        return arr.includes(value)
      }
    },
    apiEntity: {
      type: String,
      default: 'massUploading'
    },
    apiMethod: {
      type: String,
      default: 'uploadFile'
    },
    apiMethodParams: {
      type: Array,
      default: () => []
    },
    modelName: {
      type: String,
      default: 'contentMassUploadUploadFile'
    }
  },
  methods: {
    async onCloudUploaded(uploadId) {
      await this.$router.push('/uploads-history/' + uploadId + '#fullWidth')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-alert {
  display: flex;
  max-width: 920px;
  border-radius: 6px;

  > .icon-block {
    font-size: 2.4rem;
    margin-right: 16px;
    margin-top: 1px;
  }

  > .content {
    .title {
      margin-bottom: $vr-pink;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
    }

    .description {
      margin-bottom: $vr-pink;
      @include normal-text;
      color: $gray-90;

      > p {
        @include normal-text;
        color: $gray-90;
      }
    }

    .footer {
      margin-top: $vr-fuchsia;
      @include normal-text;
    }
  }

  &.-warning {
    .title {
      color: $orange;
    }
  }

  &.-error {
    .title {
      color: $red-50;
    }
  }

  &.-info {
    .title {
      color: $blue-50;
    }
  }
}
</style>
