<template>
  <div class="app">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MainApp'
}
</script>

<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
