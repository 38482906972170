import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03c4ffc5 = () => interopDefault(import('@/pages/index/index' /* webpackChunkName: "index" */))
const _5a3dab0a = () => interopDefault(import('@/pages/matrix_coordinations' /* webpackChunkName: "matrix-coordinations" */))
const _8aca1566 = () => interopDefault(import('@/pages/matrix_coordinations/_id.vue' /* webpackChunkName: "matrix-coordinations-id" */))
const _5be624f0 = () => interopDefault(import('@/pages/notifications_history/index.vue' /* webpackChunkName: "notifications-history" */))
const _1b037fa4 = () => interopDefault(import('@/pages/reports/spending' /* webpackChunkName: "reports-spending" */))
const _132efbe6 = () => interopDefault(import('@/pages/reports/realizations' /* webpackChunkName: "reports-realizations" */))
const _7c3efcb2 = () => interopDefault(import('@/pages/reports/remains' /* webpackChunkName: "reports-remains" */))
const _57e64aa5 = () => interopDefault(import('@/pages/reports/products-views' /* webpackChunkName: "reports-products-views" */))
const _7b3a2b8a = () => interopDefault(import('@/pages/reports/reviews' /* webpackChunkName: "reports-reviews" */))
const _2818129c = () => interopDefault(import('@/pages/nomenclature/_id' /* webpackChunkName: "nomenclature-product-id" */))
const _813f606c = () => interopDefault(import('@/pages/nomenclature/index' /* webpackChunkName: "nomenclatures" */))
const _37726ebf = () => interopDefault(import('@/pages/upload_new_nomenclature/index.vue' /* webpackChunkName: "upload-new-nomenclature" */))
const _26d148b2 = () => interopDefault(import('@/pages/uploads_history/index.vue' /* webpackChunkName: "uploads-history" */))
const _471dcf9a = () => interopDefault(import('@/pages/uploads_history/_id.vue' /* webpackChunkName: "uploads-history-id" */))
const _47b40b74 = () => interopDefault(import('@/pages/mass_uploading' /* webpackChunkName: "mass-uploading" */))
const _57c8decc = () => interopDefault(import('@/pages/mass_uploading_photos' /* webpackChunkName: "mass-uploading-photos" */))
const _4771d830 = () => interopDefault(import('@/pages/browser_update.vue' /* webpackChunkName: "browser-update" */))
const _595241be = () => interopDefault(import('@/pages/user_agreement.vue' /* webpackChunkName: "user-agreement" */))
const _16f06cd1 = () => interopDefault(import('@/pages/contract_registration/index.vue' /* webpackChunkName: "contract-registration" */))
const _84ae5d5c = () => interopDefault(import('@/pages/contract_registration/steps/step1/step1.vue' /* webpackChunkName: "" */))
const _529b9b94 = () => interopDefault(import('@/pages/contract_registration/steps/step2/step2.vue' /* webpackChunkName: "" */))
const _30e33454 = () => interopDefault(import('@/pages/contract_registration/steps/step3/step3.vue' /* webpackChunkName: "" */))
const _04907d43 = () => interopDefault(import('@/pages/contract_registration/clarification.vue' /* webpackChunkName: "contract-registration-clarification" */))
const _178a4e0d = () => interopDefault(import('@/pages/video_list/index.vue' /* webpackChunkName: "video-list" */))
const _ebea0e5c = () => interopDefault(import('@/pages/marketing/list/index.vue' /* webpackChunkName: "marketing" */))
const _b3ca15f2 = () => interopDefault(import('@/pages/marketing/item/index.vue' /* webpackChunkName: "marketing-id" */))
const _432d7ef2 = () => interopDefault(import('@/pages/landing' /* webpackChunkName: "landing" */))
const _9f44623c = () => interopDefault(import('@/pages/planned_reserves' /* webpackChunkName: "planned-reserves-manager" */))
const _1cb22192 = () => interopDefault(import('@/pages/planned_reserves/_id/history' /* webpackChunkName: "planned-reserves-id-history" */))
const _c56345e6 = () => interopDefault(import('@/pages/planned_reserves/_id' /* webpackChunkName: "planned-reserves-id-manager" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _03c4ffc5,
    name: "index"
  }, {
    path: "/matrix-coordinations",
    component: _5a3dab0a,
    name: "matrix-coordinations"
  }, {
    path: "/matrix-coordinations/:id",
    component: _8aca1566,
    name: "matrix-coordinations-id"
  }, {
    path: "/notifications-history",
    component: _5be624f0,
    name: "notifications-history"
  }, {
    path: "/reports/spending",
    component: _1b037fa4,
    name: "reports-spending"
  }, {
    path: "/reports/realizations",
    component: _132efbe6,
    name: "reports-realizations"
  }, {
    path: "/reports/remains",
    component: _7c3efcb2,
    name: "reports-remains"
  }, {
    path: "/reports/products-views",
    component: _57e64aa5,
    name: "reports-products-views"
  }, {
    path: "/reports/reviews",
    component: _7b3a2b8a,
    name: "reports-reviews"
  }, {
    path: "/nomenclatures/:id",
    component: _2818129c,
    name: "nomenclature-product-id"
  }, {
    path: "/nomenclatures/",
    component: _813f606c,
    name: "nomenclatures"
  }, {
    path: "/upload-new-nomenclature/",
    component: _37726ebf,
    name: "upload-new-nomenclature"
  }, {
    path: "/uploads-history/",
    component: _26d148b2,
    name: "uploads-history"
  }, {
    path: "/uploads-history/:id",
    component: _471dcf9a,
    name: "uploads-history-id"
  }, {
    path: "/mass-uploading/",
    component: _47b40b74,
    name: "mass-uploading"
  }, {
    path: "/mass-uploading-photos/",
    component: _57c8decc,
    name: "mass-uploading-photos"
  }, {
    path: "/mass-uploading-photos/:id",
    component: _57c8decc,
    name: "mass-uploading-photos-id"
  }, {
    path: "/browser-update",
    component: _4771d830,
    name: "browser-update"
  }, {
    path: "/user-agreement",
    component: _595241be,
    name: "user-agreement"
  }, {
    path: "/contract-registration",
    component: _16f06cd1,
    name: "contract-registration",
    children: [{
      path: "",
      component: _84ae5d5c
    }, {
      path: "step1",
      component: _84ae5d5c
    }, {
      path: "step2",
      component: _529b9b94
    }, {
      path: "step3",
      component: _30e33454
    }]
  }, {
    path: "/contract-registration/clarification",
    component: _04907d43,
    name: "contract-registration-clarification"
  }, {
    path: "/video-list",
    component: _178a4e0d,
    name: "video-list"
  }, {
    path: "/marketing-product-listing",
    component: _ebea0e5c,
    name: "marketing"
  }, {
    path: "/marketing-product-listing/:id",
    component: _b3ca15f2,
    name: "marketing-id"
  }, {
    path: "/marketing",
    component: _432d7ef2,
    name: "marketing"
  }, {
    path: "/app/admin/planned-reserves",
    component: _9f44623c,
    name: "planned-reserves-manager"
  }, {
    path: "/app/admin/planned-reserves/:id/history",
    component: _1cb22192,
    name: "planned-reserves-manager-history"
  }, {
    path: "/planned-reserves/history",
    component: _1cb22192,
    name: "planned-reserves-supplier-history"
  }, {
    path: "/app/admin/planned-reserves/:id",
    component: _c56345e6,
    name: "planned-reserves-id-manager"
  }, {
    path: "/planned-reserves",
    component: _c56345e6,
    name: "planned-reserves-id-supplier"
  }],

  parseQuery: function(query) {
      const qs = require('qs');
      return qs.parse(query);
    },
  stringifyQuery: function(query) {
      const qs = require('qs');
      const result = qs.stringify(query);
      return result ? '?' + result : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
