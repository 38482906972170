export default function () {
  return {
    totalCount: true,
    reports: {
      dateStart: true,
      dateEnd: true,
      amount: true,
      documentNumber: true,
      status: true,
      downloadUrl: true,
      downloadUrlXlsx: true
    }
  }
}
