import { pageVideoInstructionSchema, videoInstructionsSchema } from '@/schema/videoInstructions'

const videoInstructionsQuery = (page, tagId, videoTitlePart, fields) => {
  return {
    query: {
      videoInstructions: {
        __args: {
          page,
          tagId,
          videoTitlePart
        },
        ...(Object.keys(fields).length ? fields : videoInstructionsSchema())
      }
    }
  }
}

const pageVideoInstructionQuery = url => {
  return {
    query: {
      pageVideoInstruction: {
        __args: {
          frontUrl: url
        },
        ...pageVideoInstructionSchema()
      }
    }
  }
}

const markVideoInstructionAsViewedMutation = videoInstructionId => {
  return {
    mutation: {
      markVideoInstructionAsViewed: {
        __args: {
          videoInstructionId
        }
      }
    }
  }
}

const setVideoInstructionFeedbackMutation = (videoInstructionId, liked, feedback) => {
  return {
    mutation: {
      setVideoInstructionFeedback: {
        __args: {
          videoInstructionId,
          liked,
          feedback
        }
      }
    }
  }
}

const hideVideoInstructionHintMutation = () => {
  return {
    mutation: {
      hideVideoInstructionHint
    }
  }
}

export const getVideoInstructions =
  ({ $axios }) =>
  (page = 1, tagId = null, videoTitlePart = '', fields = {}) => {
    const query = videoInstructionsQuery(page, tagId, videoTitlePart, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getPageVideoInstruction =
  ({ $axios }) =>
  (url = '/') => {
    const query = pageVideoInstructionQuery(url)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const markVideoInstructionAsViewed =
  ({ $axios }) =>
  videoInstructionId => {
    const query = markVideoInstructionAsViewedMutation(videoInstructionId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const setVideoInstructionFeedback =
  ({ $axios }) =>
  (videoInstructionId, liked = true, feedback = '') => {
    const query = setVideoInstructionFeedbackMutation(videoInstructionId, liked, feedback)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const hideVideoInstructionHint =
  ({ $axios }) =>
  () => {
    const query = hideVideoInstructionHintMutation()
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
