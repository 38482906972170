<template>
  <modal
    v-if="isOpenedModal"
    width="900px"
    title="Примите пользовательское соглашение"
    :has-close-button="false"
    :disable-close="true"
    @close="emit('update:is-opened-modal', false)"
  >
    <base-scroll max-height="680px" class="mb-2">
      <UserAgreement />
    </base-scroll>
    <div class="d-flex justify-content-end">
      <base-button variant="outlined" color="secondary" class="mr-2" @click="logout"> Отмена</base-button>
      <AppButton
        class="app-primary"
        :loading="isLoadingAcceptUserAgreementButton"
        loading-text="Принять пользовательское соглашение"
        @click="acceptUserAgreement"
      >
        Принять пользовательское соглашение
      </AppButton>
    </div>
  </modal>
</template>

<script>
import { BaseScroll } from '@vi-frontend/elements'
import { useContext, useStore, ref } from '@nuxtjs/composition-api'
import useLogout from '@/composables/useLogout'
import UserAgreement from '@/pages/blocks/UserAgreement/UserAgreement'

export default {
  name: 'UserAgreementModal',
  components: { BaseScroll, UserAgreement },
  props: {
    isOpenedModal: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { $api, $notify } = useContext()
    const store = useStore()
    const { logout } = useLogout()
    const isLoadingAcceptUserAgreementButton = ref(false)

    const acceptUserAgreement = async () => {
      isLoadingAcceptUserAgreementButton.value = true
      try {
        await $api.user.agreeAgreement()
        const response = await $api.user.get()
        store.dispatch('user/setUser', response.data.user)
        emit('update:is-opened-modal', false)
      } catch (err) {
        $notify({
          type: 'error',
          title: 'Не удалось принять пользовательское соглашение'
        })
      } finally {
        isLoadingAcceptUserAgreementButton.value = false
      }
    }

    return {
      isLoadingAcceptUserAgreementButton,
      logout,
      emit,
      acceptUserAgreement
    }
  }
}
</script>
