import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

export default (context, inject) => {
  Sentry.init({
    dsn: window.PAS.ENV.DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  })

  Sentry.configureScope(function (scope) {
    scope.setTag('side', 'frontend')
  })

  inject('sentry', Sentry)
}
