<template>
  <div class="table-wrapper">
    <p v-if="showNoDataLabel" class="no-result-text">
      <slot v-if="isShowNoResult" name="noResult"> Нет данных </slot>
    </p>
    <div v-else :class="computedClasses">
      <!-- TODO: при выпиле синего информера из шапки заменить root-margin на '-110px'  -->
      <Observer
        root-margin="-140px"
        :style="{ width: isHorizontalScrollable ? tableWidth : '100%' }"
        @isIntersecting="setHeadPinState"
      />
      <table
        :style="{ width: isHorizontalScrollable ? tableWidth : '100%' }"
        :class="['app-table', { '-white-header': isWhiteHeader }]"
      >
        <thead
          :style="{
            width: isHorizontalScrollable ? tableWidth : '100%',
            transform: isHeadPinned ? 'translateX(' + scrollPosition + 'px)' : 'none'
          }"
          :class="['app-table-head', { '-pinned': isHeadPinned }, { '-in-left-column': isInLeftColumn }]"
        >
          <tr :style="{ height: headerHeight + 'px' }">
            <template v-if="$slots.header">
              <slot name="header" />
            </template>
            <template v-else>
              <th
                v-for="(item, index) in innerHeaders"
                :key="index"
                :style="{ width: item.width ? item.width : '200px' }"
              >
                <div :class="['th-wrap', { 'justify-content-end': item.align === 'right' }]">
                  <div class="table-header-block">
                    <span :class="['th-name th-name-small', isMarginLeft(item)]">
                      {{ item.name }}
                    </span>
                  </div>
                  <div class="icons-container" @click="sendGoalYandexMetrika('nomenclatures_TableFilter_using')">
                    <span class="order-by-icon-wrap" @click="orderBy(item.orderByField || item.field, item.orderBy)">
                      <base-icon
                        v-if="'orderBy' in item"
                        icon="stick-arrow-down"
                        width="16px"
                        height="16px"
                        :class="[
                          'order-by-icon',
                          getOrderByDirectionIconClass(item.orderBy),
                          { '-active': isActiveOrderByIcon(item.orderBy) }
                        ]"
                      />
                    </span>
                    <div v-if="item.filter" :class="['filter-icon', { '-active': isActiveFilter(item.filter.value) }]">
                      <component
                        :is="item.filter.name"
                        v-if="item.filter.name"
                        :value="item.filter.value"
                        :label="item.filter.label"
                        :items="item.filter.items"
                        :is-active="isActiveFilter(item.filter.value)"
                        :data-provider="item.filter.dataProvider"
                        @apply-filter="filter($event, item.filter.field ? item.filter.field : item.field)"
                      />
                    </div>
                  </div>
                </div>
              </th>
            </template>
          </tr>
        </thead>
      </table>
      <table
        :style="{ width: isHorizontalScrollable ? tableWidth : '100%' }"
        :class="['app-table', { 'app-table-margin-right': isVerticalScroll }]"
      >
        <tbody>
          <tr :style="{ height: headerHeight + 'px' }">
            <td v-for="(item, index) in innerHeaders" :key="index" :style="{ width: item.width ? item.width : 'auto' }">
              <div :style="{ width: item.width ? item.width : 'auto' }"></div>
            </td>
          </tr>
          <template v-if="innerItems.length">
            <template v-if="$scopedSlots.row">
              <template v-if="loading">
                <tr v-for="i in innerItems.length" :key="i">
                  <td v-for="k in innerHeaders.length" :key="k">
                    <AppTableLoader />
                  </td>
                </tr>
              </template>
              <template v-else>
                <slot
                  v-for="(item, index) in innerItems"
                  name="row"
                  :item="item"
                  :index="index"
                  :class-name="isClickableRow ? '-clickable' : ''"
                />
              </template>
            </template>
            <template v-else>
              <tr v-for="(item, index) in innerItems" :key="index" :class="{ '-clickable': isClickableRow }">
                <td v-for="(value, key, i) in item" :key="i">
                  <template v-if="headers[i]">
                    <template v-if="loading">
                      <AppTableLoader />
                    </template>
                    <template v-else> {{ item[headers[i].field] }} <br /> </template>
                  </template>
                </td>
              </tr>
            </template>
          </template>
          <template v-else-if="!loading && !innerItems.length">
            <tr class="no-result-row">
              <td :colspan="innerHeaders.length">
                <slot name="noResultWithFilters">
                  <div class="no-result-text">По выставленным фильтрам строк не найдено</div>
                  <BaseButton
                    tag-name="nuxt-link"
                    color="secondary"
                    variant="outlined"
                    size="x-small"
                    class="button-clear-filter"
                    :to="$route.path"
                  >
                    Сбросить фильтры
                  </BaseButton>
                </slot>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <PasPagination
      v-if="hasPagination && items.length"
      :current="currentPage"
      :total="totalPages"
      class="pagination"
      :is-url-pagination="isUrlPagination"
      @update:page-number="updateMarketingPage"
    />
  </div>
</template>

<script>
import PasPagination from '@/components/ui/PasPagination'
import DateRange from '@/components/filters/DateRange'
import SearchFilter from '@/components/filters/SearchFilter'
import SelectFilter from '@/components/filters/SelectFilter'
import AutocompleteFilter from '@/components/filters/AutocompleteFilter'
import CategoryFilter from '@/components/filters/CategoryFilter'
import AppTableLoader from '@/components/ui/AppTableLoader'
import Observer from '@/components/others/Observer'
import hasFilters from '@/mixins/hasFilters'
import sendGoalYandexMetrika from '@/mixins/sendGoalYandexMetrika'

const DEFAULT_COLUMN_WIDTH = 200
const DEFAULT_CELL_HEIGHT = 50

export default {
  name: 'AppTable',
  components: {
    PasPagination,
    DateRange,
    SearchFilter,
    SelectFilter,
    AutocompleteFilter,
    CategoryFilter,
    AppTableLoader,
    Observer
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    pageNumber: {
      type: Number,
      default: 1
    },
    isUrlPagination: {
      type: Boolean,
      default: true
    },
    totalPages: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    isClickableRow: {
      type: Boolean,
      default: false
    },
    isWatchItemsDeep: {
      type: Boolean,
      default: false
    },
    isShowNoResult: {
      type: Boolean,
      default: true
    },
    isHorizontalScrollable: {
      type: Boolean,
      default: false
    },
    isInLeftColumn: {
      type: Boolean,
      default: false
    },
    isWhiteHeader: {
      type: Boolean,
      default: false
    },
    isVerticalScroll: {
      type: Boolean,
      default: false
    },
    isPinned: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerHeaders: JSON.parse(JSON.stringify(this.headers)),
      innerItems: JSON.parse(JSON.stringify(this.items)),
      currentPage: 1,
      headerHeight: 0,
      scrollPosition: 0,
      isHeadPinned: false,
      innerOrderBy: null
    }
  },
  ASC: 'ASC',
  DESC: 'DESC',
  computed: {
    hasFilters,
    sortableFields() {
      return this.innerHeaders.filter(item => {
        return 'orderBy' in item
      })
    },
    computedClasses() {
      return {
        'scrollable-block': true,
        '-margin-bottom': this.hasPagination,
        'scrollable-block-show': this.isVerticalScroll
      }
    },
    hasPagination() {
      return this.totalPages > 1
    },
    tableWidth() {
      return (
        this.innerHeaders.reduce((akk, item) => {
          return akk + (item.width ? parseInt(item.width) : DEFAULT_COLUMN_WIDTH)
        }, 0) + 'px'
      )
    },
    showNoDataLabel() {
      return !this.loading && !this.innerItems.length && !hasFilters()
    },
    startSetHeaderHorizontalScroll() {
      return !this.showNoDataLabel && !!this.$el
    }
  },
  watch: {
    headers(value) {
      this.innerHeaders = JSON.parse(JSON.stringify(value))
      this.fillOrderBy()
      this.fillFilters()
    },
    '$route.query'() {
      this.setDefaultState()
    },
    startSetHeaderHorizontalScroll: {
      handler(start) {
        if (start && !!this.$el) {
          this.setHeaderHorizontalScroll()
        }
      },
      immediate: true
    },
    updateMarketingPage: {
      handler(value) {
        this.updateMarketingPage(value)
      }
    }
  },
  created() {
    this.$watch(
      'items',
      () => {
        this.innerItems = JSON.parse(JSON.stringify(this.items))
      },
      {
        deep: this.isWatchItemsDeep
      }
    )
  },
  mounted() {
    this.setDefaultState()
    setTimeout(() => {
      this.setHeaderHeight()
      this.setHeaderHorizontalScroll()
    }, 500)
  },
  methods: {
    sendGoalYandexMetrika,
    updateMarketingPage(value) {
      this.$emit('update:page-number', value)
      this.fillPagination(value)
    },
    setHeaderHeight() {
      const headerOffsetHeight = this.$el.querySelector('thead')?.offsetHeight
      this.headerHeight = headerOffsetHeight > DEFAULT_CELL_HEIGHT ? headerOffsetHeight : DEFAULT_CELL_HEIGHT
    },
    isMarginLeft(item) {
      return item.checkbox ? 'th-name-margin-left' : ''
    },
    setHeadPinState(isIntersecting) {
      if (this.isPinned) {
        this.isHeadPinned = !isIntersecting
      }
    },
    setHeaderHorizontalScroll() {
      const scrollableBlock = this.$el.querySelector('.scrollable-block ')
      if (!scrollableBlock) return false
      scrollableBlock.addEventListener(
        'scroll',
        () => {
          this.scrollPosition = '-' + scrollableBlock.scrollLeft
        },
        false
      )
    },
    filter(value, field) {
      let query

      const isEmptyArray = Array.isArray(value) && value.length === 0

      if (!value || isEmptyArray) {
        query = { ...this.$route.query }
        if (field in query) {
          delete query[field]
        }
      } else {
        query = { ...this.$route.query, [field]: value }
      }

      query.pageNumber = 1

      this.$emit('apply-filter')
      this.$router.push({ query })
    },
    orderBy(fieldName, oldOrderByDirection) {
      let orderBy
      if (oldOrderByDirection === 'ASC') {
        orderBy = ''
      } else if (oldOrderByDirection === 'DESC') {
        orderBy = fieldName
      } else {
        orderBy = '-' + fieldName
      }

      if (!this.isUrlPagination) {
        this.innerOrderBy = orderBy
        this.setDefaultState()

        this.$emit('set-order-by', this.innerOrderBy)
      } else {
        const query = { ...this.$route.query, orderBy }
        this.$router.push({ query })
      }
    },
    isActiveFilter(value) {
      if (Array.isArray(value)) {
        return value.length > 0
      } else {
        return !!value
      }
    },
    fillOrderBy() {
      let direction, fieldName
      const currentOrderBy = this.innerOrderBy || this.$route.query.orderBy

      if (currentOrderBy === undefined || currentOrderBy === null) return

      if (currentOrderBy.includes('-')) {
        direction = 'DESC'
        fieldName = currentOrderBy.substring(1)
      } else if (currentOrderBy.length) {
        direction = 'ASC'
        fieldName = currentOrderBy
      } else {
        direction = ''
        fieldName = ''
      }

      this.sortableFields.forEach(item => {
        item.orderBy = ''
      })

      const index = this.innerHeaders.findIndex(item => {
        const field = item.orderByField ? item.orderByField : item.field
        return field === fieldName
      })

      if (index !== -1) {
        this.$set(this.innerHeaders[index], 'orderBy', direction)
      }
    },
    fillFilters() {
      const keys = Object.keys(this.$route.query)
      const filteredKeys = keys.filter(key => {
        return key !== 'orderBy' && key !== 'pageSize' && key !== 'pageNumber'
      })

      filteredKeys.forEach(key => {
        const index = this.innerHeaders.findIndex(item => {
          return (item.filter?.field || item.field) === key
        })

        if (index !== -1) {
          if (this.innerHeaders[index]?.filter?.value) {
            this.innerHeaders[index].filter.value = this.$route.query[key]
          }
        }
      })
    },
    fillPagination(value) {
      this.currentPage = value || parseInt(this.$route.query.pageNumber, 10) || 1
    },
    clearFilters() {
      this.innerHeaders.forEach(item => {
        if (item.filter) {
          item.filter.value = ''
        }
      })
    },
    clearOrderBy() {
      this.innerHeaders.forEach(item => {
        if (item.orderBy) {
          item.orderBy = ''
        }
      })
    },
    getOrderByDirectionIconClass(orderBy) {
      let className

      if (orderBy === this.$options.ASC) {
        className = '-up'
      }

      return className
    },
    isActiveOrderByIcon(orderBy) {
      return orderBy === this.$options.ASC || orderBy === this.$options.DESC
    },
    setDefaultState() {
      this.clearFilters()
      this.clearOrderBy()
      this.fillPagination()
      this.fillOrderBy()
      this.fillFilters()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-table {
  position: relative;
  border-collapse: collapse;
  table-layout: fixed;

  &-margin-right {
    margin-right: 14px;
  }

  .table-checkbox-block {
    display: flex;
    align-items: center;
  }

  .th-name-small {
    font-size: 12px;
  }

  .table-checkbox {
    margin-right: $vr-blue;
  }

  .app-table-head {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    &.-pinned {
      position: fixed;
      padding-top: 28px;
      top: $headerHeight;
      left: 32px;
      padding-left: 10px;
      background-color: $white;
      &.-in-left-column {
        left: 334px;
      }
    }
  }

  .table-header-block {
    display: flex;
    align-items: center;
  }

  .th-name-margin-left {
    margin-left: 60px;
  }

  .th-name-small {
    font-size: 12px;
  }

  tr {
    transition: all 0.2s ease-in-out;
    &:not(.no-result-row):hover {
      background-color: $gray-10 !important;
    }
  }

  tr.bg-info {
    &:hover {
      background-color: #b0d7fb !important;
    }
  }

  tr.-clickable {
    &:hover {
      cursor: pointer;
    }
  }

  th,
  td {
    padding: 14px 16px;
    text-align: left;
  }

  td {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    word-wrap: break-word;
    border-bottom: 1px solid $gray-50;
    height: 50px;
  }

  th {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;
    color: $gray-80;
    background-color: $gray-30;
    user-select: none;

    .order-by-icon,
    .filter-icon {
      visibility: visible;
    }
  }

  &.-white-header {
    th {
      background-color: $white;
      border-bottom: 1px solid $gray-50;
    }
  }

  .th-wrap {
    display: flex;
    align-items: center;
  }

  .icons-container {
    display: flex;
  }

  .order-by-icon {
    visibility: hidden;
    margin-left: 4px;
    color: $gray-60;
    cursor: pointer;

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.-up {
      transform: rotate(180deg);
    }

    &.-active {
      color: $gray-90;
      visibility: visible;
    }
  }

  .filter-icon {
    margin-left: 8px;
    visibility: hidden;
    cursor: pointer;

    &.-active {
      color: $gray-90;
      visibility: visible;
    }
  }

  .button-clear-filter {
    margin-top: $vr-orange;
  }

  .no-result-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
  }
}

.no-result-text {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  color: $gray-90;
}

.-margin-bottom {
  margin-bottom: $vr-sapphire;
}

.scrollable-block {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  clip-path: inset(0 0 0 0);
  @include custom-horizontal-scroll;
}

.scrollable-block-show {
  max-height: 584px;
  overflow: auto;
}
</style>
