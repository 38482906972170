export default function (callbackName) {
  return {
    bind(el, binding, vnode) {
      document.querySelector('body').addEventListener('click', vnode.context[callbackName])
    },

    unbind(el, binding, vnode) {
      document.querySelector('body').removeEventListener('click', vnode.context[callbackName])
    }
  }
}
