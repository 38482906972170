<template>
  <modal v-if="isOpenedModal" title="Обратная связь" width="580px" @close="isOpenedModal = false">
    <form @submit.prevent="sendFeedback">
      <div class="action">Оцените работу нашего сервиса</div>
      <div class="star-rating-list">
        <AppStarRating v-model="ratingValue" color="#DCDCDC" active-color="#FFCC00" :offset="10" :size="44" />
      </div>
      <base-input v-model="userComment" tag-name="textarea" :rows="5" placeholder="Ваш комментарий" class="mb-3" />
      <div class="text-right">
        <AppButton
          variant="filled"
          loading-text="Отправка..."
          size="small"
          class="app-success"
          :loading="isLoadingSendFeedBack"
        >
          Отправить
        </AppButton>
      </div>
    </form>
  </modal>
</template>

<script>
import AppStarRating from '@/components/ui/AppStarRating'

export default {
  name: 'AppFeedBack',
  components: {
    AppStarRating
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoadingSendFeedBack: false,
      ratingValue: 5,
      userComment: ''
    }
  },
  computed: {
    isOpenedModal: {
      get() {
        return this.isOpened
      },

      set(value) {
        this.$emit('update:isOpened', value)
      }
    }
  },
  methods: {
    async sendFeedback() {
      if (this.isLoadingSendFeedBack) return

      this.isLoadingSendFeedBack = true

      const ratingValue = this.ratingValue
      const userComment = this.userComment

      const mutation = {
        mutation: {
          createFeedback: {
            __args: {
              mark: ratingValue,
              text: userComment
            },
            mark: true,
            text: true
          }
        }
      }

      try {
        await this.$axios.$post('/graphql', { query: mutation })
        this.isOpenedModal = false
        this.ratingValue = 1
        this.userComment = ''
        this.$notify({
          type: 'success',
          title: 'Ваш отзыв был отправлен'
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err[0]?.extensions.category === 'user' ? err[0].message : 'Ваш отзыв не удалось отправить'
        })
      } finally {
        this.isLoadingSendFeedBack = false
      }
    }
  }
}
</script>

<style lang="scss">
.star-rating-list {
  display: flex;
  margin-bottom: $vr-red;
}

.action {
  margin-top: $vr-pink;
  margin-bottom: $vr-pink;
  font-size: 14px;
  line-height: 20px;
  color: $gray-90;
}
</style>
