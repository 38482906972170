<template>
  <MainApp>
    <AppHeader />
    <AppFullWidthContent />
    <AppFooter />
    <AppNotification />
    <AppSpritesPlace />
    <UserAgreementModal :is-opened-modal.sync="isOpenedUserAgreementModal" />
    <ChatBot />
  </MainApp>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader'
import AppFullWidthContent from '@/components/layout/AppFullWidthContent'
import AppFooter from '@/components/layout/AppFooter'
import AppNotification from '@/components/layout/AppNotification'
import AppSpritesPlace from '@/components/layout/AppSpritesPlace'
import MainApp from '@/components/layout/MainApp'
import UserAgreementModal from '@/pages/blocks/UserAgreement/UserAgreementModal'
import useUserAgreement from '@/composables/useUserAgreement'
import ChatBot from '@/components/layout/ChatBot'

export default {
  components: {
    MainApp,
    AppHeader,
    AppFooter,
    AppFullWidthContent,
    AppNotification,
    AppSpritesPlace,
    UserAgreementModal,
    ChatBot
  },
  setup() {
    const { isOpenedUserAgreementModal } = useUserAgreement()

    return {
      isOpenedUserAgreementModal
    }
  }
}
</script>
