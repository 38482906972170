import * as nomenclatureImages from '@/api/nomenclatureImages'
import * as nomenclatureFiles from '@/api/nomeclatureFiles'
import * as nomenclatureTechParams from '@/api/nomenclatureTechParams'
import * as nomenclature from '@/api/nomenclature'
import * as hints from '@/api/hints'
import * as tnved from '@/api/tnved'
import * as manufacturers from '@/api/manufacturers'
import * as countries from '@/api/countries'
import * as categories from '@/api/categories'
import * as reports from '@/api/reports'
import * as jobs from '@/api/jobs'
import * as upload from '@/api/upload'
import * as massUploading from '@/api/massUploading'
import * as arrangement from '@/api/arrangement'
import * as massUploadPhotos from '@/api/massUploadingPhotos'
import * as videoInstructions from '@/api/videoInstructions'
import * as marketing from '@/api/marketing'
import * as matrixCoordinations from '@/api/matrix_coordinations'
import * as chat from '@/api/chat'
import * as edo from '@/api/edo'
import * as plannedReserves from '@/api/planned_reserves'

import * as user from '@/api/user'
import builder from '@/api/builder'

export default (context, inject) => {
  inject('api', {
    nomenclatureImages: {
      list: nomenclatureImages.list(context),
      sortImages: nomenclatureImages.sortImages(context),
      cancelRemoveImage: nomenclatureImages.cancelRemoveImage(context),
      removeImage: nomenclatureImages.removeImage(context),
      addImages: nomenclatureImages.addImages(context)
    },
    nomenclatureFiles: {
      list: nomenclatureFiles.list(context),
      addFile: nomenclatureFiles.addFile(context),
      cancelRemoveFile: nomenclatureFiles.cancelRemoveFile(context),
      removeFile: nomenclatureFiles.removeFile(context)
    },
    nomenclatureTechParams: {
      list: nomenclatureTechParams.list(context),
      save: nomenclatureTechParams.save(context),
      saveAll: nomenclatureTechParams.saveAll(context),
      createNewValue: nomenclatureTechParams.createNewValue(context),
      removeValue: nomenclatureTechParams.removeValue(context),
      diffTechParams: nomenclatureTechParams.diffTechParams(context)
    },
    nomenclature: {
      get: nomenclature.get(context),
      save: nomenclature.save(context),
      saveContent: nomenclature.saveContent(context),
      content: nomenclature.content(context),
      replacementNomenclatures: nomenclature.replacementNomenclatures(context),
      pageParams: nomenclature.pageParams(context),
      saveCategory: nomenclature.saveCategory(context),
      sendToCoordination: nomenclature.sendToCoordination(context),
      list: nomenclature.list(context),
      saveNomenclatureListColumnsSettings: nomenclature.saveNomenclatureListColumnsSettings(context),
      getExportNomenclatureListJob: nomenclature.getExportNomenclatureListJob(context),
      sendNomenclatureListToVerification: nomenclature.sendNomenclatureListToVerification(context),
      sendNomenclatureListToCoordination: nomenclature.sendNomenclatureListToCoordination(context),
      uploadNewNomenclatureFile: nomenclature.uploadNewNomenclatureFile(context),
      contentData: nomenclature.contentData(context),
      getExportTemplateTask: nomenclature.getExportTemplateTask(context),
      getPdmTaskProgressPercent: nomenclature.getPdmTaskProgressPercent(context),
      getNomenclatureTechParamsExportTemplateFile: nomenclature.getNomenclatureTechParamsExportTemplateFile(context),
      saveNomenclatureStatus: nomenclature.saveNomenclatureStatus(context),
      nomenclatureListCounters: nomenclature.nomenclatureListCounters(context),
      supplierClassifierUnits: nomenclature.supplierClassifierUnits(context),
      savePermitDocs: nomenclature.savePermitDocs(context)
    },
    tnved: { list: tnved.list(context) },
    hints: { list: hints.list(context) },
    manufacturers: {
      get: manufacturers.get(context),
      list: manufacturers.list(context),
      current: manufacturers.current(context)
    },
    countries: { list: countries.list(context) },
    categories: {
      list: categories.list(context),
      get: categories.get(context),
      currentSupplierCategories: categories.currentSupplierCategories(context),
      supplierList: categories.supplierList(context)
    },
    reports: {
      spending: reports.spending(context),
      remains: reports.remains(context),
      realizations: reports.realizations(context),
      mvpReports: reports.mvpReports(context),
      reportAnalytics: reports.reportAnalytics(context),
      nextReportsDate: reports.nextReportsDate(context)
    },
    user: {
      get: user.get(context),
      logout: user.logout(context),
      agreeAgreement: user.agreeAgreement(context)
    },
    jobs: {
      get: jobs.get(context),
      getJobByUploadId: jobs.getJobByUploadId(context)
    },
    upload: {
      getNomenclatureListByUploadId: upload.getNomenclatureListByUploadId(context),
      get: upload.get(context),
      list: upload.list(context),
      sendToCoordination: upload.sendToCoordination(context)
    },
    massUploading: {
      generateTemplateFile: massUploading.generateTemplateFile(context),
      uploadFile: massUploading.uploadFile(context),
      getNomenclatureListByUploadId: massUploading.getNomenclatureListByUploadId(context),
      addChanges: massUploading.addChanges(context)
    },
    massUploadPhotos: {
      uploadPhoto: massUploadPhotos.uploadPhoto(context),
      deletePhoto: massUploadPhotos.deletePhoto(context),
      createUpload: massUploadPhotos.createUpload(context),
      saveUploadType: massUploadPhotos.saveUploadType(context),
      saveUpload: massUploadPhotos.saveUpload(context),
      getUploadedFiles: massUploadPhotos.getUploadedFiles(context),
      photoMassUploadCheckPhotos: massUploadPhotos.photoMassUploadCheckPhotos(context)
    },
    arrangement: {
      get: arrangement.get(context),
      addDocumentFiles: arrangement.addDocumentFiles(context),
      arrangementGetViClarification: arrangement.arrangementGetViClarification(context),
      arrangementAddSupplierClarificationComment: arrangement.arrangementAddSupplierClarificationComment(context),
      saveArrangementLegalEntity: arrangement.saveArrangementLegalEntity(context),
      saveArrangementInformation: arrangement.saveArrangementInformation(context),
      removeDocumentFile: arrangement.removeDocumentFile(context),
      generateAgreement: arrangement.generateAgreement(context),
      getDocumentExamples: arrangement.getDocumentExamples(context),
      register: arrangement.register(context),
      searchBik: arrangement.searchBik(context),
      findOrganizations: arrangement.findOrganizations(context)
    },
    videoInstructions: {
      getVideoInstructions: videoInstructions.getVideoInstructions(context),
      getPageVideoInstruction: videoInstructions.getPageVideoInstruction(context),
      markVideoInstructionAsViewed: videoInstructions.markVideoInstructionAsViewed(context),
      setVideoInstructionFeedback: videoInstructions.setVideoInstructionFeedback(context),
      hideVideoInstructionHint: videoInstructions.hideVideoInstructionHint(context)
    },
    marketing: {
      marketingList: marketing.marketingList(context),
      marketingPromotionCreate: marketing.marketingPromotionCreate(context),
      marketingPromotionCopy: marketing.marketingPromotionCopy(context),
      marketingPromotionCancel: marketing.marketingPromotionCancel(context),
      marketingNomenclature: marketing.marketingNomenclature(context),
      marketingPromotionInformer: marketing.marketingPromotionInformer(context),
      marketingNomenclatureCategoryConfirm: marketing.marketingNomenclatureCategoryConfirm(context),
      marketingPromotionChangeNameDate: marketing.marketingPromotionChangeNameDate(context),
      addMarketingNomenclatureList: marketing.addMarketingNomenclatureList(context),
      marketingNomenclatureDelete: marketing.marketingNomenclatureDelete(context),
      plannedPromotion: marketing.plannedPromotion(context),
      marketingPromotionClearSelection: marketing.marketingPromotionClearSelection(context)
    },
    matrixCoordinations: {
      matrixCoordinations: matrixCoordinations.matrixCoordinations(context),
      matrixCoordinationsCurrentEndDate: matrixCoordinations.matrixCoordinationsCurrentEndDate(context),
      regions: matrixCoordinations.regions(context),
      matrixCoordinationStatuses: matrixCoordinations.matrixCoordinationStatuses(context)
    },
    chat: {
      chatBot: chat.chatBot(context)
    },
    edo: {
      sendEdoId: edo.sendEdoId(context)
    },
    plannedReserves: {
      getPlanReserveManagerList: plannedReserves.getPlanReserveManagerList(context),
      getPlanReserveSupplierList: plannedReserves.getPlanReserveSupplierList(context),
      getPlanReserveRequestList: plannedReserves.getPlanReserveRequestList(context),
      getPlanReserveWarehouses: plannedReserves.getPlanReserveWarehouses(context),
      getPlanReserveSupplierManufacturers: plannedReserves.getPlanReserveSupplierManufacturers(context),
      getPlanReserveTypes: plannedReserves.getPlanReserveTypes(context),
      getWarehouses: plannedReserves.getWarehouses(context),
      getPlanReserveSuppliers: plannedReserves.getPlanReserveSuppliers(context),
      getPlanReserveFileInfo: plannedReserves.getPlanReserveFileInfo(context),
      getPlanReserveRequestAnswer: plannedReserves.getPlanReserveRequestAnswer(context),
      getPlanReserveLastActiveRequestAnswer: plannedReserves.getPlanReserveLastActiveRequestAnswer(context),
      getPlanReserveLastActiveFileInfo: plannedReserves.getPlanReserveLastActiveFileInfo(context),
      getPlanReserveNomenclatureChanges: plannedReserves.getPlanReserveNomenclatureChanges(context),
      getPlanReserveChangesAuthors: plannedReserves.getPlanReserveChangesAuthors(context),
      setPlanReserveSupplierManager: plannedReserves.setPlanReserveSupplierManager(context),
      setReserveRequestValues: plannedReserves.setReserveRequestValues(context),
      uploadPlanReserveFile: plannedReserves.uploadPlanReserveFile(context),
      uploadPlanReserveRequestAnswer: plannedReserves.uploadPlanReserveRequestAnswer(context),
      downloadPlanReserveRequests: plannedReserves.downloadPlanReserveRequests(context),
      sendNotificationOnDate: plannedReserves.sendNotificationOnDate(context)
    },
    builder: builder(context)
  })
}
