export default function () {
  return {
    country: {
      id: true,
      name: true
    },
    equipment: true,
    advantages: true,
    shortDescription: true,
    siteName: true,
    category: {
      id: true,
      name: true
    }
  }
}
