<template>
  <ul v-click-outside class="app-toolbar">
    <li :class="['app-toolbar-item user-menu-item', { '-active': isOpenedUserMenu }]" @click="toggleUserMenu">
      <base-icon icon="person" width="14px" height="14px" class="user-icon" />
      <ul v-if="user" :class="['app-toolbar-submenu', { '-opened': isOpenedUserMenu }]">
        <li v-if="supplierName" class="submenu-item -first-child">
          {{ supplierName }}
        </li>
        <li v-if="supplierName" class="submenu-separator -top"></li>
        <li v-for="(subitem, index) in user.userMenu" :key="index" class="submenu-item">
          <a :href="subitem.url" class="submenu-item-link">
            {{ subitem.name }}
          </a>
        </li>
        <li v-if="supplierName" class="submenu-separator"></li>
        <li class="submenu-item -last-child">
          <a href="#" class="submenu-item-link" @click.prevent="logout"> Выход </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { defineComponent, computed, ref, useStore } from '@nuxtjs/composition-api'
import useLogout from '@/composables/useLogout'

export default defineComponent({
  name: 'AppToolbar',

  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        document.querySelector('body').addEventListener('click', vnode.context.closeUserMenu)
      },
      unbind: function (el, binding, vnode) {
        document.querySelector('body').removeEventListener('click', vnode.context.closeSubmenu)
      }
    }
  },

  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { logout } = useLogout()
    const store = useStore()
    const isOpenedUserMenu = ref(false)

    const isFromAdminPanel = computed(() => store.getters['user/isFromAdminPanel'])
    const adminPanelUrl = computed(() => store.getters['user/adminPanelUrl'])
    const supplierName = computed(() => props.user.supplier?.name)

    const toggleUserMenu = event => {
      if (event.target.closest('.app-toolbar-submenu')) {
        return
      }

      isOpenedUserMenu.value = !isOpenedUserMenu.value
    }
    const closeUserMenu = event => {
      if (!event.target.closest('.user-menu-item')) {
        isOpenedUserMenu.value = false
      }
      return false
    }

    return {
      logout,
      toggleUserMenu,
      closeUserMenu,
      isFromAdminPanel,
      adminPanelUrl,
      supplierName,
      isOpenedUserMenu
    }
  }
})
</script>

<style lang="scss" scoped>
.app-toolbar {
  &-item {
    position: relative;
    padding: 10px 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &.-active {
      background-color: $gray-10;
    }
  }
}

.app-toolbar-submenu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 208px;
  background: $white;
  padding: $vr-brown 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: default;

  &.-opened {
    display: block;
  }
}

.submenu-item {
  font-size: 1.4rem;
  user-select: none;
  transition: 0.1s background-color;

  &-link {
    display: block;
    padding: $vr-brown 8px;
    white-space: nowrap;
    color: $gray-90;
    text-decoration: none;
    border: none;

    &:hover {
      background-color: $gray-20;
    }
  }

  &.-first-child {
    color: $gray-90;
    font-weight: 500;
    padding: $vr-brown 8px;
    cursor: default;
  }

  &.-last-child {
    padding-bottom: 0;
  }
}

.submenu-separator {
  height: 1px;
  background-color: $gray-50;
  margin-top: $vr-brown;
  margin-bottom: $vr-brown;
}

.user-menu-item {
  color: $gray-70;
}

.user-icon {
  font-size: 24px;
}
</style>
