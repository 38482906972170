<template>
  <validationProvider v-slot="{ errors, reset }" :rules="rules" :mode="mode" :vid="name" tag="div">
    <FileUploader
      ref="fileUploader"
      v-bind="$attrs"
      :max-file-size="maxFileSize"
      v-on="$listeners"
      @remove-file="removeFile(reset)"
      @set-front-file-size-error="emitFrontFileSizeError"
    />
    <AppFieldErrorDetail v-if="errors[0]" :text="errors[0]" />
    <AppFieldNoteDetail v-if="!errors[0] && note" :text="note" />
  </validationProvider>
</template>

<script>
import FileUploader from '@/components/buttons/FileUploader'

export default {
  name: 'AppFileUploaderValidation',
  components: {
    FileUploader
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    name: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    maxFileSize: {
      type: Number,
      default: 50
    }
  },
  methods: {
    async removeFile(reset) {
      if (reset && typeof reset === 'function') {
        await reset()
      }
    },
    emitFrontFileSizeError(event) {
      this.$emit('set-front-file-size-error', event)
    }
  }
}
</script>
