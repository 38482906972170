<template>
  <FilterBox :is-active="isActive" @applyFilter="applyFilter" @clearFilter="clearFilter">
    <template #title>
      {{ label }}
    </template>
    <template #content>
      <div class="daterange">
        <Calendar v-model="dateStart" min="" required size="small" />
        <span class="daterange-separator"> - </span>
        <Calendar v-model="dateEnd" min="" required size="small" />
      </div>
    </template>
  </FilterBox>
</template>

<script>
import FilterBox from '@/components/filters/FilterBox'

export default {
  name: 'DateRange',
  components: {
    FilterBox
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Укажите диапазон'
    }
  },
  separator: '_',
  data() {
    return {
      dateStart: [],
      dateEnd: []
    }
  },

  watch: {
    value: {
      handler(value) {
        const date = value.split(this.$options.separator)

        if (!date.length) {
          this.dateStart = []
          this.dateEnd = []
        } else if (date[0] && date[1]) {
          this.dateStart = [date[0]]
          this.dateEnd = [date[1]]
        } else if (date[1]) {
          this.dateEnd = [date[1]]
        } else if (date[0]) {
          this.dateStart = [date[0]]
        }
      },
      immediate: true
    }
  },

  methods: {
    applyFilter() {
      let date = ''
      const dateStart = this.dateStart[0]
      const dateEnd = this.dateEnd[0]

      if (dateStart && dateEnd) {
        const formattedDateStart = this.$dayjs(dateStart).format('YYYY-MM-DD')
        const formattedDateEnd = this.$dayjs(dateEnd).format('YYYY-MM-DD')
        date = formattedDateStart + this.$options.separator + formattedDateEnd
      } else if (dateStart) {
        date = this.$dayjs(dateStart).format('YYYY.MM.DD') + this.$options.separator
      } else if (dateEnd) {
        date = this.$options.separator + this.$dayjs(dateEnd).format('YYYY.MM.DD')
      } else {
        date = ''
      }

      this.$emit('apply-filter', date)
    },

    clearFilter() {
      this.dateStart = []
      this.dateEnd = []
      this.applyFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.daterange {
  display: flex;
  align-items: center;

  &-separator {
    margin-right: 8px;
    margin-left: 8px;
  }
}
</style>
