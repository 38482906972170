<template>
  <tooltip ref="tooltip" position="bottom" popup class="filter-box-wrap" :width="width">
    <base-icon icon="funnel" width="13px" height="13px" :class="['filter-icon', { '-active': isActive }]" />
    <template #content>
      <div class="filter-box">
        <div class="title">
          <slot name="title" />
        </div>
        <div class="content">
          <slot name="content" />
        </div>
      </div>
      <div class="text-right">
        <base-button color="secondary" variant="filled" size="x-small" class="btn-apply-filter" @click="applyFilter">
          Применить
        </base-button>
        <base-button color="secondary" variant="outlined" size="x-small" @click="clearFilter"> Сбросить </base-button>
      </div>
    </template>
  </tooltip>
</template>

<script>
export default {
  name: 'AppFilterBox',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'auto'
    }
  },
  methods: {
    applyFilter() {
      this.$refs.tooltip.hideTooltip()
      this.$emit('applyFilter')
    },
    clearFilter() {
      this.$refs.tooltip.hideTooltip()
      this.$emit('clearFilter')
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-box {
  min-width: 282px;

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: $vr-pink;
    color: $gray-90;
  }

  .content {
    margin-bottom: $vr-red;
  }

  &-wrap {
    line-height: 1rem;
  }
}

.filter-icon {
  color: $gray-60;

  &.-active {
    color: $gray-90;
    visibility: visible;
  }
}

.btn-apply-filter {
  margin-right: 12px;
}
</style>
