<template>
  <div>
    <ul v-click-outside class="app-menu">
      <li v-for="(item, index) in menu" :key="index" class="app-menu-item" @click="toggleSubmenu(index)">
        <a v-if="item.id === 'feedback'" class="app-menu-item-link" @click.prevent="isOpenFeedBackModal = true">
          {{ item.name }}
        </a>
        <template v-else-if="item.url">
          <nuxt-link
            v-if="isNuxtLink(item)"
            :to="item.isDisable ? '' : item.url"
            :class="['app-menu-item-link', { '-active': item.isOpenedSubmenu }, { '-disabled': item.isDisable }]"
          >
            {{ item.name }}
          </nuxt-link>
          <a
            v-else
            :href="item.isDisable ? '' : item.url"
            :class="['app-menu-item-link', { '-active': item.isOpenedSubmenu }, { '-disabled': item.isDisable }]"
          >
            {{ item.name }}
          </a>
        </template>
        <span v-else :class="['app-menu-item-link', { '-active': item.isOpenedSubmenu }]">
          {{ item.name }}
        </span>
        <base-icon
          v-if="item.subMenu"
          icon="arrow-up"
          :class="['caret', { '-down': !item.isOpenedSubmenu }, { '-active': item.isOpenedSubmenu }]"
        />
        <ul v-if="item.subMenu" :class="['app-submenu', { '-is-opened': item.isOpenedSubmenu }]">
          <li v-for="(subitem, i) in item.subMenu" :key="i" class="app-submenu-item">
            <nuxt-link
              v-if="isNuxtLink(subitem)"
              :to="subitem.isDisable ? '' : subitem.url"
              :class="['app-submenu-item-link', { '-disabled': subitem.isDisable }]"
            >
              {{ subitem.name }}
            </nuxt-link>
            <a
              v-else
              :href="subitem.isDisable ? '' : subitem.url"
              :class="['app-submenu-item-link', { '-disabled': subitem.isDisable }]"
            >
              {{ subitem.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <AppFeedBack :is-opened.sync="isOpenFeedBackModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppFeedBack from '@/components/forms/AppFeedBack'

export default {
  name: 'AppMenu',
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        document.querySelector('body').addEventListener('click', vnode.context.closeSubmenu)
      },

      unbind: function (el, binding, vnode) {
        document.querySelector('body').removeEventListener('click', vnode.context.closeSubmenu)
      }
    }
  },
  components: {
    AppFeedBack
  },
  data() {
    return {
      menu: [],
      prevSubmenuOpenedIndex: null,
      isOpenFeedBackModal: false
    }
  },

  async fetch() {
    if (!this.isAuthorized) return

    try {
      this.menu = await this.$axios.$get('/site/menu')

      this.menu.forEach(item => {
        if (item.subMenu) {
          this.$set(item, 'isOpenedSubmenu', false)
        }
      })
    } catch (err) {
      this.$notify({
        type: 'error',
        title: err.errorMessage || err.message || 'Не удалось загрузить меню'
      })
    }
  },
  computed: {
    ...mapGetters('user', ['isAuthorized']),
    itemsWithSubmenu() {
      return this.menu.filter(item => {
        return item.subMenu
      })
    }
  },
  methods: {
    toggleSubmenu(index) {
      if (this.menu[index].isOpenedSubmenu) {
        this.menu[index].isOpenedSubmenu = false
        return
      }

      if (this.prevSubmenuOpenedIndex !== null) {
        this.menu[this.prevSubmenuOpenedIndex].isOpenedSubmenu = false
      }

      this.menu[index].isOpenedSubmenu = !this.menu[index].isOpenedSubmenu
      this.prevSubmenuOpenedIndex = index
    },
    closeSubmenu(event) {
      if (!event.target.closest('.app-menu')) {
        this.itemsWithSubmenu.forEach(item => {
          item.isOpenedSubmenu = false
        })
      }
    },
    isNuxtLink(item) {
      if (item.url === '/mass-uploading-photos' || item.url === '/mass-uploading-photos/') {
        return false
      }
      return item.isFrontend
    }
  }
}
</script>

<style lang="scss" scoped>
.app-menu {
  display: flex;
  flex-shrink: 0;
  list-style-type: none;
  margin: 0;

  &-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &-item + &-item {
    margin-left: 24px;
  }

  &-item-link {
    display: block;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: $gray-80;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border: none;
  }

  &-item-link.-active {
    color: $gray-90;
  }

  &-item-link.-disabled {
    cursor: default;
    pointer-events: none;
    color: $gray-50;
  }
}

.app-submenu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 160px;
  padding: 6px;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  list-style-type: none;

  &-item {
    padding-right: 8px;
    padding-left: 8px;
    transition: 0.1s background-color;
    border-radius: 6px;

    &:hover {
      background-color: $gray-30;
    }
  }

  &-item-link {
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 16px;
    padding-top: $vr-pink;
    padding-bottom: $vr-pink;
    color: $gray-90;
    text-decoration: none;
    white-space: nowrap;
    border: none;

    &.-disabled {
      cursor: default;
      pointer-events: none;
      color: $gray-50;
    }
  }

  &.-is-opened {
    display: block;
  }
}

.caret {
  font-size: 12px;
  margin-left: 6px;
  transition: transform 0.1s;
  color: $gray-80;

  &.-down {
    transform: rotate(180deg);
  }

  &.-active {
    color: $gray-90;
  }
}
</style>
