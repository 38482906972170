export const state = () => ({
  selectedGuidList: {},
  selectedCount: [],
  filters: {}
})

export const getters = {
  getSelectedGuidList(state) {
    return state.selectedGuidList
  },
  getLengthSelectedList(state) {
    return state.selectedCount.reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0
  },
  getMarketingModalFilters(state) {
    return state.filters
  }
}

export const mutations = {
  setSelectMarketingList: (state, { list, pageNumber }) => {
    state.selectedGuidList[pageNumber] = list
    mutations.setCountSelectedList(state)
  },
  setCountSelectedList: state => {
    state.selectedCount = Object.values(state.selectedGuidList)
    state.selectedCount = state.selectedCount.map(arr => {
      return arr.length
    })
  },
  clearSelectedGuidList: state => {
    state.selectedGuidList = {}
    mutations.setCountSelectedList(state)
  },
  setMarketingModalFilters: (state, filters = {}) => {
    state.filters = filters
  }
}

export const actions = {
  setSelectMarketingList({ commit }, { list, pageNumber }) {
    commit('setSelectMarketingList', { list, pageNumber })
  },
  clearSelectedList({ commit }) {
    commit('clearSelectedGuidList')
  },
  setMarketingModalFilters({ commit }, filters) {
    commit('setMarketingModalFilters', filters)
  }
}
