export const state = () => ({
  photoCounters: {
    all: null,
    errors: null,
    blockedErrors: null
  }
})

export const getters = {
  getPhotoCounters: state => {
    return state.photoCounters
  }
}

export const mutations = {
  setPhotoCounter: (state, [type, counter]) => {
    state.photoCounters[type] = counter
  }
}

// export const actions = {
//   setPhotoCounter({commit}, [type, counter]) {
//     commit('SET_PHOTO_COUNTER', [type, counter])
//   }
// };
