<template>
  <div
    class="app-spinner"
    :style="{
      width: size + 'px',
      height: size + 'px',
      borderWidth: borderWidth + 'px',
      borderColor: color,
      borderBottomColor: bgColor,
      animationDuration: duration + 's'
    }"
  ></div>
</template>

<script>
const hexValidator = value => {
  return /#[0-9a-fA-F]{6}$/.test(value) || 'transparent'
}

export default {
  name: 'AppSpinner',
  props: {
    size: {
      type: Number,
      default: 20
    },
    borderWidth: {
      type: Number,
      default: 5
    },
    color: {
      type: String,
      default: 'red',
      validator: hexValidator
    },
    bgColor: {
      type: String,
      default: 'blue',
      validator: hexValidator
    },
    duration: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.app-spinner {
  border-style: solid;
  border-radius: 50%;
  transform: rotate(0deg);
  animation: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
