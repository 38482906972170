const nomenclatureListFilterTypes = {
  isOnSiteFilter: {
    yes: 'Boolean',
    no: 'Boolean'
  },
  isActiveFilter: {
    yes: 'Boolean',
    no: 'Boolean'
  },
  statusFilter: {
    noChanges: 'Boolean',
    isOnCoordination: 'Boolean',
    needCoordination: 'Boolean',
    blocked: 'Boolean'
  },
  name: 'String,',
  manufacturerIds: '[Int]',
  vendorCode: 'String',
  categoryIds: '[Int]',
  guid: 'String',
  wtisId: 'String',
  multiSearchPattern: 'String',
  massUploadingId: 'String',
  tnved: 'String'
}

export default {
  matrixCoordinationsFilter: {
    id: 'Int',
    statusId: 'Int',
    month: 'Int',
    regionId: 'Int',
    createdAt: 'String',
    matrix: 'String'
  },
  matrixCoordinationRowsFilter: {
    id: 'Int',
    statusId: 'Int',
    nomenclatureId: 'Int',
    nomenclatureGuid: 'String',
    nomenclatureName: 'String',
    manufacturerName: 'String',
    vendorCode: 'String',
    matrix: 'String',
    quantity: 'Int',
    supplierQuantity: 'Int',
    managerQuantity: 'Int',
    supplierComment: 'String'
  },
  nomenclatureList: {
    ...nomenclatureListFilterTypes
  },
  exportNomenclatureListJob: {
    ...nomenclatureListFilterTypes
  },
  nomenclatureListContentData: {
    ...nomenclatureListFilterTypes
  },
  createExportTemplateTask: {
    ...nomenclatureListFilterTypes
  },
  uploadsList: {
    id: 'Int',
    type: 'Enum',
    startDate: 'String',
    endDate: 'String',
    status: 'Enum'
  },
  spendingReports: {
    status: 'Enum'
  },
  realizationReports: {
    status: 'Enum'
  }
}
