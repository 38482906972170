import VueBrowserUpdate from '@sum.cumo/vue-browserupdate'
import Vue from 'vue'

Vue.use(VueBrowserUpdate, {
  options: {
    unsupported: true,
    no_permanent_hide: true,
    url: '/browser-update',
    required: {
      e: -2,
      f: -2,
      o: -2,
      s: -2,
      c: -2,
      y: -2
    },
    text: {
      msg: '<img src="/nuxt/images/planet.svg" width="35" height="35"> <div>Вы используете устаревшую версию брузера<div>',
      msgmore:
        'Мы не можем гарантировать корректную работу личного кабинета в вашей версии браузера.\n' +
        '</br>Пожалуйста обновите ваш браузер.',
      bupdate: 'Обновить браузер'
    }
  },
  test: false // При true показывается всегда
})
