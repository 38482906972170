export default function () {
  return {
    headerRow: {
      field: true,
      name: true
    },
    dataRows: true,
    erroredCount: true,
    successCount: true
  }
}
