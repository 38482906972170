import defaultFileSchema from '@/schema/file'

const defaultDocumentFileSchema = () => {
  return {
    id: true,
    type: true,
    file: defaultFileSchema(),
    createdAt: true,
    updatedAt: true
  }
}

const defaultDocumentExampleFileSchema = () => {
  return {
    id: true,
    name: true,
    description: true,
    file: defaultFileSchema(),
    createdAt: true,
    updatedAt: true
  }
}

const defaultArrangementSchema = () => {
  return {
    contractorType: true,
    taxSystem: true,
    status: true,
    signatureRightsBasis: true,
    currentStep: true,
    id: true,
    guid: true,
    createdAt: true,
    updatedAt: true,
    name: true,
    inn: true,
    ogrn: true,
    kpp: true,
    okpo: true,
    okopf: true,
    manufacturerNames: true,
    manufacturerNameToFile: [
      {
        manufacturerName: true,
        fileId: true
      }
    ],
    website: true,
    contactName: true,
    phone: true,
    signatory: true,
    post: true,
    detailsFio: true,
    detailsPost: true,
    legalAddress: true,
    actualAddress: true,
    signatureRightsDocumentNumber: true,
    signatureRightsDocumentDate: true,
    bik: true,
    bankName: true,
    correspondingAccount: true,
    paymentAccount: true,
    simplifiedTaxSystemFile: defaultFileSchema(),
    contractFile: defaultFileSchema(),
    innFile: defaultFileSchema(),
    requisitesFile: defaultFileSchema(),
    serviceCenterQuestionnaireFile: defaultFileSchema(),
    ogrnFile: defaultFileSchema(),
    orderForAppointmentFile: defaultFileSchema(),
    protocolFile: defaultFileSchema(),
    regulationFile: defaultFileSchema(),
    pricelistFile: defaultFileSchema(),
    ogrnipFile: defaultFileSchema(),
    egripFile: defaultFileSchema(),
    files: defaultDocumentFileSchema(),
    manufacturerFiles: defaultDocumentFileSchema(),
    certificateFiles: defaultDocumentFileSchema(),
    additionalDocumentFiles: defaultDocumentFileSchema()
  }
}

export { defaultArrangementSchema, defaultDocumentExampleFileSchema }
