<template>
  <div class="app-label">
    <ContentLoader
      v-if="isLoading"
      :width="150"
      :height="20"
      :speed="1"
      :style="{ width: '150px', height: '20px;' }"
      primary-сolor="#eaeced"
      secondary-сolor="#ffffff"
    >
      <rect x="0" y="0" rx="1" ry="1" width="150" height="20" />
    </ContentLoader>
    <span v-else-if="$slots.default[0].text.trim()" :class="['label', variant ? `-${variant}` : '']">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppLabel',
  props: {
    variant: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'info', 'success', 'warning', 'hot', 'secondary', 'error'].includes(value)
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-label {
  display: inline-flex;
}

.label {
  display: inline-flex;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #fff;
  padding: $vr-yellow 6px;
  border-radius: 4px;
  background-color: $gray-60;

  &.-info {
    background-color: $blue-50;
  }

  &.-secondary {
    background-color: $gray-85;
  }

  &.-success {
    background-color: $green-50;
  }

  &.-warning {
    background-color: $mandarin;
  }

  &.-hot {
    background-color: $orange;
  }

  &.-error {
    background-color: $red-50;
  }
}
</style>
