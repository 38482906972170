function convertBytes(bytes, toFixedNumber = 2) {
  const strArr = [' б', ' кб', ' мб', ' гб']
  if (bytes < 1024) {
    return bytes + strArr[0]
  }

  for (let i = 0; i < 2; i++) {
    const acc = (bytes / 1024).toFixed(toFixedNumber)
    if (acc < 1024) {
      return acc + strArr[i + 1]
    }
  }
}

function transformToIsoDate(propDate) {
  return typeof propDate === 'string' ? new Date(propDate).toISOString() : propDate.toISOString()
}
function transformToCommonDate(propDate) {
  const date = new Date(propDate)
  const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`
  const month = `${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}`

  return `${day}.${month}.${date.getFullYear()}`
}

function fetchScript(url) {
  let script = document.querySelector(`script[src="${url}"]`)

  if (script?.hasAttribute('is-loaded')) {
    return Promise.resolve()
  }

  if (!script) {
    script = document.createElement('script')
    script.setAttribute('src', url)
    document.body.append(script)
  }

  return new Promise((resolve, reject) => {
    script.addEventListener(
      'load',
      () => {
        script.setAttribute('is-loaded', '')
        resolve()
      },
      { once: true }
    )

    script.addEventListener('error', reject, { once: true })
  })
}

const isObject = value => {
  return Object.prototype.toString.call(value) === '[object Object]'
}

const removeEmptyFilterValues = filter => {
  const object = {}
  for (const [key, value] of Object.entries(filter)) {
    if (value === null || value?.value === null || value === '' || (Array.isArray(value) && !value.length)) {
      continue
    }

    object[key] = value?.guid || value?.id || value
  }

  return object
}

export { convertBytes, transformToIsoDate, transformToCommonDate, fetchScript, isObject, removeEmptyFilterValues }
