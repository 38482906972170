export function set({ state, commit }, propertyAndValue) {
  const propsAndValueArr = propertyAndValue.split(/:(.+)/)
  const propString = propsAndValueArr[0]

  let propValue

  try {
    propValue = JSON.parse(propsAndValueArr[1])
  } catch (err) {
    propValue = propsAndValueArr[1] === 'undefined' ? undefined : propsAndValueArr[1]
  }

  const propArr = propString.split('.')
  let currentObj = state

  propArr.forEach((key, index) => {
    if (index === propArr.length - 1) {
      commit('SET_PROPERTY', { obj: currentObj, key, value: propValue })
    } else {
      currentObj = currentObj[key]
    }
  })
}

export function get(state) {
  return function (property) {
    const propArr = property.split('.')
    let currentObj = state
    let value = ''

    propArr.forEach((key, index) => {
      if (index === propArr.length - 1) {
        value = currentObj ? currentObj[key] : null
      } else {
        currentObj = currentObj[key]
      }
    })

    return value
  }
}

export function setProperty(state, { obj, key, value }) {
  obj[key] = value
}
