<template>
  <FilterBox :is-active="isActive" width="500px" @applyFilter="applyFilter" @clearFilter="clearFilter">
    <template #title>
      {{ label }}
    </template>
    <template #content>
      <AppTreeSelect
        v-model="innerValue"
        api-entity="categories"
        api-method="list"
        model-name="categoriesSearch"
        :cancel-check-sub-item="true"
        placeholder=""
        @change="onChange"
        @search="innerValue.label = $event"
      >
        <template #option="{ item }">
          <span v-html="`${item.hightLightLabel} (ID ${item.pdmId})`"></span>
        </template>
      </AppTreeSelect>
    </template>
  </FilterBox>
</template>

<script>
import FilterBox from '@/components/filters/FilterBox'
import AppTreeSelect from '@/components/ui/AppTreeSelect/AppTreeSelect'

export default {
  name: 'CategoryFilter',
  components: {
    AppTreeSelect,
    FilterBox
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Выберите вариант'
    },
    dataProvider: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    innerValue: {
      label: '',
      id: null
    }
  }),
  async fetch() {
    await this.setInitialValue(this.value)
  },
  watch: {
    value(value) {
      this.setInitialValue(value)
    }
  },
  methods: {
    applyFilter() {
      const value = this.innerValue.label && !this.innerValue.id ? -1 : this.innerValue.id
      this.$emit('apply-filter', value)
    },
    clearFilter() {
      this.innerValue.id = null
      this.innerValue.label = ''
      this.applyFilter()
    },
    onChange({ id, label }) {
      this.innerValue.id = id
      this.innerValue.label = label
    },
    async setInitialValue(value) {
      if (!value) return
      try {
        const response = await this.$api.categories.get(value)
        this.innerValue.id = response.data.category?.id || null
        this.innerValue.label = response.data.category?.name || ''
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err.message || err.errorMessage || 'Не удалось получить список'
        })
      }
    }
  }
}
</script>
