import defaultTnvedSchema from '@/schema/tnved'

const getQuery = (search, fields, limit) => {
  return {
    query: {
      tnvedItems: {
        __args: {
          pattern: search,
          limit
        },
        ...(Object.keys(fields).length ? fields : defaultTnvedSchema())
      }
    }
  }
}

export const list =
  ({ $axios }) =>
  (search = '', fields = {}, limit = 10) => {
    const query = getQuery(search, fields, limit)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
