<template>
  <hr class="block-separator" />
</template>

<script>
export default {
  name: 'AppBlockSeparator'
}
</script>

<style lang="scss" scoped>
.block-separator {
  border: 0;
  height: 1px;
  background-color: $gray-50;
  margin-bottom: $vr-fuchsia;
  margin-top: $vr-fuchsia;
}
</style>
