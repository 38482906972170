import Vue from 'vue'

import '@/components/ui/BaseComponentValidation/validators.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// Start design system's components
import {
  BaseButton,
  Tooltip,
  Calendar,
  Autocomplete,
  BaseSelect,
  Breadcrumbs,
  Counter,
  BaseInput,
  Modal,
  SvgIcon,
  Tabs,
  Tab,
  Badge,
  Checkbox
} from '@vi-frontend/elements'
// End design system's components

import { ContentLoader } from 'vue-content-loader'
import AppRow from '@/components/ui/AppGrid/AppRow'
import AppCol from '@/components/ui/AppGrid/AppCol'
import AppTable from '@/components/ui/AppTable'
import AppLabel from '@/components/ui/AppLabel'
import AppButton from '@/components/buttons/AppButton'
import AppHeader from '@/components/ui/AppHeader'
import AppTag from '@/components/ui/AppTag'
import AppInput from '@/components/ui/AppInput'
import AppFieldErrorDetail from '@/components/ui/AppFieldErrorDetail'
import AppFieldNoteDetail from '@/components/ui/AppFieldNoteDetail'
import AppAutocomplete from '@/components/ui/AppAutocomplete'
import UploadAlert from '@/components/ui/UploadAlert'
import AppBlockSeparator from '@/components/ui/AppBlockSeparator'
import AppInfoBlock from '@/components/ui/AppInfoBlock'
import AppFixedLeftColumn from '@/components/layout/AppFixedLeftColumn'

import BaseInputValidation from '@/components/ui/BaseComponentValidation/BaseInputValidation'
import BaseSelectValidation from '@/components/ui/BaseComponentValidation/BaseSelectValidation'
import AppFileUploaderValidation from '@/components/ui/BaseComponentValidation/AppFileUploaderValidation'
import AppAutocompleteValidation from '@/components/ui/BaseComponentValidation/AppAutocompleteValidation'
import AppFormValidation from '@/components/ui/BaseComponentValidation/AppFormValidation'
import AppTextEditorValidation from '@/components/ui/BaseComponentValidation/AppTextEditorValidation'

// Validation
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// Design system's components
Vue.component('BaseIcon', SvgIcon)
Vue.component('BaseButton', BaseButton)
Vue.component('Tooltip', Tooltip)
Vue.component('Calendar', Calendar)
Vue.component('Autocomplete', Autocomplete)
Vue.component('BaseSelect', BaseSelect)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('Modal', Modal)
Vue.component('Counter', Counter)
Vue.component('BaseInput', BaseInput)
Vue.component('Checkbox', Checkbox)
Vue.component('Tabs', Tabs)
Vue.component('Tab', Tab)
Vue.component('Badge', Badge)

// App's components
Vue.component('AppRow', AppRow)
Vue.component('AppCol', AppCol)
Vue.component('AppTable', AppTable)
Vue.component('AppLabel', AppLabel)
Vue.component('AppButton', AppButton)
Vue.component('AppHeader', AppHeader)
Vue.component('AppTag', AppTag)
Vue.component('ContentLoader', ContentLoader)
Vue.component('AppInput', AppInput)
Vue.component('AppAutocomplete', AppAutocomplete)
Vue.component('AppFieldErrorDetail', AppFieldErrorDetail)
Vue.component('AppFieldNoteDetail', AppFieldNoteDetail)
Vue.component('UploadAlert', UploadAlert)
Vue.component('AppBlockSeparator', AppBlockSeparator)
Vue.component('AppInfoBlock', AppInfoBlock)
Vue.component('AppFixedLeftColumn', AppFixedLeftColumn)
Vue.component('AppBlockSeparator', AppBlockSeparator)

Vue.component('BaseSelectValidation', BaseSelectValidation)
Vue.component('BaseInputValidation', BaseInputValidation)
Vue.component('AppAutocompleteValidation', AppAutocompleteValidation)
Vue.component('AppFileUploaderValidation', AppFileUploaderValidation)
Vue.component('AppFormValidation', AppFormValidation)
Vue.component('AppTextEditorValidation', AppTextEditorValidation)

Vue.component('BaseSelectValidation', BaseSelectValidation)
Vue.component('BaseInputValidation', BaseInputValidation)
Vue.component('AppAutocompleteValidation', AppAutocompleteValidation)
Vue.component('AppFileUploaderValidation', AppFileUploaderValidation)
Vue.component('AppFormValidation', AppFormValidation)
Vue.component('AppTextEditorValidation', AppTextEditorValidation)
