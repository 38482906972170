<template>
  <FixedButton icon="question-circle" @open-modal="toggleModal(true)">
    <VerticalModal v-if="isOpen" @close-modal="toggleModal(false)">
      <div class="marketing-faq">
        <section class="marketing-faq__section">
          <Typo tag="h3" text-type="heading" text-variant="3" class="marketing-faq__element">
            Акция «Продвижение товаров в каталоге сайта»
          </Typo>
          <Typo text-variant="3">
            Это маркетинговое продвижение товаров в конкретных категориях, которое позволяет увеличить переходы в
            карточку товара <br />
            и повысить продажи.
          </Typo>
        </section>
        <section class="marketing-faq__section">
          <Typo tag="h3" text-type="heading" text-variant="5" class="marketing-faq__element"> Как это работает? </Typo>
          <div class="marketing-faq__element">
            <Typo text-variant="3" class="marketing-faq__paragraph">
              <strong>Листинг</strong> – это отображение карточек товаров в категории. В каждой категории могут быть
              десятки страниц с товарами, но пользователи чаще всего просматривают только первую страницу.
            </Typo>
            <Typo text-variant="3" class="marketing-faq__paragraph">
              С помощью акции вы можете выбрать товары из своего ассортимента, которые будут отображаться на первой
              странице категории среди 20 верхних товаров. Карточка продвигаемого товара не маркируется как рекламная –
              в глазах пользователя это выглядит как естественный спрос на товар.
            </Typo>
          </div>
          <img src="/nuxt/images/marketing-faq/how_it_works.png" alt="Как это работает" class="marketing-faq__image" />
        </section>
        <section class="marketing-faq__section">
          <Typo tag="h3" text-type="heading" text-variant="5" class="marketing-faq__element"> Что это дает? </Typo>
          <Typo text-variant="3" class="marketing-faq__paragraph">
            Результаты акции показывают рост в нескольких направлениях:
          </Typo>
          <AppList :items="listContentList[0]" class="marketing-faq__paragraph" />
        </section>
        <section class="marketing-faq__section">
          <Typo tag="h3" text-type="heading" text-variant="5" class="marketing-faq__element"> Какие условия? </Typo>
          <Typo text-variant="3" class="marketing-faq__paragraph">
            Для продвижения товаров можно использовать <strong>3 – 6 уровень</strong> каталога сайта.
          </Typo>
          <AppList :items="listContentList[1]" class="marketing-faq__paragraph" />
          <img
            src="/nuxt/images/marketing-faq/what_conditions.png"
            alt="Какие условия"
            class="marketing-faq__element marketing-faq__image"
          />
          <Typo text-variant="3" class="marketing-faq__paragraph">
            Вы можете закрепить товар на нескольких уровнях каталога сайта одновременно при наличии свободных мест. В
            таком случае продвижение будет решать сразу две задачи: продажи и большее число просмотров карточки товара.
          </Typo>
          <Typo text-variant="3" class="marketing-faq__paragraph">
            В одной категории можно повысить одновременно несколько товаров.
          </Typo>
          <AppList :items="listContentList[2]" class="marketing-faq__paragraph" />
          <Typo text-variant="3" class="marketing-faq__paragraph">
            Срок действия акции – 1 месяц.
            <br />
            Стоимость рассчитывается индивидуально.
          </Typo>
        </section>
        <section class="marketing-faq__section">
          <Typo tag="h3" text-type="heading" text-variant="5" class="marketing-faq__element">Как запустить акцию?</Typo>
          <Typo text-variant="3">
            1. Перейдите в раздел <strong>Маркетинг</strong> и нажмите кнопку <strong>«Создать новую акцию»</strong>.
          </Typo>
          <img
            src="/nuxt/images/marketing-faq/step_1.png"
            alt="Шаг 1"
            class="marketing-faq__element marketing-faq__image"
          />
          <Typo text-variant="3">
            2. Заполните обязательные поля и нажмите кнопку <strong>«Добавить товары»</strong>.
          </Typo>
          <img
            src="/nuxt/images/marketing-faq/step_2.png"
            alt="Шаг 2"
            class="marketing-faq__element marketing-faq__image"
          />
          <Typo text-variant="3">
            3. Выберите товары для продвижения – вы можете использовать фильтры или найти их по названию.
          </Typo>
          <img
            src="/nuxt/images/marketing-faq/step_3.png"
            alt="Шаг 3"
            class="marketing-faq__element marketing-faq__image"
          />
          <Typo text-variant="3" class="marketing-faq__element">
            4. Нажмите кнопку <strong>«Добавить товары»</strong>.
          </Typo>
          <Typo text-variant="3"> 5. Выберите категории для продвижения. </Typo>
          <img
            src="/nuxt/images/marketing-faq/step_5.png"
            alt="Шаг 5"
            class="marketing-faq__element marketing-faq__image"
          />
          <Typo text-variant="3" class="marketing-faq__element">
            6. Проверьте настройки и нажмите кнопку <strong>«Запланировать акцию»</strong>.
          </Typo>
          <Typo text-variant="3">
            7. Ожидайте письмо от менеджеров <strong>в течение 1 рабочего дня</strong> – на вашу электронную почту
            придет <strong>ответ по заявке</strong>, стоимость услуги и счет-оферта для оплаты.
          </Typo>
        </section>
        <section class="marketing-faq__section">
          <Typo tag="h3" text-type="heading" text-variant="5" class="marketing-faq__element">
            Как проверить эффективность акции?
          </Typo>
          <Typo text-variant="3" class="marketing-faq__paragraph">
            После окончания акции <strong>вы получите отчет</strong> по каждому товару – данные за 1 месяц до акции и за
            1 месяц во время акци
          </Typo>
          <Typo text-variant="3" class="marketing-faq__paragraph"> В отчете будет указана статистика: </Typo>
          <AppList :items="listContentList[3]" class="marketing-faq__paragraph" />
        </section>
      </div>
    </VerticalModal>
  </FixedButton>
</template>

<script>
import { Typo } from '@vi-frontend/elements'
import AppList from '@/components/ui/AppList'
import FixedButton from '@/components/buttons/FixedButton'
import VerticalModal from '@/components/modals/VerticalModal'

export default {
  name: 'MarketingFAQ',
  components: {
    AppList,
    FixedButton,
    VerticalModal,
    Typo
  },
  data() {
    return {
      isOpen: false,
      listContentList: [
        [
          'переходы в карточку товара до <strong>+80%</strong>',
          'количество покупок до <strong>+50%</strong>',
          'добавление товара в корзину до <strong>+100%</strong>'
        ],
        [
          '<strong>3 и 4 уровень</strong> позволяют большему количеству пользователей увидеть товары – подходит для знакомства с брендом, демонстрации новинок',
          '<strong>5 и 6 уровень</strong> охватывает меньше пользователей, но увеличивает конверсию в продажу – подходит для продажи конкретных товаров'
        ],
        ['не более 5 товаров', 'не более 2 товаров одного бренда'],
        ['количество переходов в карточку товара', 'количество добавлений в корзину', 'общий объем продаж']
      ]
    }
  },
  methods: {
    toggleModal(state) {
      this.isOpen = state
    }
  }
}
</script>

<style lang="scss" scoped>
.marketing-faq {
  max-width: 920px;
  &__section {
    margin-bottom: $vr-purple;
  }
  &__element {
    margin: 0 0 $vr-orange;
  }
  &__image {
    width: 540px;
    height: 300px;
    object-fit: contain;
  }
  &__paragraph {
    margin-top: $vr-brown;
    &:not(:last-child) {
      margin-bottom: $vr-brown;
    }
  }
}
</style>
