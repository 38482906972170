<template>
  <div class="app-progress-bar">
    <div class="title">
      <slot v-if="$slots.title" name="title" />
      <template v-if="type === 'normal'"> {{ progress }} % </template>
    </div>
    <div class="progress" :style="{ height: progressBarHeight + 'px' }">
      <div :class="['progress-bar', '-' + progressColor]" :style="{ width: progress + '%' }"></div>
      <div v-if="type === 'inset'" class="progress-text">{{ progress }}%</div>
    </div>
    <div v-if="$slots.description" class="description">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppProgressBar',
  props: {
    progress: {
      type: [Number, String],
      default: 0
    },
    progressColor: {
      type: String,
      default: 'blue',
      validator(value) {
        return ['blue', 'gray'].includes(value)
      }
    },
    type: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'inset'].includes(value)
      }
    },
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['small', 'normal', 'large'].includes(value)
      }
    }
  },
  computed: {
    progressBarHeight() {
      if (this.size === 'small') {
        return 4
      }

      if (this.size === 'normal') {
        return 8
      }

      if (this.size === 'large') {
        return 16
      }

      return 4
    }
  }
}
</script>

<style lang="scss" scoped>
.app-progress-bar {
  > .title {
    margin-bottom: $vr-yellow;
  }

  > .progress {
    margin-bottom: $vr-yellow;
  }
}

.title,
.progress-text {
  font-weight: 500;
  @include normal-text;
}

.progress {
  background: $gray-30;
  border-radius: 4px;
  position: relative;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 8px;
  font-weight: 500;
  @include small-text;
  color: $white;
}

.progress-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s;

  &.-blue {
    background-color: $blue-50;
  }

  &.-gray {
    background-color: $gray-50;
  }
}

.description {
  color: $gray-85;
  font-weight: normal;
  @include small-text;
}
</style>
