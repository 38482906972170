import defaultHintsSchema from '@/schema/hints'

const getQuery = fields => {
  return {
    query: {
      guiHelpItems: {
        ...(Object.keys(fields).length ? fields : defaultHintsSchema())
      }
    }
  }
}

export const list =
  ({ $axios }) =>
  (fields = {}) => {
    const query = getQuery(fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
