<template>
  <BasePagination
    :total="total"
    :current-page="current"
    :page-size="perPage"
    :step="perPage"
    :url-pattern="urlPattern"
    :url-prefix="generalUrlPath"
    @page="goToPage"
  />
</template>

<script>
import { BasePagination } from '@vi-frontend/elements'
import { transformFromCleanUrl, transformToCleanUrl } from '@/pages/nomenclature/blocks/use/useTransformCleanUrl'

export default {
  name: 'PasPagination',
  components: {
    BasePagination
  },
  props: {
    total: {
      type: [String, Number],
      default: 1
    },
    current: {
      type: [String, Number],
      default: 1
    },
    perPage: {
      type: [String, Number],
      default: 1
    },
    isUrlPagination: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    generalUrlPath() {
      const { filters, orderBy } = transformFromCleanUrl(this.$route.query)
      const query = transformToCleanUrl({ filters, orderBy })
      return this.isUrlPagination ? `${this.$route.path}?${query}` : ''
    },
    urlPattern() {
      return this.isUrlPagination ? '&pageNumber={pageNumber}' : ''
    }
  },
  methods: {
    goToPage(pageNumber) {
      if (!this.isUrlPagination) {
        this.$emit('update:page-number', pageNumber)
        return
      }

      this.$router.push({
        path: this.generalUrlPath,
        query: { ...this.$route.query, pageNumber }
      })
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  &:first-child {
    justify-content: flex-start !important;
  }
  justify-content: center !important;
  .number {
    border-bottom: none;
    &.-active {
      background-color: $blue-50 !important;
    }
  }
}
</style>
