<template>
  <div>
    <AppHeader v-if="isUserAgreementPage" class="user-agreement-header"> Пользовательское соглашение </AppHeader>
    <div class="user-agreement">
      <p>
        Настоящее Соглашение заключается между ООО «ВсеИнструменты.ру» и любым юридическим лицом/индивидуальным
        предпринимателем, которое после принятия условий данного Пользовательского соглашения (далее по тексту
        «Соглашение») путем проставления галочки «Согласен с условиями пользовательского соглашения» становится
        Пользователем Личного кабинета (далее по тексту ЛКП), расположенного в сети Интернет по адресу:
        https://pas.vseinstrumenti.ru/, в дальнейшем вместе по тексту Соглашения именуемые «Стороны», а по отдельности –
        «Сторона» о нижеследующем:
      </p>
      <p class="mb-3">
        В соответствии со статьей 435 Гражданского кодекса РФ настоящее Cоглашение признается офертой. Текст Соглашения
        является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского кодекса РФ. Надлежащим акцептом
        настоящей оферты в соответствии со статьей 438 Гражданского кодекса РФ считается совершение всех действий,
        указанных в Соглашении. Безусловным принятием (акцептом) условий настоящего Соглашения считается факт
        регистрации ЛКП на сайте vseinstrumenti.ru. Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не
        требует двустороннего подписания.
      </p>
      <ol>
        <li class="item">
          <div class="header">Термины и определения, используемые в соглашении</div>
          <p>
            В Соглашении, если из текста Соглашения прямо не вытекает иное, следующие слова и выражения будут иметь
            указанные ниже значения:
          </p>
          <ol>
            <li>
              «Авторизация» – ввод Пользователем Логина и Пароля от ЛКП для целей пользования услугами и/или программным
              обеспечением ООО «ВсеИнструменты.ру».
            </li>
            <li>
              «Администрация сайта» — уполномоченные на управление сайтом сотрудники, действующие от имени ООО
              «ВсеИнструменты.ру».
            </li>
            <li>
              «Законный представитель» – физическое лицо, представляющее интересы юридического лица или индивидуального
              предпринимателя и совершающее от его имени действия в ЛКП.
            </li>
            <li>
              «Личный кабинет Поставщика» – web-страница на сайте ООО «ВсеИнструменты.ру», предоставляющая возможность
              регистрации и пользования программным обеспечением ООО «ВсеИнструменты.ру», размещенный по адресу:
              https://pas.vseinstrumenti.ru/
            </li>
            <li>«Логин» – электронная почта Пользователя.</li>
            <li>
              «Пароль» – цифровой код для доступа в ЛКП, который указал Пользователь при регистрации в ЛКП либо код,
              который был выслан на электронную почту Пользователя Администрацией сайта.
            </li>
            <li>
              ООО «ВсеИнструменты.ру» - разработчик программного обеспечения, который предоставляет неисключительные
              права использования программ для ЭВМ.
            </li>
            <li>
              «Регистрация» – совокупность действий Пользователя, а именно предоставление своих данных и первичное
              введение Логина и Пароля. При регистрации Пользователь предоставляет такие данные как: фамилия, имя,
              отчество (при наличии), мобильный телефон Законного представителя; наименование, ИНН,
              организационно-правовая форма Пользователя и адрес электронной почты.
            </li>
            <li>
              «Пользователь» – юридическое лицо или индивидуальный предприниматель, акцептовавшие оферту путем
              прохождения процедуры регистрации ЛКП и заключившие настоящее Соглашение.
            </li>
            <li>
              «Сайт» – совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видео
              материалов и иных результатов интеллектуальной деятельности, а также программных и аппаратных средств,
              обеспечивающих публикацию для всеобщего обозрения информации и данных, объединенных общим целевым
              назначением, посредством технических средств, применяемых для связи в сети Интернет. Сайт находится в сети
              Интернет по адресу: https://vseinstrumenti.ru/
            </li>
            <li>
              «Учетные данные» – учетные данные Пользователя, а именно: фамилия, имя, отчество (при наличии), Логин и
              мобильный телефон Законного представителя, наименование Пользователя. Все остальные термины и определения,
              встречающиеся в тексте договора, не указанные в настоящем разделе, толкуются Сторонами в соответствии с
              законодательством Российской Федерации и сложившимися в сети Интернет обычными правилами толкования
              соответствующих терминов.
            </li>
          </ol>
          <p>
            Все остальные термины и определения, встречающиеся в тексте договора, не указанные в настоящем разделе,
            толкуются Сторонами в соответствии с законодательством Российской Федерации и сложившимися в сети Интернет
            обычными правилами толкования соответствующих терминов.
          </p>
        </li>
        <li class="item">
          <div class="header">Предмет соглашения</div>
          <ol>
            <li>
              В рамках ЛКП Администрация сайта обязуется, предоставить Пользователю возможность регистрироваться и
              пользоваться услугами и программным обеспечением ООО «ВсеИнструменты.ру», которые были подключены
              Пользователем с помощью ЛКП.
            </li>
            <li>
              Обязательным условием оказания услуг в соответствии с настоящим Соглашением является принятие, соблюдение
              Пользователем и применение к отношениям Сторон требований и положений, определенных настоящим Соглашением.
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Права и обязанности сторон</div>
          <ol>
            <li>
              <div class="sub-header">Администрация сайта обязуется</div>
              <ol>
                <li>Обеспечить Пользователю доступ в ЛКП, в соответствии с п.2.1., п.2.2. настоящего Соглашения</li>
                <li>
                  Не разглашать, не передавать информацию третьим лицам о Пользователе и его действиях в ЛКП, за
                  исключением случаев, предусмотренных действующим законодательством Российской Федерации.
                </li>
              </ol>
            </li>
            <li>
              <div class="sub-header">Администрация сайта имеет право:</div>
              <ol>
                <li>
                  Отказать Пользователю в предоставлении доступа к ЛКП, в случае нарушения Пользователем своих
                  обязательств по настоящему Соглашению либо в случаях, предусмотренных действующим законодательством.
                </li>
                <li>
                  Осуществлять ограничение доступа к ЛКП (в том числе с использованием автоматизированных систем) в
                  целях реализации установленных действующим законодательством РФ обязательств Администрации сайта по
                  защите информации.
                </li>
                <li>
                  Вносить изменения в ЛКП в связи с внедрением новых продуктов и услуг, а также предоставлять доступ к
                  новым сервисам без предварительного уведомления Пользователя.
                </li>
                <li>Изменять условия настоящего Соглашения в одностороннем порядке.</li>
              </ol>
            </li>
            <li>
              <div class="sub-header">Администрация сайта не несет ответственность:</div>
              <ol>
                <li>
                  За последствия несанкционированного использования ЛКП третьими лицами, случившегося не по вине
                  Администрации сайта.
                </li>
                <li>
                  За прямой или косвенный ущерб, понесенный Пользователем в результате ошибок, пропусков, перерывов в
                  работе, изменения функций, дефектов, задержек в работе, случившихся не по вине Администрации сайта.
                </li>
              </ol>
            </li>
            <li>
              <div class="sub-header">Пользователь обязан:</div>
              <ol>
                <li>
                  Предоставить при Регистрации достоверные данные и при использовании ЛКП предоставить достоверные
                  Учетные данные юридического лица либо индивидуального предпринимателя, в случае если Пользователь
                  действует от их имени.
                </li>
                <li>
                  Производить действия в ЛКП от имени юридического лица, либо индивидуального предпринимателя только при
                  наличии доверенности, выданной данными лицами до момента совершения действий в ЛКП. Доверенность на
                  предоставление интересов юридического лица либо индивидуального предпринимателя должна быть
                  предоставлена Пользователем не позднее 3 (трех) рабочих дней с даты получения запроса Администрации
                  сайта на его электронную почту.
                </li>
                <li>
                  Обеспечить сохранность информации о Логине, Пароле и информации, содержащейся в ЛКП. Пользователь
                  самостоятельно определяют порядок ограничения доступа к такой информации. При использовании ЛКП, до
                  момента получения от Пользователя информации о нарушения режима конфиденциальности, все действия и
                  документы, совершенные и направленные с помощью ЛКП, даже если такие действия и документы были
                  совершены и направлены иными лицами, считаются совершенными и направленными Пользователем. В этом
                  случае права и обязанности, а также ответственность наступают у Пользователя. В случае утраты, а также
                  в случаях незаконного доступа к Логину и Паролю третьими лицами, Пользователь обязуется
                  незамедлительно сообщить об этом Администрации, путем направления соответствующего письма на
                  электронный почтовый адрес: _____@vseinstrumenti.ru.
                </li>
                <li>
                  Пользователь обязуется, ни при каких условиях не предоставлять третьим лицам свой Логин и Пароль,
                  используемый для доступа к ЛКП и его содержимому. Пользователь самостоятельно несет ответственность за
                  сохранность установленного Пользователем Пароля к ЛКП и за убытки, которые могут возникнуть по причине
                  несанкционированного использования Пароля.
                </li>
                <li>
                  Обеспечить за свой счет доступ в Интернет, а также настройку и защиту от несанкционированного
                  использования своего оборудования, а также оперативно реагировать при обнаружении случаев такого
                  использования.
                </li>
                <li>
                  Нести ответственность за все действия, предпринятые через ЛКП, имевшие место после регистрации
                  Пользователя в ЛКП.
                </li>
                <li>
                  Нести ответственность за любое нарушение обязательств, установленных настоящим Соглашением, а также за
                  все последствия таких нарушений (включая любые убытки или ущерб, которые может понести Администрация
                  Сайта).
                </li>
                <li>
                  Не использовать услуги ЛКП для совершения каких-либо действий, противоречащих действующему
                  законодательству.
                </li>
                <li>
                  Соблюдать условия настоящего Соглашения или немедленно отказаться от использования Личного кабинета в
                  случае наступления обстоятельств, препятствующих дальнейшему использования ЛКП Пользователем.
                </li>
              </ol>
            </li>
            <li>
              <div class="sub-header">Пользователь имеет право:</div>
              <ol>
                <li>Пользоваться ЛКП согласно условиям настоящего Соглашения.</li>
                <li>
                  В случае возникновения претензий, пожеланий, связанных с предоставлением услуг ЛКП, оформить
                  соответствующее заявление.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Регистрация пользователя в личном кабинете поставщика</div>
          <ol>
            <li>
              Регистрация Пользователя в ЛКП производится Законным представителем путем заполнения необходимой
              информации о Пользователе в разделе «Партнеры» сайта vseinstrumenti.ru., а также формированием Пароля.
              Законный представитель подтверждает достоверность указанных им данных и факт регистрации Пользователя в
              ЛКП путем введения Пароля. С момента введения Пароля ученая запись Пользователя считается созданной и
              готовой для использования в целях настоящего Соглашения.
            </li>
            <li>
              В случае необходимости пароль для использования ЛКП может быть выслан Администрацией сайта на электронную
              почту Пользователя, указанную при регистрации ЛКП.
            </li>
            <li>
              Факт Регистрации в ЛКП означает полное и безоговорочное принятие Пользователем условий настоящего
              Соглашения с момента регистрации.
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Авторизация пользователя в личном кабинете постащика</div>
          <ol>
            <li>Пользователь при повторном вхождении в ЛКП вводит Пароль и Логин на web-странице ЛКП.</li>
            <li>
              Все действия, произведенные с использованием данных Пользователя и ЛКП, являются действиями самого
              Пользователя, что является бесспорным доказательством волеизъявления Пользователя.
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Использование личного кабинета поставщика</div>
          <ol>
            <li>
              <div class="sub-header">Пользователь посредством ЛКП имеет возможность:</div>
              <ol>
                <li>Управлять своей номенклатурой.</li>
                <li>Добавлять контент на товары, поставляемые Пользователем ООО «ВсеИнструменты.ру».</li>
                <li>Получать отчеты по своей деятельности.</li>
                <li>Получать информацию о работе ООО «ВсеИнструменты.ру».</li>
              </ol>
            </li>
            <li>
              <div class="sub-header">Администрация Сайта вправе:</div>
              <ol>
                <li>Изменять перечень Услуг в ЛКП.</li>
                <li>
                  Приостановить, изменить порядок предоставления либо прекратить предоставление доступа Пользователя в
                  ЛКП в случае нарушения Пользователем условий настоящего Соглашения, а также в случаях, предусмотренных
                  действующим законодательством.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Ответственность сторон</div>
          <ol>
            <li>
              Пользователь несет ответственность за разглашение информации о Пароле и за доступ третьих лиц к
              информации, содержащейся в ЛКП, в том числе к информации, относящейся к конфиденциальной
              информации/коммерческой тайне.
            </li>
            <li>
              Пользователь обязуется возместить ООО «ВсеИнструменты.ру» убытки понесенные последним по причине
              несоблюдения Пользователем настоящего Соглашения, в том числе убытки, причиненные Администрации сайта в
              размере ответственности, наложенной государственными органами.
            </li>
            <li>
              Администрация сайта не несет ответственность за убытки, причиненные Пользователю в результате разглашения
              третьим лицам Учетных данных Пользователя и Пароля, произошедшего не по вине Администрации сайта.
              Пользователь самостоятельно определяет перечень организационных и программных средств для сохранения в
              тайне своих Учетных данных, Пароля и обеспечения санкционированного доступа к ней. Если любое лицо, помимо
              Пользователя, авторизуется в ЛКП, используя Логин и Пароль Пользователя, то все действия, совершенные
              таким лицом, будут считаться совершенными этим Пользователем. Пользователь самостоятельно несет
              ответственность за все действия, совершенные им в ЛКП, а также за все действия, совершенные в ЛКП любыми
              иными лицами с использованием Логина и Пароля Пользователя.
            </li>
            <li>
              Администрация сайта не несет ответственности за убытки, причиненные Пользователем третьим лицам в
              результате Регистрации и использования ЛКП.
            </li>
            <li>
              Администрация сайта прилагает все возможные усилия для обеспечения нормальной работоспособности Сайта,
              однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению, а
              также возможные убытки, возникшие в том числе, но не ограничиваясь, в результате:<br />
              - неправомерных действий Пользователей, направленных на нарушения информационной безопасности или
              нормального функционирования Сайта;<br />
              - отсутствия (невозможности установления, прекращения и пр.) интернет-соединения между сервером
              Пользователя и сервером Сайта;<br />
              - установления государственного регулирования (или регулирования иными организациями) хозяйственной
              деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых
              ограничений, затрудняющих или делающих невозможным исполнение Соглашения;<br />
              - других случаев, связанных с действиями (бездействием) Пользователей и/или других субъектов,
              направленными на ухудшение общей ситуации с использованием сети интернет и/ или компьютерного
              оборудования, существовавшей на момент заключения Соглашения, а также любых других действий, направленных
              на Сайт и на третьих лиц;<br />
              - выполнения технических работ.
            </li>
            <li>
              Администрация сайта имеет право производить профилактические/технические работы на Сайте, что может
              повлечь за собой временное приостановление работы Сайта и/или ограничение функциональных возможностей ЛКП.
            </li>
            <li>
              В случае наступления форс-мажорных обстоятельств, а также аварий или сбоев в программно-аппаратных
              комплексах третьих лиц, сотрудничающих с Администрацией сайта, или действий (бездействия) третьих лиц,
              направленных на приостановку или прекращение функционирования Сайта, возможна приостановка работы Сайта
              без предварительного уведомления Пользователя.
            </li>
            <li>
              Администрация сайта не несет ответственности за нарушение Пользователем настоящего Соглашения и оставляет
              за собой право по своему собственному усмотрению, а также при получении информации от других пользователей
              либо третьих лиц о нарушении Пользователем настоящего Соглашения приостанавливать, ограничивать или
              прекращать доступ Пользователя к ЛКП в любое время по любой причине или без объяснения причин, с
              предварительным уведомлением или без такового, не отвечая за любой вред, который может быть причинен таким
              действием.
            </li>
            <li>
              Администрация сайта закрепляет за собой право удалить ЛКП Пользователя и (или) приостановить, ограничить
              или прекратить доступ Пользователя к любому из сервисов Сайта, если обнаружит, что, по его мнению,
              Пользователь представляет угрозу для Сайта либо для лиц, указанных Пользователем в качестве лиц, чьи
              интересы он представляет. Администрация сайта не несет ответственности за осуществленное в соответствии с
              настоящим условием временное блокирование или удаление информации либо удаление ЛКП (прекращение
              регистрации) Пользователя. Удаление ЛКП Пользователя означает автоматическое удаление всей информации,
              размещенной на ней. После удаления ЛКП Пользователь теряет права доступа к ЛКП.
            </li>
            <li>
              Ни одна из Сторон не несет ответственности за полное или частичное неисполнение любой из своих
              обязанностей, если неисполнение является следствием таких обстоятельств, как наводнение, пожар,
              землетрясение, другие стихийные бедствия, война или военные действия и другие обстоятельства непреодолимой
              силы, возникшие после заключения Соглашения и не зависящие от воли Сторон.
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Порядок разрешения споров и урегулирования претензий</div>
          <ol>
            <li>
              В случае возникновения споров между Пользователем и Администрацией сайта по вопросам, связанным с
              исполнением Соглашения, Стороны примут все меры к разрешению их путем переговоров между собой.
              Претензионный порядок разрешения споров обязателен. Пользователь имеет возможность направить претензию на
              электронные адреса, указанные в пункте 7.2. настоящего Соглашения.
            </li>
            <li>
              Для решения технических вопросов при определении вины Пользователя в результате его неправомерных действий
              при использовании ЛКП, в частности, Администрация сайта вправе самостоятельно привлекать компетентные
              организации в качестве экспертов. В случае установления вины Пользователя последний обязан возместить
              затраты на проведение экспертизы.
            </li>
            <li>
              Все споры между Сторонами подлежат разрешению путем переговоров. При не достижении соглашения, спор
              рассматривается в арбитражном суде по адресу истца.
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Заверения и гарантии</div>
          <ol>
            <li>
              Пользователь подтверждает, что при регистрации ЛКП передал достоверные данные о себе, а также актуальные
              банковские реквизиты и контактные данные.
            </li>
            <li>
              Пользователь обеспечить актуальность информации о себе в ЛКП, обязуется сообщать об изменении указанной
              ЛКП информации в течение 5 (пяти) рабочих дней с момента ее изменения.
            </li>
            <li>Пользователь подтверждает, что не намерен использовать ЛКП в противоправных целях.</li>
            <li>
              Не существует законодательных, подзаконных нормативных и индивидуальных актов, локальных документов,
              судебных актов, а также решений органов управления, запрещающих Пользователю или ограничивающих его право
              заключать и исполнять настоящее Соглашение.
            </li>
          </ol>
        </li>
        <li class="item">
          <div class="header">Прочие условия</div>
          <ol>
            <li>
              Стороны обязуются обеспечивать конфиденциальность сведений и информации, необходимых для доступа к
              уполномоченным адресам электронной почты, не допускать разглашение такой информации и передачу третьим
              лицам. Стороны самостоятельно определяют порядок ограничения доступа к такой информации. При использовании
              уполномоченных адресов электронной почты, до момента получения от второй Стороны информации о нарушения
              режима конфиденциальности, все действия и документы, совершенные и направленные с помощью уполномоченного
              адреса электронной почты второй Стороны, даже если такие действия и документы были совершены и направлены
              иными лицами, считаются совершенными и направленными такой второй Стороной. В этом случае права и
              обязанности, а также ответственность наступают у такой второй Стороны.
            </li>
            <li>
              Уполномоченными адресами электронной почты Администрации Сайта признаются: – для ООО «ВсеИнструменты.ру»:
              lkp@vseinstrumenti.ru<br />
              – для Пользователя: адрес электронной почты, указанный Пользователем при получении доступа к ЛКП.<br />
            </li>
            <li>
              Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному
              исполнению не влечет недействительности иных положений Соглашения.
            </li>
            <li>
              Во всем остальном, что не урегулировано Соглашением, Стороны руководствуются действующим законодательством
              Российской Федерации.
            </li>
            <li>
              Положения настоящего Соглашения устанавливаются, изменяются и отменяются Администрацией сайта в
              одностороннем порядке без предварительного уведомления Пользователя. С момента размещения на Сайте новой
              редакции Соглашения предыдущая редакция считается утратившей свою силу.
            </li>
            <li>
              Пользователь вправе знакомиться с содержанием Соглашения, размещенного на Сайте, в целях своевременного
              ознакомления с его изменениями. Новая редакция Соглашения и/или какой-либо его неотъемлемой части вступает
              в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен
              Администрацией сайта при их опубликовании.
            </li>
            <li>
              Настоящее Соглашение вступает в силу с момента акцепта настоящей оферты Пользователем и заключается на
              неопределенный срок.
            </li>
            <li>
              Если Пользователь не согласен с условиями настоящего Соглашения, то он должен обратиться к Администрации
              сайта по lkp@vseinstrumenti.ru для блокировки ЛКП Пользователя. В противном случае продолжение
              использования Сайта Пользователем означает, Пользователь согласен с условиями Соглашения.
            </li>
            <li>
              Пользователь подтверждает, что ознакомился со всеми положениями Соглашения, понимает и принимает их.
            </li>
            <li>
              Ни одна из Сторон не вправе передавать свои права и обязанности по настоящему Соглашению без
              предварительного письменного согласия другой Стороны.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import useUserAgreement from '@/composables/useUserAgreement'

export default {
  name: 'UserAgreement',
  setup() {
    const { isUserAgreementPage } = useUserAgreement()

    return {
      isUserAgreementPage
    }
  }
}
</script>

<style lang="scss">
.user-agreement {
  &-header {
    margin-top: $vr-green;
    margin-bottom: $vr-purple;
  }

  p {
    @include normal-text;
    font-weight: 400;
    color: $gray-90;
  }

  .item {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin-bottom: $vr-fuchsia;

    .header {
      margin-bottom: $vr-orange;
    }

    .sub-header {
      margin-bottom: $vr-brown;
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;

    li {
      display: table;
      counter-increment: item;
      font-weight: 400;
      margin-bottom: $vr-brown;
      @include normal-text;

      &:last-child {
        margin-bottom: 0;
      }
    }

    li:before {
      content: counters(item, '.') '. ';
      display: table-cell;
      padding-right: 4px;
    }
  }
}
</style>
