var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-tag",on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.type === 'link')?_c('nuxt-link',{class:[
      'link',
      {
        '-active': _vm.isActive,
        '-disabled': _vm.isDisabled
      }
    ],attrs:{"to":_vm.url,"event":_vm.isDisabled || _vm.hasClickListener || !_vm.isClickable ? '' : 'click'},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.type === 'radio')?_c('label',[_c('input',{staticClass:"input-radio",attrs:{"type":"radio","name":_vm.name}}),_vm._v(" "),_c('span',{class:['text-radio']},[_vm._t("default")],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }