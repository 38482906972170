<template>
  <div class="dark-popup-wrapper" :style="{ width: contentWidth }">
    <div ref="content" class="dark-popup">
      <div class="dark-popup-content" @click="detectIsLink" v-html="content"></div>
      <a href="#" class="dark-popup-icon" @click="hideHint">
        <SvgIcon icon="cross" size="24" />
      </a>
      <div class="dark-popup-tail-wrapper">
        <div class="dark-popup-tail"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { SvgIcon } from '@vi-frontend/elements'

export default {
  name: 'DarkPopup',
  components: {
    SvgIcon
  },
  props: {
    content: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      contentWidth: null
    }
  },
  mounted() {
    this.contentWidth = this.$refs.content?.clientWidth + 'px'
  },
  methods: {
    hideHint(e) {
      e.preventDefault()
      this.$emit('close')
    },
    detectIsLink(event) {
      if (event.target.tagName === 'A') this.hideHint()
    }
  }
}
</script>

<style lang="scss" scoped>
.dark-popup-wrapper {
  position: absolute;
  right: calc(100% + 30px);
  width: 370px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
}

.dark-popup {
  position: relative;
  padding: 16px 40px 16px 16px;
  background: linear-gradient(90deg, rgba($gray-90, 0.9) 90%, $gray-90 100%);
  border-radius: 6px;
  z-index: 20;
  .dark-popup-tail-wrapper {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-2px, -50%);
    z-index: 5;
  }
  .dark-popup-tail {
    position: relative;
    padding: 24px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 24px solid transparent, 24px solid $gray-90, 24px solid transparent, 24px solid transparent;
      z-index: 1;
    }
  }

  & > .dark-popup-icon,
  & > .dark-popup-content {
    color: $white;
  }

  &,
  & > .dark-popup-content {
    display: inline-block;
  }

  & > .dark-popup-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    border-bottom: none;
  }
}
</style>

<style lang="scss">
.dark-popup-content a {
  color: $gray-10;
  border-bottom: 1px solid #f5f5f5;
}
</style>
