import defaultUserSchema from '@/schema/user'

const getQuery = fields => {
  return {
    query: {
      user: {
        ...(Object.keys(fields).length ? fields : defaultUserSchema())
      }
    }
  }
}

export const get =
  ({ $axios }) =>
  (fields = {}) => {
    const query = getQuery(fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const logout =
  ({ $axios }) =>
  () => {
    return $axios.$post(window.PAS.ENV.PAS_URL + '/site/logout')
  }

export const agreeAgreement =
  ({ $axios }) =>
  () => {
    const query = {
      mutation: {
        userAgreementAccept: true
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
