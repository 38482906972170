<template>
  <notifications position="bottom right" classes="app-notification" :max="3" width="380px" :duration="30000">
    <template #body="props">
      <div :class="['app-notification', `-${props.item.type}`]" @click="props.close">
        <base-icon icon="cross" size="12" class="btn-close" title="Закрыть уведомление" />
        <div class="body">
          <base-icon
            :icon="props.item.type === 'success' ? 'check-circle' : 'info'"
            size="18"
            class="icon"
            width="20px"
          />

          <div class="content">
            <div v-if="props.item.title" class="title">
              {{ props.item.title }}
            </div>
            <div v-if="props.item.text" class="text" v-html="props.item.text"></div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'AppNotification'
}
</script>

<style lang="scss" scoped>
.app-notification {
  position: relative;
  padding: $vr-fuchsia 24px;
  background: $white;
  border-radius: 6px;

  .body {
    display: flex;
  }

  .content {
    flex: 1;
  }

  .title {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .text {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .icon {
    margin-right: 16px;
  }

  &.-success {
    color: $green-50;
  }

  &.-error {
    color: $red-60;
  }

  .btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: $gray-60;
  }
}
</style>

<style>
.vue-notification-group {
  right: 30px !important;
  bottom: 44px !important;
}

.vue-notification-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px !important;
  border-radius: 6px;
}
</style>
