import { VariableType } from 'json-to-graphql-query'
import addFilesToQuery from '@/mixins/addFilesToQuery'
import defaultContentMassUploadGetUploadDataFields from '@/schema/contentMassUploadGetUploadData'

const getQuery = (filters, fields) => {
  if (filters.categoryId) {
    filters.category = parseInt(filters.categoryId)
  }
  if (filters.category) {
    filters.category = parseInt(filters.category)
  }
  if (fields.categoryId) {
    fields.categoryId = parseInt(fields.categoryId)
  }

  return {
    mutation: {
      contentMassUploadGenerateTemplateFile: {
        __args: {
          settings: {
            filter: filters,
            ...fields
          }
        }
      }
    }
  }
}

const getNomenclatureListQuery = (uploadId, fields, limit, offset, showErrors) => {
  return {
    query: {
      contentMassUploadGetUploadData: {
        __args: {
          uploadId,
          limit,
          offset,
          showErrors
        },
        ...(Object.keys(fields).length ? fields : defaultContentMassUploadGetUploadDataFields())
      }
    }
  }
}

const getAddChangesQuery = uploadId => {
  return {
    mutation: {
      contentMassUploadSaveData: {
        __args: {
          uploadId
        }
      }
    }
  }
}

export const generateTemplateFile =
  ({ $axios }) =>
  (filters = {}, fields = {}) => {
    const query = getQuery(filters, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getNomenclatureListByUploadId =
  ({ $axios }) =>
  (uploadId, fields = {}, limit = 10, offset = 0, showErrors = false) => {
    const query = getNomenclatureListQuery(uploadId, fields, limit, offset, showErrors)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const addChanges =
  ({ $axios }) =>
  uploadId => {
    const query = getAddChangesQuery(uploadId)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const uploadFile =
  ({ $axios }) =>
  file => {
    const query = {
      mutation: {
        __variables: {
          file: 'Upload!'
        },
        contentMassUploadUploadFile: {
          __args: {
            file: new VariableType('file')
          },
          uploadId: true,
          jobId: true
        }
      }
    }

    const formData = addFilesToQuery(file, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }
