const middleware = {}

middleware['checkUserAgreementPage'] = require('../middleware/checkUserAgreementPage.js')
middleware['checkUserAgreementPage'] = middleware['checkUserAgreementPage'].default || middleware['checkUserAgreementPage']

middleware['hasAttachedVideo'] = require('../middleware/hasAttachedVideo.js')
middleware['hasAttachedVideo'] = middleware['hasAttachedVideo'].default || middleware['hasAttachedVideo']

middleware['hasHints'] = require('../middleware/hasHints.js')
middleware['hasHints'] = middleware['hasHints'].default || middleware['hasHints']

middleware['hasPermission'] = require('../middleware/hasPermission.js')
middleware['hasPermission'] = middleware['hasPermission'].default || middleware['hasPermission']

middleware['hasUser'] = require('../middleware/hasUser.js')
middleware['hasUser'] = middleware['hasUser'].default || middleware['hasUser']

middleware['isUploadNewNomenclaturePage'] = require('../middleware/isUploadNewNomenclaturePage.js')
middleware['isUploadNewNomenclaturePage'] = middleware['isUploadNewNomenclaturePage'].default || middleware['isUploadNewNomenclaturePage']

middleware['isUserAcceptAgreement'] = require('../middleware/isUserAcceptAgreement.js')
middleware['isUserAcceptAgreement'] = middleware['isUserAcceptAgreement'].default || middleware['isUserAcceptAgreement']

export default middleware
