export default function () {
  return {
    id: true,
    guid: true,
    name: true,
    extension: true,
    createdAt: true,
    updatedAt: true,
    createdUserId: true,
    updatedUserId: true,
    size: true,
    downloadUrl: true
  }
}
