import { setProperty } from '@/mixins/store'

export const state = () => ({
  hints: []
})

export const getters = {
  hint: state => guid => {
    return state.hints.find(hint => hint.guid === guid)
  },
  hintText: (state, getters) => guid => {
    return getters.hint(guid)?.hint || ''
  }
}

export const mutations = {
  SET_PROPERTY: setProperty
}

export const actions = {
  setHints({ commit, state }, hints) {
    commit('SET_PROPERTY', { obj: state, key: 'hints', value: hints })
  }
}
