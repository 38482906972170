export default function () {
  return {
    id: true,
    email: true,
    fio: true,
    permissions: true,
    roles: true,
    supplier: {
      id: true,
      name: true,
      edoStatus: true,
      edoId: true
    },
    params: {
      isFromAdminPanel: true,
      adminPanelUrl: true
    },
    userMenu: {
      name: true,
      url: true,
      isDisable: true
    },
    registrationStage: true,
    registrationStageData: true,
    isFirstLoginAsSupplier: true,
    isTermsAccepted: true,
    nomenclatureListColumns: {
      type: true,
      isVisible: true,
      isDisabled: true
    }
  }
}
