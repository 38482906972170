export default function () {
  return {
    totalCount: true,
    reports: {
      documentNumber: true,
      invoiceNumber: true,
      purpose: true,
      expenseAt: true,
      amount: true,
      amountPayed: true,
      amountPayedOffset: true,
      status: true,
      downloadUrl: true,
      downloadUrlXlsx: true,
      comment: true,
      rejectReason: true
    }
  }
}
