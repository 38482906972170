import defaultJobSchema from '@/schema/job'

const getJobQuery = (jobId, fields) => {
  const query = {
    query: {
      job: {
        __args: {
          id: jobId
        },
        ...(Object.keys(fields).length ? fields : defaultJobSchema())
      }
    }
  }

  return query
}

const getJobByUploadIdQuery = (uploadId, fields) => {
  const query = {
    query: {
      newNomenclatureUploadParseJob: {
        __args: {
          upload: { uploadId }
        },
        ...(Object.keys(fields).length ? fields : defaultJobSchema())
      }
    }
  }

  return query
}

export const get =
  ({ $axios }) =>
  (jobId, fields = {}) => {
    const query = getJobQuery(jobId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getJobByUploadId =
  ({ $axios }) =>
  (uploadId, fields = {}) => {
    const query = getJobByUploadIdQuery(uploadId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
