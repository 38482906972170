<template>
  <validationProvider v-slot="{ errors }" :rules="rules" :mode="mode" :vid="name" tag="div">
    <AppTextEditor v-bind="$attrs" v-on="$listeners" />
    <AppFieldErrorDetail v-if="errors[0]" :text="errors[0]" />
  </validationProvider>
</template>

<script>
import AppTextEditor from '@/components/ui/AppTextEditor'

export default {
  name: 'AppTextEditorValidationVue',
  components: {
    AppTextEditor
  },
  props: {
    rules: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
