export default function () {
  return {
    items: {
      guid: true,
      title: true,
      value: {
        __on: [
          {
            __typeName: 'TechParamRangeValue',
            to: true,
            from: true
          },
          {
            __typeName: 'TechParamScalarValue',
            value: true
          }
        ]
      },
      type: true,
      measureUnit: true
    },
    existsActiveChanges: true
  }
}
