import { EnumType, VariableType } from 'json-to-graphql-query'
import defaultUploadSchema from '@/schema/upload'
import defaultPhotoUploadFileSchema from '@/schema/photoUploadFile'
import addFilesToQuery from '@/mixins/addFilesToQuery'

export const uploadPhoto =
  ({ $axios }) =>
  (uploadId, uploadImage, fields = {}, config = {}) => {
    const query = {
      mutation: {
        __variables: {
          file: 'Upload!'
        },
        photoMassUploadAddPhoto: {
          __args: {
            uploadedFile: new VariableType('file'),
            uploadId: parseInt(uploadId)
          },
          ...(Object.keys(fields).length ? fields : defaultPhotoUploadFileSchema())
        }
      }
    }

    const defaultConfig = {
      isFileUpload: true
    }

    const formData = addFilesToQuery(uploadImage, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, Object.assign(defaultConfig, config))
  }

export const createUpload =
  ({ $axios }) =>
  (photoUploadMode, fields = {}) => {
    const query = {
      mutation: {
        photoMassUploadCreateUpload: {
          __args: {
            photoUploadMode: new EnumType(photoUploadMode)
          },
          ...(Object.keys(fields).length ? fields : defaultUploadSchema())
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const deletePhoto =
  ({ $axios }) =>
  (uploadId, photoUploadFileId) => {
    const query = {
      mutation: {
        photoMassUploadDeletePhoto: {
          __args: {
            uploadId,
            photoUploadFileId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const photoMassUploadCheckPhotos =
  ({ $axios }) =>
  uploadId => {
    const query = {
      mutation: {
        photoMassUploadCheckPhotos: {
          __args: {
            uploadId
          }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveUploadType =
  ({ $axios }) =>
  (uploadId, photoUploadMode, fields = {}) => {
    const query = {
      mutation: {
        photoMassUploadSaveUploadParams: {
          __args: {
            uploadId,
            photoUploadMode: new EnumType(photoUploadMode)
          },
          ...(Object.keys(fields).length ? fields : defaultUploadSchema())
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const saveUpload =
  ({ $axios }) =>
  uploadId => {
    const query = {
      mutation: {
        photoMassUploadApplyChanges: {
          __args: {
            uploadId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const getUploadedFiles =
  ({ $axios }) =>
  (uploadId, fields = {}) => {
    const query = {
      query: {
        photoMassUploadGetUploadFiles: {
          __args: {
            uploadId
          },
          ...(Object.keys(fields).length ? fields : defaultPhotoUploadFileSchema())
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
