export default function () {
  return {
    id: true,
    statusId: true,
    statusLabel: true,
    resultText: true,
    resultData: true,
    params: true,
    completePercent: true
  }
}
