import defaultNomenclatureImagesSchema from '@/schema/nomenclatureImages'

const getQuery = (nomenclatureId, fields) => {
  const resultFields = Object.keys(fields).length ? fields : defaultNomenclatureImagesSchema()

  return {
    query: {
      nomenclatureImages: {
        __args: {
          supplierNomenclatureId: nomenclatureId
        },
        ...resultFields
      }
    }
  }
}

const list =
  ({ $axios }) =>
  (nomenclatureId, fields = {}) => {
    const query = getQuery(nomenclatureId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const sortImages =
  ({ $axios }) =>
  (nomenclatureId, photoIds) => {
    const query = {
      mutation: {
        sortImages: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            identifiers: photoIds
          },
          sortImages: true,
          validationErrors: {
            field: true,
            errors: true
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const cancelRemoveImage =
  ({ $axios }) =>
  (nomenclatureId, imageId) => {
    const query = {
      mutation: {
        cancelDeleteImage: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            identifier: imageId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const removeImage =
  ({ $axios }) =>
  (nomenclatureId, imageId) => {
    const query = {
      mutation: {
        deleteImage: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            identifier: imageId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const addImages =
  ({ $axios, $json2graphql }) =>
  (images, nomenclatureId) => {
    const formData = new FormData()

    const query = {
      mutation: {
        __variables: {
          file: '[Upload!]!'
        },
        addImages: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            uploadedFiles: new $json2graphql.VariableType('file')
          },
          uploadedImages: Object.assign(defaultNomenclatureImagesSchema(), { url: { __aliasFor: 'previewUrl' } }),
          validationErrors: {
            field: true,
            errors: true
          }
        }
      }
    }

    const operations = $json2graphql.jsonToGraphQLQuery(query)
    const map = {}
    const variablesFiles = { file: [] }

    const imagesArray = images.toString() === '[object FileList]' ? Object.values(images) : images
    imagesArray.forEach((image, index) => {
      map[index] = [`variables.file.${index}`]
      variablesFiles.file.push(null)
      formData.append(index, image)
    })

    const operationsString = JSON.stringify(operations)
    const variablesFilesString = JSON.stringify(variablesFiles)
    const mapString = JSON.stringify(map)

    formData.append('operations', '{ "query": ' + operationsString + ', "variables":' + variablesFilesString + '}')
    formData.append('map', mapString)

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

export { list, sortImages, cancelRemoveImage, removeImage, addImages }
