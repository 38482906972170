import { EnumType } from 'json-to-graphql-query'
import filterTypes from '@/constants/filtersTypes'

function modifyFilters(type, value) {
  switch (type) {
    case 'Int':
      return value * 1
    case '[Int]':
      return JSON.parse(value).map(el => el * 1)
    case 'Enum':
      return new EnumType(value)
    case 'Boolean':
      return value === 'true'
    default:
      return value
  }
}

export default function (filters, model) {
  if (!filters) return false
  Object.keys(filters).forEach(filter => {
    if (typeof filters[filter] === 'string') {
      filters[filter] = modifyFilters(filterTypes[model][filter], filters[filter])
    } else {
      Object.keys(filters[filter]).forEach(innerFilter => {
        if (typeof filters[filter][innerFilter] === 'string') {
          filters[filter][innerFilter] = modifyFilters(
            filterTypes[model][filter][innerFilter],
            filters[filter][innerFilter]
          )
        }
      })
    }
  })
}
