<template>
  <div :class="['input-wrapper', { '-error': error }]">
    <input
      v-mask="mask"
      type="text"
      :value="innerValue"
      :class="['input', `-${size}`]"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div v-if="isShowDetail && error" class="input-detail">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },

    size: {
      type: String,
      default: ''
    },

    mask: {
      type: [String, Array, Object],
      default: ''
    },

    error: {
      type: String,
      default: ''
    },

    isShowDetail: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      innerValue: this.value
    }
  },

  watch: {
    value(value) {
      this.innerValue = value
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  color: $red-60;

  &.-short {
    width: 76px;
  }
}

.input {
  height: 40px;
  width: 100%;
  padding: $vr-brown 16px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  box-shadow: inset 0 0 0 1px $gray-60;
  border: none;
  border-radius: 6px;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px $gray-70;
  }

  &.-small {
    height: 32px;
    padding: $vr-brown 16px;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

.-error {
  .input {
    box-shadow: inset 0 0 0 2px $red-60;

    &:focus {
      box-shadow: inset 0 0 0 2px $red-60;
    }
  }
}
</style>
