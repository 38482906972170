<template>
  <div v-click-outside class="user-notifications">
    <div :class="['header', { '-active': isShow }]" @click="toggle">
      <base-icon icon="bell" :class="['header-icon', { '-active': isShow }]" />
      <Badge :value="unreadCount" :color="unreadCount ? 'blue' : 'gray'" />
    </div>
    <div v-if="isShow" class="body">
      <div class="body-title">Уведомления</div>
      <div :class="['body-content', { 'no-result': !items.length }]" @scroll="onScrollNotifications">
        <ul v-if="items.length" id="notifications_list" class="list">
          <li v-for="(item, index) in items" :id="'notification-' + item.id" :key="index" class="list-item">
            <ListLoader v-if="isLoading" :is-horizontal-padding="false" />
            <a
              v-else
              :href="item.actionUrl"
              target="_blank"
              :class="['list-item-link', { '-active': !item.isRead }]"
              @click="readNotifications(item)"
            >
              <span class="link-text">
                {{ item.subject }}
                <template v-if="item.groupCount > 1">
                  ({{ item.groupCount > 99 ? '99+' : item.groupCount }} )
                </template>
                <span class="date">
                  {{ getReadNotificationDate(item.createdAt) }}
                </span>
              </span>
            </a>
          </li>
        </ul>
        <div v-else-if="!items.length && !isLoading" class="no-result-block">
          <base-icon icon="bell" class="no-result-icon" />
          Новых уведомлений нет
        </div>
      </div>
      <div class="body-footer">
        <a href="/notifications-history" class="history-link" @click.prevent="goToNotificationsHistory">
          История уведомлений
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutside from '@/mixins/clickOutside'
import getReadNotificationDate from '@/mixins/getReadNotificationDate'
import ListLoader from '@/components/list/HistoryList/ListLoader'

export default {
  name: 'AppUserNotifications',
  components: {
    ListLoader
  },
  directives: {
    'click-outside': clickOutside('close')
  },
  data() {
    return {
      items: [],
      isShow: false,
      unreadCount: 0,
      isLoading: false
    }
  },
  scrollTimer: null,
  notificationDelay: 3000,
  async fetch() {
    if (!this.isShow) return
    this.isLoading = true
    this.items = []

    const query = {
      query: {
        notifications: {
          items: {
            id: true,
            subject: true,
            groupCount: true,
            createdAt: true,
            isRead: true,
            actionUrl: true
          },
          count: true,
          unreadCount: true
        }
      }
    }

    try {
      const response = await this.$axios.$post('/graphql', { query })
      this.items = response.data.notifications.items
    } catch (err) {
      this.$notify({
        type: 'error',
        title: err.errorMessage || err.message || 'Не удалось получить список уведомлений'
      })
    } finally {
      setTimeout(() => {
        if (this.isShow) {
          this.readNotifications()
        }
      }, this.$options.notificationDelay)
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    }
  },
  watch: {
    isShow(value) {
      if (value) {
        this.$fetch()
      }
    }
  },
  beforeMount() {
    this.getUnreadCount()

    this.$SSE.instance?.on('notifications', msg => {
      let response
      try {
        response = JSON.parse(msg)
        this.unreadCount = this.isShow ? this.unreadCount : response.count
      } catch (err) {
        this.$sentry.captureException('Ошибка парсинга уведомлений, переданных по sse')
      }
    })
  },
  methods: {
    getReadNotificationDate,
    toggle(event) {
      if (event.target.closest('.body')) {
        return
      }

      this.isShow = !this.isShow
    },
    close(event) {
      if (!event.target.closest('.user-notifications')) {
        this.isShow = false
      }

      return false
    },
    getUnreadVisibleNotifications() {
      const targetBlock = document.querySelector('.user-notifications .body-content')

      if (!targetBlock) return

      const targetBlockHeight = targetBlock.getBoundingClientRect().height
      const offsetTargetBlock = 20

      return this.items.filter(item => {
        if (item.isRead) return false

        const element = document.querySelector('#notification-' + item.id)
        const elementOffsetTopWithScrollTop = element.offsetTop - targetBlock.scrollTop // offset с учетом скролла
        return (
          elementOffsetTopWithScrollTop <= targetBlockHeight - offsetTargetBlock &&
          elementOffsetTopWithScrollTop >= -offsetTargetBlock
        )
      })
    },
    onScrollNotifications() {
      clearTimeout(this.$options.scrollTimer)

      this.$options.scrollTimer = setTimeout(() => {
        this.readNotifications()
      }, this.$options.notificationDelay)
    },
    goToNotificationsHistory() {
      this.isShow = false
      this.$router.push('/notifications-history')
    },
    async readNotifications(notification) {
      if (notification?.isRead) {
        return
      }

      const unreadVisibleNotifications = notification ? [notification] : this.getUnreadVisibleNotifications()

      if (!unreadVisibleNotifications.length) return

      const query = {
        mutation: {
          readNotifications: {
            __args: {
              ids: unreadVisibleNotifications.map(item => item.id)
            },
            ids: true
          }
        }
      }

      try {
        const response = await this.$axios.$post('/graphql', { query })
        unreadVisibleNotifications.forEach(item => {
          item.isRead = true
        })

        const newUnreadCount = this.unreadCount - response.data.readNotifications.ids.length
        this.unreadCount = newUnreadCount >= 0 ? newUnreadCount : 0
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err.errorMessage || err.message || 'Не удалось пометить уведомления как прочитанные'
        })
      }
    },
    async getUnreadCount() {
      const query = {
        query: {
          notifications: {
            unreadCount: true
          }
        }
      }

      try {
        const response = await this.$axios.$post('/graphql', { query })
        this.unreadCount = response.data.notifications.unreadCount
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err.errorMessage || err.message || 'Не удалось получить количество новых уведомлений'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-notifications {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.5s;

  &-icon {
    margin-right: 8px;
    font-size: 24px;
    color: $gray-80;

    &.-active {
      color: $gray-90;
    }
  }

  &.-active {
    background-color: $gray-10;
  }
}

.body {
  position: absolute;
  width: 452px;
  height: 524px;
  display: flex;
  flex-direction: column;
  right: 0;
  left: auto;
  padding: $vr-pink 16px;
  background-color: $white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  &-title {
    padding-bottom: $vr-pink;
    border-bottom: 1px solid $gray-50;
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: 700;
    color: $gray-90;
  }

  &-content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-content.no-result {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-footer {
    padding-top: $vr-pink;
    border-top: 1px solid $gray-50;
  }
}

.list {
  position: relative;
}

.list-item-link {
  display: flex;
  padding: $vr-pink 12px;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: none;
  border-bottom: none;

  &:before {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: 6px;
    margin-right: 8px;
    background-color: $gray-50;
    border-radius: 50%;
  }

  &.-active {
    &:before {
      background-color: $blue-50;
    }
  }

  &:hover {
    background-color: $gray-10;
  }
}

.link-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: $vr-yellow;
}

.history-link {
  font-size: 1.4rem;
  line-height: 2rem;

  .date {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.no-result-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: italic;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $gray-70;
}

.no-result-icon {
  font-size: 40px;
  color: $gray-50;
  margin-bottom: $vr-orange;
}

.date {
  color: $gray-60;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-top: $vr-yellow;
}
</style>
