import { render, staticRenderFns } from "./AppStarRating.vue?vue&type=template&id=e781b17a&scoped=true&"
import script from "./AppStarRating.vue?vue&type=script&lang=js&"
export * from "./AppStarRating.vue?vue&type=script&lang=js&"
import style0 from "./AppStarRating.vue?vue&type=style&index=0&id=e781b17a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e781b17a",
  null
  
)

export default component.exports