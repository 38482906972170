<template>
  <modal width="1280px" class="video-modal" @close="$emit('close-modal')">
    <div class="spacer"></div>
    <iframe class="iframe-video" allow="fullscreen; autoplay" :src="setVideoSource()"> </iframe>
    <VideoModalFeedback :id="id" class="mt-2" :active-button="setActiveButton()"></VideoModalFeedback>
  </modal>
</template>

<script>
import VideoModalFeedback from '@/components/modals/blocks/VideoModalFeedback'

export default {
  name: 'VideoModal',
  components: {
    VideoModalFeedback
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
    isYoutube: {
      type: Boolean,
      default: true
    },
    activeButton: {
      type: [String, Boolean],
      default: null
    }
  },
  methods: {
    setActiveButton() {
      if (typeof this.activeButton === 'boolean') {
        return this.activeButton ? 'positive' : 'negative'
      } else {
        return this.activeButton
      }
    },
    setVideoSource() {
      return this.isYoutube
        ? `https://www.youtube.com/embed/${this.url}?autoplay=1`
        : `https://rutube.ru/play/embed/${this.url}`
    }
  }
}
</script>

<style lang="scss" scoped>
.video-modal {
  z-index: 260;
}
.iframe-video {
  border: none;
  width: 100%;
  border-radius: 10px;
  min-height: 730px;
}
.spacer {
  height: 30px;
}

@media (max-height: 920px) {
  .iframe-video {
    min-height: 70vh;
  }
}
</style>
