const valueFragment = () => ({
  __on: [
    {
      __typeName: 'TechParamRangeValue',
      to: true,
      from: true
    },
    {
      __typeName: 'TechParamScalarValue',
      value: true
    }
  ]
})

export default function () {
  return {
    value: valueFragment(),
    oldValue: valueFragment(),
    listValues: {
      __on: [
        {
          __typeName: 'TechParamListValue',
          key: true,
          value: true
        },
        {
          __typeName: 'TechParamColorListValue',
          key: true,
          value: true,
          colorHexCode: true
        }
      ]
    },
    supplierListValues: {
      key: true,
      value: true
    },
    canceledListValues: {
      key: true,
      value: true
    },
    guid: true,
    unit: true,
    title: true,
    type: true,
    comment: true,
    showInSearch: true,
    previousGuid: true
  }
}
