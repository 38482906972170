<template>
  <div class="feedback-container">
    <div>
      <div class="feedback-container__title">
        {{ title }}
      </div>
      <div class="feedback-container__buttons">
        <base-button
          variant="link"
          :class="[
            'feedback-container__button',
            '-positive',
            { '-disabled': defaultButtonChoice || !isEnabled },
            { '-filled': buttonChoice === 'positive' }
          ]"
          @click="setChoice('positive')"
        >
          <div class="text">
            {{ buttonsText.positive }}
          </div>
          <template #icon>
            <img :src="checkButtonChoice('positive')" :alt="buttonsText.positive" class="icon" />
          </template>
        </base-button>
        <base-button
          v-if="defaultButtonChoice || !isEnabled"
          variant="link"
          :class="['feedback-container__button', '-negative', '-disabled', { '-filled': buttonChoice === 'negative' }]"
        >
          <div class="text">
            {{ buttonsText.negative }}
          </div>
          <template #icon>
            <img :src="checkButtonChoice('negative')" :alt="buttonsText.negative" class="icon" />
          </template>
        </base-button>
        <Tooltip v-else ref="feedbackTooltip" width="340px" :show-close-button="false" popup @close="clearButtonChoice">
          <base-button
            variant="link"
            :class="[
              'feedback-container__button',
              '-negative',
              { '-disabled': defaultButtonChoice },
              { '-filled': buttonChoice === 'negative' }
            ]"
            @click="setChoice('negative')"
          >
            <div class="text">
              {{ buttonsText.negative }}
            </div>
            <template #icon>
              <img :src="checkButtonChoice('negative')" :alt="buttonsText.negative" class="icon" />
            </template>
          </base-button>
          <template #content>
            <div class="feedback-container__title -no-gap">Что мы можем изменить?</div>
            <div class="feedback-container__description">Оставив комментарии, вы поможете нам улучшить сервис</div>
            <base-input
              v-model="feedbackText"
              placeholder="Ваш комментарий"
              :animated-placeholder="false"
              tag-name="textarea"
              :rows="3"
              maxlength="255"
              required
            />
            <div class="feedback-container__inner-button">
              <AppButton class="app-primary" size="small" @click="sendFeedback('negative')"> Отправить </AppButton>
            </div>
          </template>
        </Tooltip>
      </div>
    </div>
    <AppButton tag-name="a" class="app-primary" href="/video-list"> Все обучающие видео </AppButton>
  </div>
</template>

<script>
import { computed, defineComponent, ref, useContext, useStore } from '@nuxtjs/composition-api'
import { Tooltip } from '@vi-frontend/elements'

const ICONS = {
  positive: '/nuxt/images/videos/thumb-up.svg',
  positiveFilled: '/nuxt/images/videos/thumb-up-filled.svg',
  negative: '/nuxt/images/videos/thumb-down.svg',
  negativeFilled: '/nuxt/images/videos/thumb-down-filled.svg'
}

export default defineComponent({
  name: 'VideoModalFeedBack',
  components: {
    Tooltip
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: 'Поделитесь впечатлением от видео'
    },
    activeButton: {
      type: String,
      default: null
    },
    buttonsText: {
      type: Object,
      default: () => ({
        positive: 'Все хорошо',
        negative: 'Ничего не понятно'
      })
    }
  },
  setup(props) {
    const store = useStore()
    const updatedButtonChoice = computed(() => store.getters['videos/getVideoFeedbackValue'])
    const defaultButtonChoice = ref(props.activeButton || updatedButtonChoice.value(props.id))
    const buttonChoice = ref(defaultButtonChoice.value)

    const checkButtonChoice = type => {
      return buttonChoice.value === type ? ICONS[`${type}Filled`] : ICONS[type]
    }

    const setChoice = type => {
      if (buttonChoice.value === 'negative' || buttonChoice.value === 'positive') return false
      buttonChoice.value = type
      if (type === 'negative') {
        buttonChoice.value = type
      } else {
        buttonChoice.value = type
        sendFeedback(type)
      }
    }

    const isEnabled = ref(true)
    const clearButtonChoice = () => {
      if (isEnabled.value) buttonChoice.value = null
    }
    const disableButtons = () => {
      isEnabled.value = false
    }

    const { $api } = useContext()
    const feedbackText = ref(null)
    const feedbackTooltip = ref(null)
    const sendFeedback = async type => {
      const liked = type === 'positive'
      const feedback = type === 'negative' ? feedbackText.value : null
      const result = await $api.videoInstructions.setVideoInstructionFeedback(props.id, liked, feedback)
      if (result.data.setVideoInstructionFeedback) {
        await store.dispatch('videos/updateFeedbackList', { id: props.id, value: type })
        disableButtons()
        feedbackTooltip.value.hideTooltip()
      } else {
        disableButtons()
      }
    }

    return {
      buttonChoice,
      defaultButtonChoice,
      feedbackText,
      feedbackTooltip,
      isEnabled,

      checkButtonChoice,
      clearButtonChoice,
      setChoice,
      sendFeedback
    }
  }
})
</script>

<style lang="scss" scoped>
.feedback-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    &.-no-gap {
      margin-bottom: 0;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 20px;
    margin: $vr-brown 0 $vr-pink;
  }
  &__buttons {
    display: flex;
    align-items: center;
  }
  &__button {
    margin-right: 16px;
    &.-disabled {
      cursor: default;
    }
    &.-positive {
      &.-filled,
      &:hover:not(.-disabled) {
        .text {
          color: $green-50;
        }
        .icon {
          filter: brightness(0) saturate(100%) invert(48%) sepia(90%) saturate(376%) hue-rotate(71deg) brightness(97%)
            contrast(87%);
        }
      }
    }
    &.-negative {
      &.-filled,
      &:hover:not(.-disabled) {
        .text {
          color: $red-50;
        }
        .icon {
          filter: brightness(0) saturate(100%) invert(8%) sepia(99%) saturate(7112%) hue-rotate(16deg) brightness(102%)
            contrast(118%);
        }
      }
    }
  }
  &__inner-button {
    display: flex;
    justify-content: flex-end;
    margin-top: $vr-pink;
  }
}

.icon {
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(49%) sepia(0%) saturate(44%) hue-rotate(224deg) brightness(99%)
    contrast(88%);
}

//TODO: проверить насколько валидный typography.scss - при правках убрать хак
a {
  border-bottom: none !important;
}
</style>
