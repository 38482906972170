import { get, setProperty } from '@/mixins/store'
import { USER_ROLES, USER_TYPES } from '@/constants/user'

export const state = () => ({
  user: {
    supplier: {},
    permissions: [],
    params: {}
  }
})

export const getters = {
  get,
  hasOnePermission: state => permissions => {
    return permissions.some(permission => {
      return state.user.permissions.includes(permission)
    })
  },
  hasUser(state) {
    return state.user?.permissions.length
  },
  isFromAdminPanel(state) {
    return state.user?.params.isFromAdminPanel
  },
  adminPanelUrl(state) {
    return state.user?.params.adminPanelUrl
  },
  isMatchedRole: state => role => {
    return state.user?.roles?.includes(role)
  },
  isAuthorized(state) {
    return state.user?.id
  },
  isSupplier(state) {
    return state.user?.roles?.includes(USER_ROLES.supplier)
  },
  isTemporarySupplier(state) {
    return state.user?.roles?.includes(USER_ROLES.tempSupplier)
  },
  isPlanReserveManager(state) {
    return state.user?.roles?.includes(USER_ROLES.reserveManager)
  },
  registrationStageData(state) {
    return state.user?.registrationStageData
  },
  registrationStage(state) {
    return state.user?.registrationStage
  },
  isFirstLoginAsSupplier(state) {
    return state.user?.isFirstLoginAsSupplier
  },
  isUserAcceptAgreement(state) {
    return state.user?.isTermsAccepted
  },
  supplierId(state) {
    return state.user?.supplier?.id
  },
  getUserType(state, getters) {
    if (!state.user.id) return
    return getters.isMatchedRole(USER_ROLES.supplier) || getters.isMatchedRole(USER_ROLES.tempSupplier)
      ? USER_TYPES.supplier
      : USER_TYPES.manager
  }
}

export const mutations = {
  SET_PROPERTY: setProperty,
  SET_LIST_COLUMNS: (state, { model, columns }) => {
    state.user[model] = columns
  }
}

export const actions = {
  setUser({ commit, state }, user) {
    commit('SET_PROPERTY', { obj: state, key: 'user', value: user })
  },
  updateListColumns({ commit }, { model, columns }) {
    commit('SET_LIST_COLUMNS', { model, columns })
  }
}
