import { getRandMockInt } from './helpers'

const FirstNamesArr = ['Плюха', 'Клюха', 'Блюха', 'Млюха', 'Злюха', 'Флюха']
const FathersNameArr = ['Плюхович', 'Клюхович', 'Блюхович', 'Млюхович', 'Злюхович', 'Флюхович']
const LastNameArr = ['Плюхин', 'Клюхин', 'Блюхин', 'Млюхин', 'Злюхин', 'Флюхин']
const SupplierNameArr = ['Рога', 'Копыта', 'Ножи', 'Перцовки', 'Стаканчики', 'ОМОН', 'Глабли', 'Цапли']

const planReserveManagerListQueryMock = () => {
  return {
    list: Array.from({ length: 11 }, (_, index) => ({
      id: index,
      fio: `${FirstNamesArr[getRandMockInt(FirstNamesArr.length - 1)]} ${
        FathersNameArr[getRandMockInt(FathersNameArr.length - 1)]
      } ${LastNameArr[getRandMockInt(LastNameArr.length - 1)]}`
    }))
  }
}

const planReserveSupplierListQueryMock = () => {
  return Array.from({ length: 11 }, (_, index) => ({
    supplier: {
      id: index * 10000,
      name: `${SupplierNameArr[getRandMockInt(SupplierNameArr.length - 1)]} и ${
        SupplierNameArr[getRandMockInt(SupplierNameArr.length - 1)]
      }`
    },
    manager: getRandMockInt(3) !== 1 ? planReserveManagerListQueryMock().list[index] : null,
    stats: {
      answeredItemsCount: getRandMockInt(99),
      nonAnsweredItemsCount: getRandMockInt(99)
    }
  }))
}

export const getPlanReserveManagerListQueryMock = () => {
  return {
    data: {
      planReserveManagerList: planReserveManagerListQueryMock()
    }
  }
}

export const getPlanReserveSupplierListQueryMock = () => {
  return {
    data: {
      planReserveSupplierList: {
        totalCount: 15,
        pagesCount: 2,
        list: planReserveSupplierListQueryMock()
      }
    }
  }
}
