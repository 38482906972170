export default function () {
  return {
    id: true,
    fileName: true,
    title: true,
    uploadedAt: true,
    url: true,
    type: true,
    external: true,
    status: true
  }
}
