<template>
  <div class="button-container">
    <div class="fixed-button" @click="openModal">
      <base-icon :icon="icon" />
    </div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'FixedButton',
  props: {
    icon: {
      type: String,
      default: 'play'
    }
  },
  setup(props, { emit }) {
    const openModal = () => {
      emit('open-modal')
    }

    return {
      openModal
    }
  }
})
</script>

<style lang="scss" scoped>
.button-container {
  position: relative;
}

.fixed-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 64px;
  font-size: 24px;
  transition: font-size 0.2s ease-in-out;
  transform: scale(1.0001);
  color: $white;
  &:hover {
    cursor: pointer;
    font-size: 28px;
    &:after {
      opacity: 0.9;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-size: contain;
    background-image: url('/nuxt/images/fixed-button-background.png');
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
  }
}
</style>
