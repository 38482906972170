import { computed, ref, useStore, useRoute } from '@nuxtjs/composition-api'

export default function () {
  const store = useStore()
  const isOpenedUserAgreementModal = ref(false)
  const route = useRoute()

  const isUserAgreementPage = computed(() => {
    return route.value.path.includes('/user-agreement')
  })

  isOpenedUserAgreementModal.value = store.state.userAgreementModal.isOpenedUserAgreementModal

  return {
    isOpenedUserAgreementModal,
    isUserAgreementPage
  }
}
