<template>
  <h1 class="page-header">
    <ContentLoader
      v-if="isLoading"
      :width="450"
      :height="40"
      :speed="1"
      :style="{ width: '450px', height: '40px;' }"
      primary-сolor="#eaeced"
      secondary-сolor="#ffffff"
    >
      <rect x="0" y="0" rx="1" ry="1" width="450" height="40" />
    </ContentLoader>
    <slot v-else-if="isLoadingSlot" name="loadingSlot" />
    <slot v-else />
  </h1>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isLoadingSlot: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.page-header {
  display: flex;
  align-items: center;
}
</style>
