<template>
  <button v-if="!isHidden" class="webim_button chat-button" @click.prevent="chatTriggerClick()">
    <base-icon icon="chat" :size="24" />
  </button>
</template>
<script>
import { mapGetters } from 'vuex'
import { fetchScript } from '@/plugins/helpers'
import { USER_ROLES } from '@/constants/user'

export default {
  name: 'ChatBot',
  data() {
    return {
      isHidden: true,
      isLoaded: false,
      isChatAutoShownToday: null
    }
  },
  computed: {
    ...mapGetters('user', ['isMatchedRole'])
  },
  async mounted() {
    if (this.isMatchedRole(USER_ROLES.supplier)) {
      this.isChatAutoShownToday = this.$cookies.get('isChatAutoShown')

      let visitor = this.$cookies.get('chatVisitor')

      if (!visitor) {
        visitor = await this.$api.chat.chatBot()

        this.$cookies.set('chatVisitor', visitor)
      }

      if (!window.webim) {
        window.webim = {
          accountName: 'vseinstrumentiru',
          domain: 'vseinstrumentiru.webim2.ru',
          location: 'lkp'
        }

        window.webim_visitor = visitor.data.chatBot
      }

      window.webimHandlers = {
        onInit: async () => {
          if (!this.isChatAutoShownToday) {
            window.webim.api.chat.start()
            await this.setShownCookie()
          }
        }
      }

      this.isHidden = false

      if (!window.webim?.api?.chat) {
        await fetchScript(`https://vseinstrumentiru.webim2.ru/js/button.js`)
      }
    }
  },
  methods: {
    chatTriggerClick() {
      window.webim?.api?.chat?.start()
    },
    setShownCookie() {
      const today = new Date()
      today.setHours(72, 0, 0, 0)

      this.$cookies.set('isChatAutoShown', true, {
        path: '/',
        expires: today
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-button {
  position: fixed;
  right: 24px;
  bottom: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: $white;
  background-color: $blue-50;
  transition: all 0.3s ease-in-out;
  z-index: 10;

  &:hover {
    background-color: $blue-150;
  }
}
</style>
