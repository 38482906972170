export const sendEdoId =
  ({ $axios }) =>
  id => {
    const query = {
      mutation: {
        sendEdoId: {
          __args: {
            edoId: id
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
