export const matrixCoordinations =
  ({ $axios }) =>
  (orderBy = {}, filter = {}, offset = 0, limit = 10) => {
    const query = {
      query: {
        matrixCoordinations: {
          __args: {
            orderBy,
            filter,
            limit,
            offset
          },
          id: true,
          createdAt: true,
          endDate: true,
          region: {
            name: true
          },
          status: {
            name: true,
            id: true
          },
          month: true,
          matrixCounts: {
            A: true,
            B: true,
            C: true,
            D: true,
            X: true,
            G: true
          }
        },
        matrixCoordinationsConnection: {
          __args: {
            filter
          },
          totalCount: true
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const matrixCoordinationsCurrentEndDate =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        matrixCoordinationsCurrentEndDate: true
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const regions =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        regions: {
          value: { __aliasFor: 'id' },
          text: { __aliasFor: 'name' }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const matrixCoordinationStatuses =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        matrixCoordinationStatuses: {
          value: { __aliasFor: 'id' },
          text: { __aliasFor: 'name' }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
