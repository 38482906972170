export const userPermissions = {
  matrixCoordinations: {
    canUploadExcel: 'frontend\\controllers\\MatrixCoordinationsController.UploadFromExcel',
    canDownloadExcel: 'frontend\\controllers\\MatrixCoordinationsController.DownloadToExcel',
    canUpdatePositions: 'frontend\\controllers\\MatrixCoordinationsController.Update',
    canSendToCoordinations: 'frontend\\controllers\\MatrixCoordinationsController.SendToCoordination',
    canViewCoordinations: 'frontend\\controllers\\MatrixCoordinationsController.View'
  }
}

export const USER_ROLES = {
  supplier: 'ROLE_SUPPLIER', // Поставщик.
  tempSupplier: 'ROLE_TEMPORARY_SUPPLIER', // Временный поставщик.
  reserveManager: 'ROLE_PLAN_RESERVE_MANAGER' // Менеджер плановых резервов
}

export const USER_TYPES = {
  manager: 'MANAGER',
  supplier: 'SUPPLIER'
}
