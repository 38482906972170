import { GRAPHQL_TYPE_ERROR_VALIDATION } from '@/constants'

export default function ({ $axios, $json2graphql, error }) {
  const nuxtError = error

  $axios.setBaseURL(window.PAS.ENV.API_URL)

  $axios.onRequest(config => {
    if (config.isFileUpload) {
      return config
    }

    if (config.url === '/graphql') {
      config.data.query = $json2graphql.jsonToGraphQLQuery(config.data.query)
    }
  })

  $axios.onResponseError(error => {
    if (error.response) {
      // При отмена запроса(cancelToken) response undefined

      if (
        error.response.status >= 400 &&
        error.response.status < 500 &&
        error.response.status !== 403 &&
        error.response.status !== 401
      ) {
        throw error.response.data
      }

      if (error.response.status) {
        nuxtError({
          statusCode: error.response.status
        })
      }
    }
  })

  $axios.onResponse(response => {
    if (response.data.errors) {
      throw response.data.errors
    }

    if (response.data.data) {
      const mutationName = Object.keys(response.data.data)[0]
      if (response.data.data[mutationName]?.validationErrors?.length) {
        const errorObj = {}
        const fieldErrors = {}
        response.data.data[mutationName].validationErrors.forEach(error => {
          fieldErrors[error.field] = error.errors
        })
        errorObj.extensions = {}
        errorObj.extensions.category = GRAPHQL_TYPE_ERROR_VALIDATION
        errorObj.extensions.fieldErrors = fieldErrors
        errorObj.data = response.data.data
        // eslint-disable-next-line no-throw-literal
        throw [errorObj]
      }
    }
  })
}
