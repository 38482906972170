export default function () {
  return {
    id: true,
    guid: true,
    jobId: true,
    supplierId: true,
    file: {
      downloadUrl: true,
      name: true,
      size: true
    },
    errorFile: {
      downloadUrl: true,
      name: true,
      size: true
    },
    photoUploadMode: true,
    type: true,
    status: true,
    createdAt: true,
    isExpired: true,
    columns: {
      __on: [
        {
          __typeName: 'UploadColumns',
          columns: {
            key: true,
            value: true
          },
          unknownColumns: true
        },
        {
          __typeName: 'UploadColumnsError',
          requiredColumns: true,
          duplicateColumns: {
            column: true,
            indexes: true
          },
          unknownColumns: true,
          commonErrors: true
        }
      ]
    }
  }
}
