<template>
  <div ref="vertical-modal" :class="['vertical-modal-backdrop', { '-prepared': isPrepared }]" @click="overlayClick">
    <div :class="['vertical-modal', { '-left': isLeft }]" :style="{ minWidth: width }">
      <base-button class="vertical-modal-close" no-paddings no-label-paddings variant="link" @click="close">
        <SvgIcon icon="cross" size="24" />
      </base-button>
      <slot />
    </div>
  </div>
</template>

<script>
import { SvgIcon } from '@vi-frontend/elements'

export default {
  name: 'VerticalModal',
  components: {
    SvgIcon
  },
  props: {
    disableOverlayClose: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '968px'
    }
  },
  data() {
    return {
      isPrepared: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.insertBody()
      setTimeout(() => {
        this.triggerAnimation()
      }, 0)
    })
  },
  beforeDestroy() {
    this.removeBody()
  },
  methods: {
    insertBody() {
      document.body.classList.add('no-scroll')
      document.body.appendChild(this.$refs['vertical-modal'])
    },
    removeBody() {
      document.body.classList.remove('no-scroll')
      this.$refs['vertical-modal'].remove()
    },
    overlayClick(event) {
      if (this.disableOverlayClose || !event.target.classList.contains('vertical-modal-backdrop')) return

      this.close()
    },
    triggerAnimation() {
      this.isPrepared = true
    },
    close() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: $white;
  min-width: 968px;
  padding: $vr-orange 24px $vr-fuchsia;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;

  &:not(.-left) {
    right: 0;
  }

  &.-left {
    left: 0;
  }
}

.vertical-modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  z-index: 100;
  max-width: 100%;

  &.-prepared {
    background-color: rgba($black, 0.5);
    .vertical-modal {
      transform: translateX(0);
    }
  }
}

.vertical-modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
}
</style>
