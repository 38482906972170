<template>
  <div class="file-uploader">
    <div v-if="innerFiles.length > 1" class="mb-2">
      <div v-for="(file, index) in innerFiles" :key="'file' + index" class="file-item-wrapper">
        <div class="file-item">
          <span class="name">
            {{ file.name }}
          </span>
          <AppSpinner
            v-if="file.isLoading"
            :size="14"
            :border-width="2"
            color="#7D7D7D"
            bg-color="#FFFFFF"
            class="icon-loader"
          />
          <base-icon v-else class="icon" icon="cross" @click.native="removeFile(index)" />
        </div>
      </div>
    </div>
    <div class="upload-block">
      <base-button
        color="secondary"
        size="small"
        class="mr-2 btn-upload"
        type="button"
        :disabled="maxFilesCount === innerFiles.length || isDisabled"
        @click="onClick"
      >
        <template #icon>
          <base-icon icon="clip" />
        </template>
        {{ title }}
      </base-button>
      <div v-if="innerFiles.length === 1" class="file-item mb-0">
        <span class="name">
          {{ innerFiles[0].name }}
        </span>
        <AppSpinner
          v-if="innerFiles[0].isLoading"
          :size="14"
          :border-width="2"
          color="#7D7D7D"
          bg-color="#FFFFFF"
          class="icon-loader"
        />
        <base-icon v-else class="icon" icon="cross" @click.native="removeFile(0)" />
      </div>
      <span v-if="innerFiles.length === 0" class="text"> Файл не выбран </span>
      <input
        ref="input"
        type="file"
        :multiple="maxFilesCount > 1"
        :accept="'.' + validFileTypes.join(',.')"
        @change="onChange"
      />
    </div>
    <template v-if="errors.length">
      <div v-for="(error, index) in errors" :key="index" class="error">
        {{ error }}
      </div>
    </template>
  </div>
</template>

<script>
import AppSpinner from '../ui/AppSpinner'
import { GRAPHQL_TYPE_ERROR_VALIDATION, defaultValidMimeTypes } from '@/constants'
import emitId from '@/pages/contract_registration/steps/use/useEmitId'

const MEGABYTE_TO_BYTE_CONVERSION_VALUE = 1048576
export default {
  name: 'FileUploader',
  components: {
    AppSpinner
  },
  props: {
    title: {
      type: String,
      default: 'Выбрать файл'
    },
    validFileTypes: {
      type: Array,
      default: () => {
        return defaultValidMimeTypes
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasClientValidation: {
      type: Boolean,
      default: true
    },
    isUploadImmediately: {
      type: Boolean,
      default: false
    },
    apiAddFile: {
      type: Object,
      default: () => {}
    },
    apiRemoveFile: {
      type: Object,
      default: () => {}
    },
    maxFilesCount: {
      type: Number,
      default: 1
    },
    maxFileSize: {
      type: Number,
      default: 50
    },
    uploadedFiles: {
      type: Array,
      default: () => []
    },
    validationErrors: {
      type: Array,
      default: () => []
    },
    parentError: {
      type: [String, Boolean],
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      errors: [],
      innerFiles: []
    }
  },
  watch: {
    uploadedFiles: {
      handler(value) {
        this.innerFiles = value
      },
      immediate: true
    },
    parentError: {
      handler(value) {
        if (value) {
          this.removeErrors()
          this.errors.push(value)
        }
        if (this.innerFiles.length && value === 'Добавлены не все документы на товарный знак.') {
          this.removeErrors()
        }
        if (value === 'Необходимо заполнить «Все страницы подписанного договора».') {
          this.removeErrors()
        }
      },
      immediate: true
    },
    innerFiles: {
      handler() {
        this.$emit('get-inner-files', this.innerFiles)
      },
      immediate: true
    },
    validationErrors: {
      handler(errors) {
        this.errors = errors
      },
      immediate: true
    }
  },
  methods: {
    onClick() {
      this.$refs.input.click()
    },
    removeErrors() {
      this.errors = []
    },
    removeFile(index) {
      if (this.isDisabled) return

      if (this.isUploadImmediately) {
        const fileId = this.innerFiles[index]?.id
        this.removeFileFromServer(fileId)
        return
      }
      this.innerFiles.splice(index, 1)
      this.$emit('remove-file')
    },
    getFileType(filetype) {
      return filetype.split('/')[1]
    },
    isFileExists(fileName) {
      return this.innerFiles.some(file => {
        return file.name === fileName
      })
    },
    onChange(e) {
      this.removeErrors()

      Array.from(e.target.files).forEach(file => {
        const convertedFileSize = this.maxFileSize * MEGABYTE_TO_BYTE_CONVERSION_VALUE

        if (file.size > convertedFileSize) {
          this.$emit('set-front-file-size-error', {
            uploadedFile: [`Некорректный размер файла. Допустимо: ${this.maxFileSize} МB.`]
          })
          return
        } else {
          this.$emit('set-front-file-size-error', { uploadedFile: [] })
        }

        if (this.isFileExists(file.name)) {
          return
        }

        if (this.innerFiles.length >= this.maxFilesCount) {
          return
        }

        const fileType = this.getFileType(file.type)

        if (this.isValidFileType(fileType) || !this.hasClientValidation) {
          if (this.isUploadImmediately) file.isNew = true
          const newFile = {}

          for (const key in file) {
            newFile[key] = file[key]
          }

          newFile.originalFile = file

          this.innerFiles.push(newFile)
          this.$emit('add-file', file)
        } else {
          this.$notify({
            type: 'error',
            title: `Доступные типы загрузки файлов: ${this.validFileTypes}`
          })
        }
      })

      this.$refs.input.value = ''

      if (this.isUploadImmediately) {
        this.uploadFile()
      }
    },
    isValidFileType(type) {
      return this.validFileTypes.includes(type)
    },
    async uploadFile() {
      this.isLoadingUpload = true
      this.removeErrors()
      const newFiles = this.innerFiles.filter(file => file.isNew).map(file => file.originalFile)
      const file = this.innerFiles.length > 1 ? newFiles : this.innerFiles[0].originalFile

      this.innerFiles
        .filter(file => file.isNew)
        .forEach(file => {
          this.$set(file, 'isLoading', true)
        })

      try {
        const response = await this.$api[this.apiAddFile.entity][this.apiAddFile.method](
          file,
          ...this.apiAddFile.methodParams
        )
        const files = response.data[this.apiAddFile.modelName]

        files.forEach(file => {
          const index = this.innerFiles.findIndex(innerFile => file.name === innerFile.name)
          this.innerFiles[index].id = file.id
          this.innerFiles[index].isNew = false
        })
        this.$emit('show-error', false)
      } catch (err) {
        const graphqlErrors = this.$getGraphqlErrors(err)

        if (graphqlErrors.detail) {
          Object.keys(graphqlErrors.detail).forEach(key => {
            emitId(key)
          })
        }

        if (graphqlErrors.type === GRAPHQL_TYPE_ERROR_VALIDATION) {
          Object.keys(graphqlErrors.detail).forEach(key => {
            if (graphqlErrors.detail?.contract) {
              this.removeErrors()
              this.$emit('show-error', ...graphqlErrors.detail[key])
            } else {
              this.errors.push(...graphqlErrors.detail[key])
            }
          })

          for (let i = this.innerFiles.length - 1; i >= 0; i--) {
            if (this.innerFiles[i].isNew) {
              this.innerFiles.splice(i, 1)
            }
          }
        }
      } finally {
        this.innerFiles.forEach(file => {
          this.$set(file, 'isLoading', false)
        })
      }
    },
    async removeFileFromServer(fileId) {
      const index = this.innerFiles.findIndex(file => {
        return file.id === fileId
      })
      this.innerFiles[index].isLoading = true

      try {
        await this.$api[this.apiRemoveFile.entity][this.apiRemoveFile.method](
          fileId,
          ...this.apiRemoveFile.methodParams
        )
        this.innerFiles.splice(index, 1)
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Не удалось удалить файл'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-uploader {
  .file-item-wrapper {
    display: flex;
    margin-bottom: $vr-orange;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .file-item {
    display: inline-flex;
    align-items: center;
    padding: $vr-yellow 12px;
    border: 2px solid $gray-70;
    border-radius: 61px;
    background-color: $white;
    max-width: 280px;
    @include normal-text;
    overflow: hidden;

    .name {
      flex: 1;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 280px;
    }

    .icon {
      margin-left: 12px;
      cursor: pointer;
      color: $gray-70;
      font-size: 16px;
      flex-shrink: 0;
    }

    .icon-loader {
      margin-left: 12px;
    }
  }

  .upload-block {
    display: flex;
    align-items: center;
  }

  .btn-upload {
    flex-shrink: 0;
  }

  .text {
    @include normal-text;
    color: $gray-70;
  }

  input[type='file'] {
    display: none;
  }

  .error {
    display: flex;
    @include small-text;
    color: $red-60;
    margin-top: $vr-brown;
    margin-bottom: $vr-brown;

    > .name {
      margin-left: 16px;
      margin-right: 8px;
    }
  }
}
</style>
