import getTaskStatus from '@/mixins/getTaskStatus'
import { DELAY } from '@/constants'

const getUploadJobStatus = async (context, jobId, data, isGetUploadIdOnly = false) => {
  try {
    const result = await getTaskStatus.call(context, parseInt(jobId, 10))

    if (result.status === 'process') {
      context.progress = result.message
      context.timer = setTimeout(() => {
        getUploadJobStatus(context, jobId, data, isGetUploadIdOnly)
      }, DELAY.server)
      return
    }

    context.isLoadingUpload = false
    context.file = null
    context.$emit('success', { result, data })
  } catch (err) {
    context.isLoadingUpload = false

    if (err.status === 'stopped') {
      context.$emit('stopped', { data, err })
      context.file = null
      return
    }

    context.$emit('error', { data, err })
    context.$notify({
      type: 'error',
      title: err.message || err.errorMessage || 'Возникли проблемы при получении статуса задачи'
    })
  }
}

export { getUploadJobStatus }
