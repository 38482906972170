<template>
  <ContentLoader
    height="260"
    :style="{ height: '260', width: '100%' }"
    :speed="1"
    primary-color="#eaeced"
    secondary-color="#FFFFFF"
    preserve-aspect-ratio="none"
  >
    <template v-for="(i, indexI) in 3">
      <rect
        v-for="(k, indexK) in 4"
        :key="indexI + '' + indexK"
        :x="calculatePositionX(indexK)"
        :y="calculatePositionY(i)"
        rx="2"
        ry="3"
        width="100%"
        height="12"
      />
    </template>
  </ContentLoader>
</template>

<script>
export default {
  name: 'AppTreeSuggestPreloader',
  count: 0,
  offsetX: 16,
  offsetY: 20,
  methods: {
    calculatePositionX(index) {
      return index * this.$options.offsetX
    },

    calculatePositionY(i) {
      const y = this.$options.count * this.$options.offsetY + (i - 1) * 12
      this.$options.count += 1
      return y
    }
  }
}
</script>
