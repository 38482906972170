export default function () {
  return {
    id: true,
    manufacturer: true,
    vendorCode: true,
    barcode: true,
    tnved: true,
    name: true,
    purchasePrice: true,
    rrcPrice: true,
    mwpPrice: true,
    unit: true,
    unitFactor: true,
    width: true,
    length: true,
    height: true,
    weight: true
  }
}
