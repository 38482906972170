import { EnumType, VariableType } from 'json-to-graphql-query'
import addFilesToQuery from '@/mixins/addFilesToQuery'
import defaultFileSchema from '@/schema/file'
import { defaultArrangementSchema, defaultDocumentExampleFileSchema } from '@/schema/arrangement'

const saveArrangementLegalEntity =
  ({ $axios }) =>
  ArrangementLegalEntityInput => {
    const query = {
      mutation: {
        saveArrangementLegalEntity: {
          __args: {
            input: {
              ...ArrangementLegalEntityInput
            }
          }
        }
      }
    }
    let formData = { query }
    let isFileUpload = false
    if (ArrangementLegalEntityInput.simplifiedTaxSystemFile) {
      isFileUpload = true
      const file = ArrangementLegalEntityInput.simplifiedTaxSystemFile.originalFile
      query.mutation.__variables = {}
      query.mutation.__variables.file = 'Upload!'
      query.mutation.saveArrangementLegalEntity.__args.input.simplifiedTaxSystemFile = {}
      query.mutation.saveArrangementLegalEntity.__args.input.simplifiedTaxSystemFile.file = new VariableType('file')
      formData = addFilesToQuery(file, query)
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload })
  }
const saveArrangementInformation =
  ({ $axios }) =>
  (ArrangementLegalEntityInput, isForward = true) => {
    const query = {
      mutation: {
        saveArrangementInformation: {
          __args: {
            input: ArrangementLegalEntityInput,
            forward: isForward
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const generateAgreement =
  ({ $axios }) =>
  () => {
    const query = {
      mutation: {
        arrangementGenerateAgreement: true
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const get =
  ({ $axios }) =>
  (fields = {}) => {
    const query = {
      query: {
        arrangement: {
          ...(Object.keys(fields).length ? fields : defaultArrangementSchema())
        }
      }
    }

    return $axios
      .$post(window.PAS.ENV.API_ENDPOINT, { query })
      .then(resolve => resolve)
      .catch(error => error)
  }

const addDocumentFiles =
  ({ $axios }) =>
  (uploadFiles = [], documentType, manufacturerName = '', fields = {}) => {
    const query = {
      mutation: {
        __variables: {
          file: '[Upload!]!'
        },
        saveArrangementDocumentsFile: {
          __args: {
            type: new EnumType(documentType),
            files: new VariableType('file'),
            manufacturerName
          },
          ...(Object.keys(fields).length ? fields : defaultFileSchema())
        }
      }
    }

    const formData = addFilesToQuery(uploadFiles, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

const arrangementGetViClarification =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        arrangementGetViClarification: {
          comment: true,
          files: defaultFileSchema()
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const removeDocumentFile =
  ({ $axios }) =>
  (fileId, documentType) => {
    const query = {
      mutation: {
        deleteArrangementDocumentsFile: {
          __args: {
            type: new EnumType(documentType),
            file: { fileId }
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const getDocumentExamples =
  ({ $axios }) =>
  (fields = {}) => {
    const query = {
      query: {
        arrangementDocumentExamples: {
          ...(Object.keys(fields).length ? fields : defaultDocumentExampleFileSchema())
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const arrangementAddSupplierClarificationComment =
  ({ $axios }) =>
  (uploadFiles = [], comment = '') => {
    const query = {
      mutation: {
        __variables: {
          file: '[Upload!]!'
        },
        arrangementAddSupplierClarificationComment: {
          __args: {
            files: new VariableType('file'),
            comment
          }
        }
      }
    }

    const formData = addFilesToQuery(uploadFiles, query)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

const register =
  ({ $axios }) =>
  () => {
    const query = {
      mutation: {
        registerArrangement: {
          currentStep: true,
          errors: {
            field: true,
            errors: true
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const findOrganizations =
  ({ $axios }) =>
  (inn = '') => {
    const query = {
      query: {
        findOrganizations: {
          __args: {
            inn: inn.toString()
          },
          name: true,
          inn: true,
          kpp: true,
          ogrn: true,
          okpo: true,
          okopf: true
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const searchBik =
  ({ $axios }) =>
  (search = '') => {
    const query = {
      query: {
        searchBik: {
          __args: {
            search: search.toString()
          },
          bik: true,
          name: true,
          accounts: true
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export {
  get,
  saveArrangementLegalEntity,
  saveArrangementInformation,
  generateAgreement,
  addDocumentFiles,
  removeDocumentFile,
  getDocumentExamples,
  register,
  arrangementAddSupplierClarificationComment,
  arrangementGetViClarification,
  searchBik,
  findOrganizations
}
