<template>
  <Autocomplete
    v-bind="$attrs"
    :search-results="searchResults"
    @search="search"
    @input="$emit('input', $event)"
    @option-click="$emit('select', $event)"
  >
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Autocomplete>
</template>

<script>
export default {
  name: 'AppAutocomplete',
  props: {
    apiMethod: {
      type: String,
      required: true
    },
    apiEntity: {
      type: String,
      default: ''
    },
    modelName: {
      type: String,
      required: true
    },
    apiMethodParams: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchResults: []
  }),
  methods: {
    async search(value) {
      this.$emit('search')
      const params = [value, ...this.apiMethodParams]
      try {
        const response = await this.$api[this.apiEntity][this.apiMethod](...params)
        this.searchResults = response.data[this.modelName]
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Ошибка при поиске',
          text: err.errorMessage || err.message || 'Не удалось найти нужную информацию'
        })
      }
    }
  }
}
</script>
