var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-spinner",style:({
    width: _vm.size + 'px',
    height: _vm.size + 'px',
    borderWidth: _vm.borderWidth + 'px',
    borderColor: _vm.color,
    borderBottomColor: _vm.bgColor,
    animationDuration: _vm.duration + 's'
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }