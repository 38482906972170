import defaultCategoriesSchema from '@/schema/categories'
import { fullCategorySchema, shortCategorySchema } from '@/schema/category'

const getQuery = (search, fields, forCurrentSupplier = null) => {
  return {
    query: {
      categoriesSearch: {
        __args: {
          query: search,
          forCurrentSupplier
        },
        ...(Object.keys(fields).length ? fields : defaultCategoriesSchema())
      }
    }
  }
}

const getCategoryQuery = (categoryId, fields) => {
  return {
    query: {
      category: {
        __args: {
          id: parseInt(categoryId)
        },
        ...(Object.keys(fields).length ? fields : fullCategorySchema())
      }
    }
  }
}

const getCurrentSupplierCategories = (categoryId, fields) => {
  return {
    query: {
      currentSupplierCategories: {
        ...(Object.keys(fields).length ? fields : shortCategorySchema())
      }
    }
  }
}

export const list =
  ({ $axios }) =>
  (search = '', fields = {}) => {
    const query = getQuery(search, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const supplierList =
  ({ $axios }) =>
  (search = '', fields = {}) => {
    const query = getQuery(search, fields, true)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const get =
  ({ $axios }) =>
  (categoryId, fields = {}) => {
    const query = getCategoryQuery(categoryId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const currentSupplierCategories =
  ({ $axios }) =>
  (categoryId, fields = {}) => {
    const query = getCurrentSupplierCategories(categoryId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
