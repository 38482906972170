import defaultCountriesSchema from '@/schema/countries'

const getQuery = (search, fields) => {
  return {
    query: {
      countries: {
        __args: {
          pattern: search
        },
        ...(Object.keys(fields).length ? fields : defaultCountriesSchema())
      }
    }
  }
}

export const list =
  ({ $axios }) =>
  (search = '', fields = {}) => {
    const query = getQuery(search, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
