export const state = () => ({
  currentVideo: null,
  tagId: 0,
  tagList: [],
  list: [],
  totalPages: 1,
  feedbackList: []
})

export const getters = {
  getCurrentVideo: state => state.currentVideo,

  getList: state => state.list,

  getTagList: state => state.tagList,

  getTagId: state => state.tagId,

  getTotalPages: state => state.totalPages,

  getVideoFeedbackValue: state => id => {
    const targetElement = state.feedbackList.filter(el => el.id === id)
    return targetElement.length ? targetElement[0].value : null
  }
}

export const mutations = {
  SET_CURRENT_VIDEO: (state, currentVideo) => {
    state.currentVideo = currentVideo
  },
  SET_LIST: (state, list) => {
    state.list = list
  },
  SET_TAG_LIST: (state, tagList) => {
    state.tagList = tagList
  },
  SET_TAG_ID: (state, tagId) => {
    state.tagId = tagId
  },
  SET_TOTAL_PAGES: (state, totalPages) => {
    state.totalPages = totalPages
  },
  UPDATE_FEEDBACK_LIST: (state, feedback) => {
    state.feedbackList.push(feedback)
  }
}

export const actions = {
  setCurrentVideo({ commit }, currentVideo) {
    commit('SET_CURRENT_VIDEO', currentVideo)
  },
  setList({ commit }, list) {
    commit('SET_LIST', list)
  },
  setTagList({ commit }, tagList) {
    commit('SET_TAG_LIST', tagList)
  },
  setTagId({ commit }, tagId) {
    commit('SET_TAG_ID', Number(tagId))
  },
  setTotalPages({ commit }, totalPages) {
    commit('SET_TOTAL_PAGES', totalPages)
  },
  updateFeedbackList({ commit }, feedback) {
    commit('UPDATE_FEEDBACK_LIST', feedback)
  }
}
