<template>
  <validationProvider v-slot="{ errors }" :rules="rules" :mode="mode" :vid="name" tag="div">
    <base-input :value="innerValue" v-bind="$attrs" :class="{ '-has-error': errors[0] }" v-on="$listeners" />
    <AppFieldErrorDetail v-if="errors[0]" :text="errors[0]" />
    <AppFieldNoteDetail v-if="!errors[0] && note" :text="note" />
  </validationProvider>
</template>

<script>
export default {
  name: 'BaseInputValidation',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    note: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value.toString()
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
