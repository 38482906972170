<template>
  <div class="error-container">
    <div class="error-container-header mb-2">
      {{ error.statusCode }}
    </div>
    <div class="error-container-message mb-1">
      {{ errorMessage }}
    </div>
    <nuxt-link v-if="linkUrl === '/'" to="/">
      {{ linkMessage }}
    </nuxt-link>
    <a v-else :href="linkUrl" class="btn btn-secondary">
      {{ linkMessage }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {
        return {
          statusCode: 'Ошибка',
          message: 'Что-то пошло не так...'
        }
      }
    }
  },
  head: {
    title: 'Ошибка'
  },

  computed: {
    errorMessage() {
      let message
      const errorStatus = parseInt(this.error.statusCode)

      if (this.error.message && !this.error.statusCode) {
        return this.error.message
      }

      switch (errorStatus) {
        case 404:
          message = 'Запрашиваемые данные не найдены'
          break
        case 403:
          message = 'У вас нет доступа к этим данным'
          break
        case 401:
          message = 'Вы не авторизованы'
          break
        case 500:
          message = 'Внутренняя ошибка сервера'
          break
        default:
          message = 'Что-то пошло не так...'
      }

      return message
    },

    linkMessage() {
      const errorStatus = parseInt(this.error.statusCode)
      return errorStatus === 401 ? 'Войти на сайт' : 'Вернуться на главную'
    },

    linkUrl() {
      const errorStatus = parseInt(this.error.statusCode)
      return errorStatus === 401 ? '/site/login' : '/'
    }
  }
}
</script>

<style lang="scss" scoped>
.error-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &-header {
    font-size: 72px;
    line-height: 72px;
    font-weight: 700;
    color: $gray-90;
  }

  &-message {
    text-align: center;
    color: $gray-90;
  }
}
</style>
