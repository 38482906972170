<template>
  <validationProvider v-slot="{ errors }" :rules="rules" :mode="mode">
    <base-select
      :value="innerValue"
      v-bind="$attrs"
      :class="{ '-has-error': errors[0] }"
      :options="options"
      v-on="$listeners"
      @change="onChange"
    />
    <AppFieldErrorDetail v-if="errors[0]" :text="errors[0]" />
    <AppFieldNoteDetail v-if="!errors[0] && note" :text="note" />
  </validationProvider>
</template>

<script>
export default {
  name: 'BaseSelectValidation',
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          text: '',
          value: ''
        }
      }
    },
    rules: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    note: {
      type: String,
      default: ''
    },
    options: {
      type: [Array, Object],
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
</script>
