<template>
  <base-button v-bind="$attrs" class="app-button" v-on="$listeners">
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </base-button>
</template>

<script>
export default {
  name: 'AppButton'
}
</script>

<style lang="scss">
.app-button {
  &.app-primary:not(.-disabled) {
    background-color: $blue-50 !important;

    &:hover {
      background-color: $blue-150 !important;
    }

    .ripple {
      background-color: $blue-10 !important;
    }
  }

  &.app-success:not(.-disabled) {
    background-color: $green-50 !important;

    &:hover {
      background-color: $green-80 !important;
    }

    .ripple {
      background-color: $green-3 !important;
    }
  }
}
</style>
