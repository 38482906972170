async function getQuery(entities) {
  const obj = { query: {} }
  let fields
  for (let i = 0; i < entities.length; i++) {
    const isEntityObject = typeof entities[i] === 'object' && entities[i] !== null

    if (isEntityObject) {
      if (Object.keys(entities[i]).length && entities[i]?.fields) {
        fields = entities[i]?.fields
        obj.query[entities[i].name] = fields
      } else {
        fields = await import('@/schema/' + entities[i].name)
        obj.query[entities[i].name] = fields.default()
      }
    } else {
      fields = await import('@/schema/' + entities[i])
      obj.query[entities[i]] = fields.default()
    }

    if (entities[i].arg) {
      const entityName = isEntityObject ? entities[i].name : entities[i]
      obj.query[entityName].__args = entities[i].arg
    }

    if (entities[i].isNeedAlias) {
      obj.query[entities[i].name + i] = {
        __aliasFor: entities[i].name,
        ...obj.query[entities[i].name]
      }

      delete obj.query[entities[i].name]
    }
  }

  return Promise.resolve(obj)
}

export default ({ $axios }) =>
  async entities => {
    const query = await getQuery(entities)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
