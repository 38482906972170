<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }" tag="div">
    <slot name="form" :server-validation="handleSubmit" />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'AppFormValidation',
  props: {
    errors: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    errors: {
      handler() {
        this.$refs.form.setErrors(this.errors)
      },
      deep: true
    }
  }
}
</script>
