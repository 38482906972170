<template>
  <div>
    <div v-if="showFakeLoader">
      <AppButton loading class="app-primary"> Файл загружается </AppButton>
    </div>
    <template v-else>
      <FileUploader
        class="mb-3"
        :title="title"
        :is-disabled="isLoadingUpload"
        :valid-file-types="validFileTypes"
        :has-client-validation="hasClientValidation"
        :validation-errors="errors"
        @add-file="onAddFile"
        @remove-file="onRemoveFile"
      />
      <AppButton v-if="file && !isLoadingUpload && !isImmediateUpload" class="app-success mb-3" @click="uploadFile">
        <template #icon>
          <base-icon icon="upload" :size="16" />
        </template>
        Загрузить файл
      </AppButton>
      <AppProgressBar v-if="isLoadingUpload" size="small" :progress="progress" />
    </template>
  </div>
</template>

<script>
import AppProgressBar from '@/components/ui/AppProgressBar'
import FileUploader from '@/components/buttons/FileUploader'
import getTaskStatus from '@/mixins/getTaskStatus'
import { defaultValidMimeTypes, DELAY, GRAPHQL_TYPE_ERROR_VALIDATION } from '@/constants'
import { getUploadJobStatus } from '@/components/others/use/useGetUploadJobStatus'

export default {
  name: 'AppFileUploader',
  components: {
    AppProgressBar,
    FileUploader
  },
  props: {
    title: {
      type: String,
      default: 'Выбрать файл'
    },
    validFileTypes: {
      type: Array,
      default: () => {
        return defaultValidMimeTypes
      }
    },
    apiEntity: {
      type: String,
      required: true
    },
    apiMethod: {
      type: String,
      required: true
    },
    apiMethodParams: {
      type: Array,
      default: () => []
    },
    modelName: {
      type: String,
      required: true
    },
    hasClientValidation: {
      type: Boolean,
      default: true
    },
    isMultiPageBehavior: {
      type: Boolean,
      default: false
    },
    isImmediateUpload: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoadingUpload: false,
    file: null,
    progress: 0,
    errors: []
  }),
  timer: null,
  computed: {
    showFakeLoader() {
      return this.isImmediateUpload && this.isLoadingUpload
    }
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    onAddFile(file) {
      this.file = file
      if (this.isImmediateUpload) {
        this.uploadFile()
      }
    },
    onRemoveFile() {
      this.file = null
    },
    async getJobStatus(jobId, data) {
      try {
        const result = await getTaskStatus.call(this, parseInt(jobId))

        if (result.status === 'process') {
          this.progress = result.message
          this.timer = setTimeout(() => {
            this.getJobStatus(jobId, data)
          }, DELAY.server)
          return
        }

        this.isLoadingUpload = false
        this.file = null
        this.$emit('success', { result, data })
      } catch (err) {
        this.isLoadingUpload = false

        if (err.status === 'stopped') {
          this.$emit('stopped', { data, err })
          this.file = null
          return
        }

        this.$emit('error', { data, err })
        this.$notify({
          type: 'error',
          title: err.message || err.errorMessage || 'Возникли проблемы при получении статуса задачи'
        })
      }
    },
    async uploadFile() {
      this.errors = []
      this.isLoadingUpload = true
      this.progress = 0
      this.$emit('start-upload')

      try {
        const params = [...this.apiMethodParams, this.file]
        const response = await this.$api[this.apiEntity][this.apiMethod](...params)

        // eslint-disable-next-line prefer-const
        let { jobId, uploadId, ...data } = response.data[this.modelName]

        if (uploadId && this.isMultiPageBehavior) {
          this.$emit('uploaded-to-cloud', uploadId)
          return false
        }

        if (!jobId) {
          jobId = response.data[this.modelName]
        }

        if (!jobId) {
          return
        }
        await getUploadJobStatus(this, jobId, data, true)
      } catch (err) {
        this.isLoadingUpload = false
        const graphqlErrors = this.$getGraphqlErrors(err)

        if (graphqlErrors.type === GRAPHQL_TYPE_ERROR_VALIDATION) {
          this.validationErrors = []
          Object.keys(graphqlErrors.detail).forEach(key => {
            this.validationErrors.push(graphqlErrors.detail[key])
          })
          this.errors.push(this.validationErrors.join('.'))
          return
        }

        this.$emit('error', err)
        this.errors.push(err.message || err.errorMessage || 'Возникли проблемы при создании задачи')
      }
    }
  }
}
</script>
