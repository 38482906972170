export const state = () => ({
  columnSettings: []
})

export const getters = {
  getColumnSettings(state) {
    return state.columnSettings
  }
}

export const mutations = {
  setColumnSettings: (state, columnSettings) => {
    state.columnSettings = columnSettings
  }
}

export const actions = {
  setColumnSettings({ commit }, columnSettings) {
    commit('setColumnSettings', columnSettings)
  }
}
