export const STATUS_NEW = 1
export const STATUS_COORDINATE_SUCCESS = 2
export const STATUS_CANCEL = 3
export const STATUS_ON_SUPPLIER_COORDINATION = 4

export const MATRIX_STATUS_REQUIRE_AGREE = 4
export const MATRIX_STATUS_AGREED = 2

export const TASK_STATUS_FINISHED = 3
export const TASK_STATUS_CANCELLED = 4
export const TASK_STATUS_INTERRUPTED = 5

export const SITE_URL_PROD = 'https://pas.vseinstrumenti.ru'

export const defaultValidMimeTypes = [
  'jpeg',
  'png',
  'pdf',
  'plain',
  'rtf',
  'doc',
  'msword',
  'docx',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
  'txt',
  'xls',
  'xlsx',
  'csv',
  'vnd.ms-excel',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const validFileMassUploader = [
  'xls',
  'xlsx',
  'csv',
  'vnd.ms-excel',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const propsForTechnicalSpecifications = {
  apiEntity: 'categories',
  apiMethod: 'list',
  modelName: 'categoriesSearch',
  note: 'Введите категорию или ее ID',
  errorText: 'Необходимо заполнить &quot;Категория&quot;'
}
export const FAST_FILTERS = {
  ALL: 'ALL',
  NOT_ON_SITE: 'NOT_ON_SITE',
  WITH_FILTERS: 'WITH_FILTERS'
}

export const IMAGE_PROCESS_STATUS = {
  ON_MODERATION: {
    value: 'ON_MODERATION',
    name: 'На модерации'
  },
  ON_DELETE: {
    value: 'ON_DELETE',
    name: 'Удаление'
  }
}

export const FILE_PROCESS_STATUS = {
  NEW: {
    value: 'NEW',
    name: 'Новая'
  },
  ON_MODERATION: {
    value: 'ON_MODERATION',
    name: 'На модерации'
  },
  ACCEPTED: {
    value: 'ACCEPTED',
    name: 'Утверждено'
  },
  ON_DELETION: {
    value: 'ON_DELETION',
    name: 'Удаление'
  }
}

export const FILE_TYPE = {
  OTHER: {
    value: 'OTHER',
    name: 'Разное'
  },
  GOODS_MANUAL: {
    value: 'GOODS_MANUAL',
    name: 'Инструкция к товару'
  },
  MOTOR_MANUAL: {
    value: 'MOTOR_MANUAL',
    name: 'Инструкция к двигателю'
  },
  TEXT_DESCRIPTION: {
    value: 'TEXT_DESCRIPTION',
    name: 'Файл для текстового описания'
  }
}

export const NOMENCLATURE_STATUS = {
  ACTIVE: {
    value: 'ACTIVE',
    name: 'Активен'
  },
  DELETED: {
    value: 'DELETED',
    name: 'Не активен'
  }
}

export const NOMENCLATURE_CONTENT_STATUS = {
  IN_BASE: {
    value: 'IN_BASE',
    name: 'В базе'
  },
  IN_WORK: {
    value: 'IN_WORK',
    name: 'В работе'
  },
  ON_SITE: {
    value: 'ON_SITE',
    name: 'На сайте'
  },
  NOT_ON_SITE: {
    value: 'NOT_ON_SITE',
    name: 'Нет на сайте'
  }
}

export const GRAPHQL_TYPE_ERROR_USER = 'User'
export const GRAPHQL_TYPE_ERROR_INTERNAL = 'internal'
export const GRAPHQL_TYPE_ERROR_VALIDATION = 'Validation'

export const TECH_PARAM_TYPE_INTEGER = 'TYPE_INTEGER'
export const TECH_PARAM_TYPE_DECIMAL = 'TYPE_DECIMAL'
export const TECH_PARAM_TYPE_BOOL = 'TYPE_BOOL'
export const TECH_PARAM_TYPE_STRING = 'TYPE_STRING'
export const TECH_PARAM_TYPE_SINGLE_LIST = 'TYPE_SINGLE_LIST'
export const TECH_PARAM_TYPE_RANGE = 'TYPE_RANGE'
export const TECH_PARAM_TYPE_COLOR = 'TYPE_COLOR'

export const TECH_PARAM_TYPE_REMOVABLE = 'TYPE_REMOVABLE'
export const TECH_PARAM_TYPE_ADDED = 'TYPE_ADDED'
export const TECH_PARAM_TYPE_UNCHANGING = 'TYPE_UNCHANGING'

export const RAZUKRASHKA_TYPE_NULL = 'NULL'
export const RAZUKRASHKA_TYPE_FILL = 'FILL'
export const RAZUKRASHKA_TYPE_NOT_FILL = 'NOT_FILL'
export const RAZUKRASHKA_TYPE_NO_SELECT = 'NO_SELECT'

export const NOMENCLATURE_COORDINATION_STATUS = {
  HAS_CHANGES: {
    value: 'HAS_CHANGES',
    text: 'Не отправлен на согласование'
  },
  NO_CHANGES: {
    value: 'NO_CHANGES',
    text: 'Без изменений'
  },
  ON_COORDINATION: {
    value: 'ON_COORDINATION',
    text: 'На согласовании'
  },
  BLOCKED: {
    value: 'BLOCKED',
    text: 'Не одобрен к заполнению'
  }
}

export const NOMENCLATURE_LIST_COLUMNS = {
  MANUFACTURER: {
    text: 'Производитель',
    value: 'MANUFACTURER'
  },
  NOMENCLATURE_NAME: {
    text: 'Название',
    value: 'NOMENCLATURE_NAME'
  },
  VENDOR_CODE: {
    text: 'Артикул',
    value: 'VENDOR_CODE'
  },
  NUMBER_RD: {
    text: 'Номер РД',
    value: 'NUMBER_RD'
  },
  LINK_RD: {
    text: 'Ссылка на РД',
    value: 'LINK_RD'
  },
  STATUS: {
    text: 'Статус',
    value: 'STATUS'
  },
  IS_ON_SITE: {
    text: 'На сайте',
    value: 'IS_ON_SITE'
  },
  IS_ON_SITE_DATE: {
    text: 'Дата загрузки на сайт',
    value: 'IS_ON_SITE_DATE'
  },
  PDM_CREATED_AT: {
    text: 'Дата загрузки в базу',
    value: 'PDM_CREATED_AT'
  },
  CATEGORY: {
    text: 'Рубрика',
    value: 'CATEGORY'
  },
  ACTIVITY: {
    text: 'Активность',
    value: 'ACTIVITY'
  },
  GUID: {
    text: 'GUID',
    value: 'GUID'
  },
  SKU: {
    text: 'SKU',
    value: 'SKU'
  },
  PURCHASE_PRICE: {
    text: 'Закупочная цена',
    value: 'PURCHASE_PRICE'
  },
  RRC_PRICE: {
    text: 'РРЦ',
    value: 'RRC_PRICE'
  },
  QUANTITY: {
    text: 'Наличие',
    value: 'QUANTITY'
  },
  TNVED: {
    text: 'ТН ВЭД',
    value: 'TNVED'
  },
  REQUIRED_FIELDS: {
    text: 'Обязательные поля',
    value: 'REQUIRED_FIELDS'
  },
  TECH_PARAMS_COUNT: {
    text: 'Количество ТХ',
    value: 'TECH_PARAMS_COUNT'
  },
  PHOTO_COUNT: {
    text: 'Количество фото',
    value: 'PHOTO_COUNT'
  },
  BARCODES: {
    text: 'Штрихкод',
    value: 'BARCODES'
  },
  MEASURE_UNIT: {
    text: 'Единица измерения',
    value: 'MEASURE_UNIT'
  },
  SITE_NAME: {
    text: 'Наименование на сайте',
    value: 'SITE_NAME'
  }
}

export const NOMENCLATURE_UPLOAD_STATUS = {
  EXPORTED: {
    type: 'EXPORTED',
    name: 'Загружен'
  },
  PROCESSING: {
    type: 'PROCESSING',
    name: 'В работе'
  },
  TEMPLATE_ERROR: {
    type: 'TEMPLATE_ERROR',
    name: 'Ошибка шаблона'
  },
  DATA_ERROR: {
    type: 'DATA_ERROR',
    name: 'Ошибка в заполнении'
  },
  ERROR: {
    type: 'ERROR',
    name: 'Ошибка'
  }
}

export const UPLOADS_TYPES = {
  NEW_NOMENCLATURE: {
    type: 'NEW_NOMENCLATURE',
    name: 'Загрузка новых товаров'
  },
  MASS_UPLOADING: {
    type: 'MASS_UPLOADING',
    name: 'Загрузка контента'
  },
  PHOTO_MASS_UPLOADING: {
    type: 'PHOTO_MASS_UPLOADING',
    name: 'Загрузка изображений'
  }
}

export const ARRANGEMENT_DOCUMENT_TYPES = {
  CONTRACT: {
    type: 'CONTRACT',
    name: 'Загрузка новых товаров'
  },
  INN: {
    type: 'INN',
    name: 'Свидетельство ИНН'
  },
  REQUISITES: {
    type: 'REQUISITES',
    name: 'Карточка с реквизитами'
  },
  OGRN: {
    type: 'OGRN',
    name: 'ОГРН'
  },
  ORDER_FOR_APPOINTMENT: {
    type: 'ORDER_FOR_APPOINTMENT',
    name: 'Приказ о назначении генерального директора'
  },
  PROTOCOL: {
    type: 'PROTOCOL',
    name: 'Актуальное решение/протокол об избрании генерального директора'
  },
  REGULATION: {
    type: 'REGULATION',
    name: 'Приказ о назначении генерального директора'
  },
  PRICELIST: {
    type: 'PRICELIST',
    name: 'Прайс-лист'
  },
  OGRNIP: {
    type: 'OGRNIP',
    name: 'ОГРНИП'
  },
  EGRIP: {
    type: 'EGRIP',
    name: 'ЕГРИП'
  },
  MANUFACTURERS: {
    type: 'MANUFACTURERS',
    name: ''
  },
  CERTIFICATES: {
    type: 'CERTIFICATES',
    name: 'Дополнительные документы'
  },
  ADDITIONAL_DOCUMENTS: {
    type: 'ADDITIONAL_DOCUMENTS',
    name: 'Дополнительные документы'
  }
}

export const CONTRACTOR_TYPES = {
  IP_TOTAL_TAX_SYSTEM: {
    type: 'IP_TOTAL_TAX_SYSTEM',
    name: 'ИП на общей системе налогообложения (ОСН)'
  },
  OOO_TOTAL_TAX_SYSTEM: {
    type: 'OOO_TOTAL_TAX_SYSTEM',
    name: 'ООО на общей системе налогообложения (ОСН)'
  },
  JOINT_STOCK_COMPANY: {
    type: 'JOINT_STOCK_COMPANY',
    name: 'АО'
  }
}

export const PHOTO_UPLOAD_MODES = {
  ADD_PHOTOS: {
    type: 'ADD_PHOTOS'
  },
  REPLACE_PHOTOS: {
    type: 'REPLACE_PHOTOS'
  }
}

export const NOT_EXIST_POSITION = -1

export const SPENDING_REPORT_TYPES = {
  DRAFT: {
    type: 'DRAFT',
    name: 'Черновик'
  },
  WAITING: {
    type: 'WAITING',
    name: 'В работе'
  },
  WAITING_PAYMENT: {
    type: 'WAITING_PAYMENT',
    name: 'Ожидает оплаты'
  },
  PAYED: {
    type: 'PAYED',
    name: 'Оплачен'
  },
  REFUSED: {
    type: 'REFUSED',
    name: 'Оплата отклонена'
  },
  APPROVED: {
    type: 'APPROVED',
    name: 'Оплата утверждена'
  },
  NOT_PAYED: {
    type: 'NOT_PAYED',
    name: 'Не оплачена'
  },
  CLOSED: {
    type: 'CLOSED',
    name: 'Закрыта'
  }
}

export const SPENDING_REJECT_REASONS = {
  AGREEMENT: {
    type: 'AGREEMENT',
    name: 'Договор'
  },
  LIMITS: {
    type: 'LIMITS',
    name: 'Лимиты'
  },
  OFFSET: {
    type: 'OFFSET',
    name: 'Взаимозачёт (используется при отклонении по взаиморасчёту)'
  },
  OTHER: {
    type: 'OTHER',
    name: 'Другое'
  }
}

export const MONTHS = [
  { text: 'Январь', value: 1 },
  { text: 'Февраль', value: 2 },
  { text: 'Март', value: 3 },
  { text: 'Апрель', value: 4 },
  { text: 'Май', value: 5 },
  { text: 'Июнь', value: 6 },
  { text: 'Июль', value: 7 },
  { text: 'Август', value: 8 },
  { text: 'Сентябрь', value: 9 },
  { text: 'Октябрь', value: 10 },
  { text: 'Ноябрь', value: 11 },
  { text: 'Декабрь', value: 12 }
]

export const ADMIN_PERMISSION = 'frontend\\modules\\admin\\controllers\\DefaultController.Index'

export const DELAY = {
  short: 300,
  server: 2000
}
