import { EnumType } from 'json-to-graphql-query'
import defaultSpendingReportsSchema from '@/schema/spendingReports.js'
import defaultRemainsReportsSchema from '@/schema/remainsReports.js'
import defaultRealizationsReportsSchema from '@/schema/realizationsReports.js'
import addFilters from '@/mixins/addFilters'

const getSpendingReportsQuery = (pageNumber, pageSize, fields, orderBy, filters) => {
  const query = {
    query: {
      spendingReports: {
        __args: {
          page: pageNumber,
          perPage: pageSize,
          orderBy: Object.keys(orderBy).length ? orderBy : {}
        },
        ...(Object.keys(fields).length ? fields : defaultSpendingReportsSchema())
      }
    }
  }

  addFilters('spendingReports', filters, query)

  return query
}

const getRemainsReportsQuery = (pageNumber, pageSize, fields, orderBy) => {
  return {
    query: {
      remainsReports: {
        __args: {
          page: pageNumber,
          perPage: pageSize,
          orderBy: Object.keys(orderBy).length ? orderBy : {}
        },
        ...(Object.keys(fields).length ? fields : defaultRemainsReportsSchema())
      }
    }
  }
}

const getRealizationsReportsQuery = (pageNumber, pageSize, fields, orderBy, filters) => {
  const query = {
    query: {
      realizationReports: {
        __args: {
          page: pageNumber,
          perPage: pageSize,
          orderBy: Object.keys(orderBy).length ? orderBy : {}
        },
        ...(Object.keys(fields).length ? fields : defaultRealizationsReportsSchema())
      }
    }
  }

  addFilters('realizationReports', filters, query)

  return query
}
export const mvpReports =
  ({ $axios }) =>
  (type = '', feedback = '') => {
    const mutation = {
      mutation: {
        mvpReports: {
          __args: {
            type: new EnumType(type),
            feedback
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query: mutation })
  }

export const reportAnalytics =
  ({ $axios }) =>
  () => {
    const mutation = {
      mutation: {
        reportAnalytics
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query: mutation })
  }

export const spending =
  ({ $axios, $json2graphql }) =>
  (pageNumber = 1, pageSize = 10, orderBy = {}, fields = {}, filters = {}) => {
    if (Object.keys(orderBy).length) {
      const field = Object.keys(orderBy)[0]
      orderBy[field] = new $json2graphql.EnumType(orderBy[field])
    }

    const query = getSpendingReportsQuery(pageNumber, pageSize, fields, orderBy, filters)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const remains =
  ({ $axios, $json2graphql }) =>
  (pageNumber = 1, pageSize = 10, orderBy = {}, fields = {}) => {
    if (Object.keys(orderBy).length) {
      const field = Object.keys(orderBy)[0]
      orderBy[field] = new $json2graphql.EnumType(orderBy[field])
    }

    const query = getRemainsReportsQuery(pageNumber, pageSize, fields, orderBy)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const realizations =
  ({ $axios, $json2graphql }) =>
  (pageNumber = 1, pageSize = 10, orderBy = {}, fields = {}, filters = {}) => {
    if (Object.keys(orderBy).length) {
      const field = Object.keys(orderBy)[0]
      orderBy[field] = new $json2graphql.EnumType(orderBy[field])
    }

    const query = getRealizationsReportsQuery(pageNumber, pageSize, fields, orderBy, filters)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const nextReportsDate =
  ({ $axios }) =>
  () => {
    const query = {
      query: {
        nextReportsDate: true
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
