<template>
  <div class="text-editor">
    <ckeditor-nuxt
      ref="editor"
      v-bind="$attrs"
      :config="editorConfig"
      :value="value"
      v-on="$listeners"
      @input="onTextChange"
    />
  </div>
</template>

<script>
import ckeditorNuxt from '@blowstack/ckeditor-nuxt'

export default {
  name: 'AppTextEditor',
  components: {
    ckeditorNuxt
  },
  props: {
    isCountCharacters: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isTextChanged: false,
      activeTextEditorName: ''
    }
  },
  computed: {
    editorConfig() {
      const defaultConfig = {
        removePlugins: ['Title', 'ListStyle', 'MediaEmbedToolbar', 'MathType']
      }

      if (this.isCountCharacters) {
        Object.assign(defaultConfig, {
          wordCount: {
            onUpdate: stats => {
              this.$emit('count-characters', stats.characters)
            }
          }
        })
      }

      return Object.assign(defaultConfig, this.$attrs.config)
    }
  },
  mounted() {
    document.body.addEventListener('click', this.onClickBody)
    this.$refs.editor.$children[0].$on('blur', () => {
      this.$listeners.input(this.removeSpacesAndTags(this.value))
    })
  },
  destroyed() {
    document.body.removeEventListener('click', this.onClickBody)
  },
  methods: {
    onTextChange() {
      this.isTextChanged = true
      this.activeTextEditorName = this.$refs.editor.$el.getAttribute('data-name')
    },
    removeSpacesAndTags(event) {
      return event.replaceAll(/\s {1}|(&nbsp;){1}/g, '').replaceAll(/<p[^>]*>(\s|&nbsp;|<br\s?\/?>)*<\/p>/g, '')
    },
    onClickBody(e) {
      if (!this.isTextChanged) return

      const textEditor = e.target.closest('.text-editor')

      if (textEditor) {
        const isThisTextEditor = textEditor.getAttribute('data-name') === this.activeTextEditorName
        if (isThisTextEditor) {
          return
        }
      }

      this.$emit('change')
      this.isTextChanged = false
    }
  }
}
</script>

<style lang="scss">
.text-editor {
  /* stylelint-disable-next-line*/
  .ck-editor__editable {
    height: 300px;

    &.ck-focused {
      border-color: $gray-90 !important;
    }

    p,
    ul {
      color: $gray-90;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  .ck.ck-button .ck.ck-tooltip {
    display: none;
  }

  /* stylelint-disable-next-line*/
  .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    z-index: 99;
  }
}
</style>
