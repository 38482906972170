<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Observer',
  props: {
    root: {
      type: Object,
      default: null
    },
    rootMargin: {
      type: String,
      default: '0px'
    },
    threshold: {
      type: Array,
      default: () => [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 0]
    }
  },
  data() {
    return {
      observer: null,
      intersectionCount: 0
    }
  },
  mounted() {
    this.createObserver()
  },
  destroyed() {
    this.observer?.disconnect()
  },
  methods: {
    createObserver() {
      const options = {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold
      }

      setTimeout(() => {
        this.observer = new IntersectionObserver(this.onIntersecting, options)
        this.observer.observe(this.$el)
      })
    },
    onIntersecting([entry]) {
      if (entry.intersectionRatio > 0) {
        this.$emit('isIntersecting', true)
        this.intersectionCount++
      } else if (this.intersectionCount > 0) {
        this.$emit('isIntersecting', false)
      }
    }
  }
}
</script>
