import defaultManufacturesSchema from '@/schema/manufacturers'

const getQuery = (search, fields, limit, offset) => {
  return {
    query: {
      manufacturers: {
        __args: {
          pattern: search,
          offset,
          limit
        },
        ...(Object.keys(fields).length ? fields : defaultManufacturesSchema())
      }
    }
  }
}

const getSupplierManufacturersQuery = (search, nomenclatureID, fields) => {
  return {
    query: {
      currentSupplierManufacturers: {
        __args: {
          supplierNomenclatureId: nomenclatureID,
          pattern: search
        },
        ...(Object.keys(fields).length ? fields : defaultManufacturesSchema())
      }
    }
  }
}

const getManufactureQuery = (manufactureId, fields) => {
  return {
    query: {
      manufacturer: {
        __args: {
          id: manufactureId
        },
        ...(Object.keys(fields).length ? fields : defaultManufacturesSchema())
      }
    }
  }
}

export const list =
  ({ $axios }) =>
  (search = '', fields = {}, limit = 10, offset = 0) => {
    const query = getQuery(search, fields, limit, offset)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const current =
  ({ $axios }) =>
  (search = '', nomenclatureID = 0, fields = {}) => {
    const query = getSupplierManufacturersQuery(search, nomenclatureID, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const get =
  ({ $axios }) =>
  (manufactureId, fields = {}) => {
    const query = getManufactureQuery(manufactureId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
