<template>
  <FixedButton v-if="video" @open-modal="toggleModal(true)">
    <DarkPopup v-if="video.hint && !isHintHidden" :content="video.hint" @close="hideHint" />
    <VideoModal
      v-if="isOpen"
      :id="video.id"
      :url="video.videoId"
      :is-youtube="video.isYoutube"
      :active-button="video.activeButton"
      @close-modal="toggleModal(false)"
    />
  </FixedButton>
</template>

<script>
import { computed, defineComponent, useStore, useContext, watch, ref } from '@nuxtjs/composition-api'
import VideoModal from '@/components/modals/VideoModal'
import DarkPopup from '@/components/buttons/blocks/DarkPopup'
import FixedButton from '@/components/buttons/FixedButton'

export default defineComponent({
  name: 'FixedVideoButton',
  components: {
    FixedButton,
    VideoModal,
    DarkPopup
  },
  setup() {
    const isOpen = ref(false)

    const store = useStore()
    const video = computed(() => store.getters['videos/getCurrentVideo'])

    const { $api } = useContext()
    const isHintHidden = ref(false)
    const hideHint = async () => {
      await $api.videoInstructions.hideVideoInstructionHint()
      isHintHidden.value = true
    }

    watch(isOpen, () => {
      if (isOpen.value) {
        hideHint()
      }
    })

    const toggleModal = state => {
      isOpen.value = state
    }

    return {
      isOpen,
      video,
      isHintHidden,
      hideHint,
      toggleModal
    }
  }
})
</script>

<style lang="scss" scoped>
.button-container {
  position: relative;
}

.fixed-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 64px;
  font-size: 24px;
  transition: font-size 0.2s ease-in-out;
  transform: scale(1.0001);
  color: $white;
  &:hover {
    cursor: pointer;
    font-size: 28px;
    &:after {
      opacity: 0.9;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-size: contain;
    background-image: url('/nuxt/images/fixed-button-background.png');
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
  }
}
</style>
