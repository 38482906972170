export default function ({ store, route, error }) {
  if (!route.meta || !route?.meta[0]?.permissions) {
    return
  }

  if (!store.getters['user/hasOnePermission'](route.meta[0].permissions)) {
    error({
      statusCode: 403
    })
  }
}
