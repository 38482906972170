export default async function ({ $api, store, $sentry }) {
  const hasHints = store.state.hints.length > 0

  if (hasHints) return

  try {
    const response = await $api.hints.list()
    store.dispatch('hints/setHints', response.data.guiHelpItems)
  } catch (err) {
    $sentry.captureMessage((err.errorMessage || err.message) + '. Не удалось получить подсказки для пользователя')
  }
}
