import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import isYesterday from 'dayjs/plugin/isYesterday'
import isToday from 'dayjs/plugin/isToday'

require('dayjs/locale/ru')

dayjs.locale('ru')
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(isYesterday)
dayjs.extend(isToday)

export default (context, inject) => {
  inject('dayjs', dayjs)
}
