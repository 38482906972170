export default async function ({ $api, route, store, error }) {
  if (route.fullPath === '/') {
    store.dispatch('videos/setCurrentVideo', null)
  } else {
    try {
      const response = await $api.videoInstructions.getPageVideoInstruction('/' + route.path.slice(1))
      if (response.data.pageVideoInstruction) {
        store.dispatch('videos/setCurrentVideo', {
          ...response.data.pageVideoInstruction.videoInstruction,
          hint: response.data.pageVideoInstruction.hint
        })
      } else {
        store.dispatch('videos/setCurrentVideo', null)
      }
    } catch (err) {
      return error({ statusCode: err?.response?.status })
    }
  }
}
