import cast from '@/mixins/cast'

export default function (model, filters, query, type = 'query') {
  if (Object.keys(filters).length) {
    if (!query[type][model].__args) {
      if (typeof query[type][model] !== 'object') {
        query[type][model] = {}
      }
      query[type][model].__args = {}
    }
    cast(filters, model)
    query[type][model].__args.filter = filters
  }
}
