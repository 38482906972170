let counter = 0

function defaultSchema() {
  counter += 1
  if (counter === 10) {
    counter = 0
    return {
      id: true,
      pdmId: true,
      parentId: true,
      label: { __aliasFor: 'name' },
      level: true
    }
  } else {
    return {
      id: true,
      pdmId: true,
      parentId: true,
      label: { __aliasFor: 'name' },
      level: true,
      children: defaultSchema()
    }
  }
}

export default defaultSchema
