<template>
  <footer class="app-footer">
    <div class="copyright">© Личный кабинет поставщика {{ currentYear }}</div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',

  computed: {
    currentYear() {
      return this.$dayjs().year()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  padding: 18px 30px;
  background-color: $gray-10;

  .copyright {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $gray-60;
  }
}
</style>
