import defaultNomenclatureFilesSchema from '@/schema/nomenclatureFiles'

const getQuery = (nomenclatureId, fields) => {
  return {
    query: {
      nomenclatureFiles: {
        __args: {
          supplierNomenclatureId: nomenclatureId
        },
        ...(Object.keys(fields).length ? fields : defaultNomenclatureFilesSchema())
      }
    }
  }
}

const list =
  ({ $axios }) =>
  (nomenclatureId, fields = {}) => {
    const query = getQuery(nomenclatureId, fields)
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const cancelRemoveFile =
  ({ $axios }) =>
  (nomenclatureId, fileId) => {
    const query = {
      mutation: {
        cancelDeleteNomenclatureFile: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            identifier: fileId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const removeFile =
  ({ $axios }) =>
  (nomenclatureId, fileId) => {
    const query = {
      mutation: {
        deleteNomenclatureFile: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            identifier: fileId
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

const addFile =
  ({ $axios, $json2graphql }) =>
  (nomenclatureId, uploadedFile, title, fileType) => {
    const formData = new FormData()

    const query = {
      mutation: {
        __variables: {
          file: 'Upload!'
        },
        createNomenclatureFile: {
          __args: {
            supplierNomenclatureId: nomenclatureId,
            uploadedFile: new $json2graphql.VariableType('file'),
            title,
            fileType: new $json2graphql.EnumType(fileType)
          },
          id: true,
          fileName: true,
          uploadedAt: true,
          title: true,
          url: true,
          type: true,
          status: true,
          external: true
        }
      }
    }

    const operations = $json2graphql.jsonToGraphQLQuery(query)
    const newOperations = JSON.stringify(operations)
    const map = `{"0": ["variables.file"]}`

    formData.append('operations', '{ "query": ' + newOperations + ', "variables": { "file": null }}')
    formData.append('map', map)
    formData.append('0', uploadedFile)

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, formData, { isFileUpload: true })
  }

export { list, cancelRemoveFile, removeFile, addFile }
