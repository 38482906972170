export const state = () => ({
  arrangement: {},
  documentList: []
})

export const getters = {
  getCurrentStep(state) {
    return state.arrangement?.currentStep || 1
  },
  getDocumentList(state) {
    return state.documentList || []
  },
  getContractorType(state) {
    return state.arrangement?.contractorType
  }
}

export const mutations = {
  SET_ARRANGEMENT: (state, arrangement) => {
    state.arrangement = arrangement
  },
  SET_DOCUMENT_TO_LIST: (state, documentList) => {
    const documentsKeyArr = []
    state.documentList = [...state.documentList, ...documentList].reverse().filter(document => {
      if (!documentsKeyArr.includes(document.id)) {
        documentsKeyArr.push(document.id)
        return document
      }
      return null
    })
  }
}

export const actions = {
  setArrangement({ commit }, arrangement) {
    commit('SET_ARRANGEMENT', arrangement)
  },
  setDocumentToList({ commit }, documents) {
    commit('SET_DOCUMENT_TO_LIST', documents)
  }
}
