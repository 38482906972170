import { EnumType } from 'json-to-graphql-query'

export default function (model, orderBy, query, type = 'query') {
  if (orderBy) {
    let field = orderBy
    const isDesc = field.includes('-')
    field = isDesc ? field.slice(1) : field

    query[type][model].__args.orderBy = {
      [field]: isDesc ? new EnumType('DESC') : new EnumType('ASC')
    }
  }
}
