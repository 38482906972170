<template>
  <div class="grid-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppRow'
}
</script>
