import { EnumType } from 'json-to-graphql-query'

function transformFilterObject(filter) {
  let newFilterString = ''
  Object.entries(filter).forEach((filterField, fieldIndex) => {
    const [filterFieldName, filterFieldValue] = filterField
    newFilterString += filterFieldValue ? filterFieldName : `-${filterFieldName}`
    if (Object.keys(filter).length !== fieldIndex + 1) newFilterString += '..'
  })
  return newFilterString
}

function transformToCleanUrl(queryObject) {
  let urlString = ''
  const { filters, orderBy } = queryObject
  if (orderBy) {
    const [orderName, orderValue] = Object.entries(orderBy)[0]
    urlString += '&orderBy=' + (orderValue.value === 'ASC' ? orderName : `-${orderName}`)
  }
  if (filters) {
    urlString += '&filters='
    Object.keys(filters).forEach((filterName, index) => {
      const filter = filters[filterName]
      let filterString = filterName + '~'
      switch (typeof filter) {
        case 'object':
          filterString += transformFilterObject(filter)
          break
        default:
          filterString += filter
      }
      urlString += filterString
      if (Object.keys(filters).length !== index + 1) urlString += '_'
    })
  }

  return urlString
}

function transformFromCleanUrl(query = {}) {
  if (!Object.keys(query).length) return false

  const queryObject = {}
  Object.entries(query).forEach(filterType => {
    const [type, value] = filterType
    switch (type) {
      case 'orderBy':
        if (value) {
          const isNegativeValue = value[0] === '-'
          const key = isNegativeValue ? value.slice(1) : value
          queryObject.orderBy = {
            [key]: isNegativeValue ? new EnumType('DESC') : new EnumType('ASC')
          }
        }
        break
      case 'filters': {
        queryObject.filters = parseFilter(value)
        break
      }
      case 'massUploadingId': {
        if (!queryObject.filters) queryObject.filters = {}
        queryObject.filters.massUploadingId = value
        break
      }
      case 'pageNumber': {
        queryObject.pageNumber = Number(value)
        break
      }
    }
  })
  return queryObject
}

function parseFilter(filter) {
  const parsedFilter = {}
  const filtersArray = filter.split('_')
  filtersArray.forEach(stringifiedFilter => {
    const [field, value] = stringifiedFilter.split('~')
    if (field?.search('Filter') !== -1) {
      const valueArray = value.split('..').map(element => {
        return element[0] !== '-' ? { [element]: true } : { [element.slice(1)]: false }
      })
      valueArray.forEach(arrayValue => {
        parsedFilter[field] = { ...parsedFilter[field], ...arrayValue }
      })
    } else {
      parsedFilter[field] = value
    }
  })
  return parsedFilter
}

export { transformFromCleanUrl, transformToCleanUrl }
