<template>
  <div class="star-list">
    <base-icon
      v-for="n in count"
      :key="n"
      icon="star"
      :size="size"
      :class="['star', { '-active': n <= value }]"
      :style="getStyles(n)"
      @click.native="onClick(n)"
    />
  </div>
</template>

<script>
export default {
  name: 'AppStarRating',
  props: {
    count: {
      type: Number,
      default: 5
    },
    value: {
      type: Number,
      default: 0
    },
    offset: {
      type: Number,
      default: 5
    },
    color: {
      type: String,
      default: 'black'
    },
    activeColor: {
      type: String,
      default: 'yellow'
    },
    size: {
      type: Number,
      default: 20
    }
  },
  methods: {
    onClick(number) {
      this.$emit('input', number)
    },
    getStyles(startNumber) {
      return {
        'margin-right': (startNumber !== this.count ? this.offset : 0) + 'px',
        '--color': this.color,
        '--active-color': this.activeColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.star-list {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.star {
  color: var(--color);

  &:hover {
    opacity: 0.6;
  }

  &.-active {
    color: var(--active-color);
  }
}
</style>
