export default function () {
  return {
    id: true,
    guid: true,
    wtisId: true,
    siteId: true,
    contentStatusName: true,
    contentStatus: true,
    pdmCreatedAt: true,
    isOnSiteDate: true,
    barcodes: {
      id: true,
      barcode: true
    },
    barcodesFromChanges: true,
    classifierUnit: {
      id: true,
      name: true
    },
    vendorCode: true,
    quantity: true,
    purchasePrice: true,
    rrcPrice: true,
    name: true,
    manufacturer: {
      id: true,
      name: true
    },
    status: true,
    replacementNomenclature: {
      id: true,
      name: true
    },
    isDiscontinued: true,
    tnved: {
      id: true,
      guid: true,
      code: true,
      name: true
    },
    coordinationStatus: true,
    coordinationsLinks: {
      coordinationId: true,
      url: true
    },
    category: {
      id: true,
      name: true,
      pdmId: true
    },
    razukrashkaType: true,
    factor: true,
    weight: true,
    length: true,
    width: true,
    height: true,
    photoCount: true,
    requiredFieldsCount: true,
    filledRequiredFieldsCount: true
  }
}
