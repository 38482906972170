import { marketingNomenclatureSchema, marketingPromotionSchema } from '@/schema/marketingNomenclature'

export const marketingList =
  ({ $axios }) =>
  (page = 1, perPage = 20, filter = {}, orderBy = {}) => {
    const query = {
      query: {
        marketingList: {
          __args: {
            page,
            perPage,
            filter,
            orderBy
          },
          info: {
            totalCount: true,
            totalGoods: true,
            finishedPromotion: true,
            waitingPromotion: true,
            createdPromotion: true,
            plannedPromotion: true,
            activePromotion: true
          },
          marketingList: {
            status: true,
            startDate: true,
            endDate: true,
            id: true,
            name: true,
            amount: true,
            createdAt: true,
            updatedAt: true,
            report: {
              downloadUrl: true
            },
            isPaid: true
          }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingPromotionCreate =
  ({ $axios }) =>
  id => {
    const query = {
      mutation: {
        marketingPromotionCreate: {
          id: true,
          ...marketingPromotionSchema()
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingPromotionCopy =
  ({ $axios }) =>
  id => {
    const query = {
      mutation: {
        marketingPromotionCopy: {
          __args: {
            promotionId: id
          }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingPromotionCancel =
  ({ $axios }) =>
  id => {
    const query = {
      mutation: {
        marketingPromotionCancel: {
          __args: {
            promotionId: id
          }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingCategory =
  ({ $axios }) =>
  id => {
    const query = {
      query: {
        marketingCategory: {
          __args: {
            id
          }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingPromotionInformer =
  ({ $axios }) =>
  id => {
    const query = {
      query: {
        marketingPromotionInformer: {
          __args: {
            promotionId: id
          },
          totalNomenclaturesCount: true,
          categories: {
            count: true,
            maxCount: true,
            hasErrors: true,
            name: true,
            guid: true,
            manufacturers: {
              count: true,
              maxCount: true,
              hasErrors: true,
              name: true,
              guid: true
            }
          }
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const planned =
  ({ $axios }) =>
  id => {
    const query = {
      query: {
        planned: {
          __args: {
            id
          },
          quantity: true,
          items: true
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingNomenclature =
  ({ $axios }) =>
  (id, page = 1, perPage = 10, orderBy = {}) => {
    const query = {
      query: {
        marketingNomenclature: {
          __args: {
            promotionId: id,
            page,
            perPage,
            orderBy
          },
          ...marketingNomenclatureSchema()
        }
      }
    }
    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingNomenclatureCategory =
  ({ $axios }) =>
  nomenclatureGuidList => {
    const query = {
      query: {
        marketingNomenclatureCategory: {
          __args: {
            nomenclatureGuidList
          },
          nomenclatureCategoriesList: true
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingNomenclatureDelete =
  ({ $axios }) =>
  (id, nomenclatureGuid) => {
    const query = {
      mutation: {
        marketingNomenclatureDelete: {
          __args: {
            promotionId: id,
            nomenclatureGuid
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingNomenclatureCategoryConfirm =
  ({ $axios }) =>
  (id, categoryConfirmInput) => {
    const query = {
      mutation: {
        marketingNomenclatureCategoryConfirm: {
          __args: {
            promotionId: id,
            categoryConfirmInput
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingPromotionChangeNameDate =
  ({ $axios }) =>
  (id, name = null, startDate = null, endDate = null) => {
    const query = {
      mutation: {
        marketingPromotionChangeNameDate: {
          __args: {
            promotionId: id,
            name,
            startDate,
            endDate
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const addMarketingNomenclatureList =
  ({ $axios }) =>
  (id, guidList = '') => {
    const query = {
      mutation: {
        marketingNomenclatureListAdd: {
          __args: {
            promotionId: id,
            nomenclatureGuids: guidList
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const plannedPromotion =
  ({ $axios }) =>
  id => {
    const query = {
      mutation: {
        plannedPromotion: {
          __args: {
            promotionId: id
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }

export const marketingPromotionClearSelection =
  ({ $axios }) =>
  id => {
    const query = {
      mutation: {
        marketingPromotionClearSelection: {
          __args: {
            promotionId: id
          }
        }
      }
    }

    return $axios.$post(window.PAS.ENV.API_ENDPOINT, { query })
  }
