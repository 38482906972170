<template>
  <AppButton class="app-primary helper" tag-name="a" size="small" :href="'/help/pages' + query" @click="onClick">
    <base-icon class="icon" icon="question-circle" />
    Помощь
  </AppButton>
</template>
<script>
export default {
  name: 'AppHelper',
  data() {
    return {
      urls: [],
      query: ''
    }
  },

  async fetch() {
    const query = {
      query: {
        helpSystemPages: {
          id: true,
          route: {
            source: true
          }
        }
      }
    }

    try {
      const response = await this.$axios.$post('/graphql', { query })
      this.urls = response.data.helpSystemPages.map(item => {
        return {
          helpPageId: item.id,
          sourcePageUrl: item.route?.source
        }
      })

      this.setHelperUrl()
    } catch (err) {
      this.$sentry.captureException(err)
    }
  },
  watch: {
    '$route.path': {
      handler: 'setHelperUrl'
    }
  },

  methods: {
    setHelperUrl() {
      const index = this.urls.findIndex(item => {
        return item.sourcePageUrl === this.$route.path || item.sourcePageUrl + '/' === this.$route.path
      })

      this.query = index !== -1 ? '?id=' + this.urls[index].helpPageId : ''
    },
    onClick() {
      this.$gtag.event('click', {
        event_category: 'Кнопка Помощь',
        event_label: this.$route.path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.helper {
  .icon {
    font-size: 18px;
    margin-right: 8px;
  }
}
</style>
